// TODO: move all logic from the program form to here

import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import { CreateProgramRequest } from "../program.schema";

const useProgramForm = () => {
  const mapProgramByIdToCreateRequest = (program: ProgramByIDResponse): CreateProgramRequest => {
    const mappedProgram: CreateProgramRequest = {
      programDetails: program.programDetails,
      status: program.status,
      adsEndDate: new Date(program.adsEndDate),
      adsStartDate: new Date(program.adsStartDate),
      adsImageId: program.adsImageId,
      audienceTypesIds: program.audienceTypes.map((audienceType) => audienceType.id),
      classificationProgramId: program.classificationProgramId,
      contractId: program.contractId,
      contractStartDate: new Date(program.contractStartDate),
      cycleYear: program.programCycleYearId,
      formBuilderSchema: program.formBuilderSchema,
      programCycleId: program.programCycleId,
      programTypeId: program.programTypeId,
      financingCalculationItems: program.financingCalculationItems.map((item) => ({
        calculationWay: item.financingType?.calculationWay?.calculationWayDetail?.displayName,
        financingTypeId: item.financingType.id,
        fromValue: item.fromValue,
        toValue: item.toValue,
        notes: item.notes,
      })),
      fundingDurationInMonths: program.fundingDurationInMonths,
      fundingAmount: program.fundingAmount,
      programAttachmentsIds: program.files?.map((file) => file.id) ?? [],
      workflowGid: program.workflowGid,
    };

    return mappedProgram;
  };

  return {
    mapProgramByIdToCreateRequest,
  };
};

export default useProgramForm;
