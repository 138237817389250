import IconButton from "components/blocks/IconButton";
import ActionModal from "components/blocks/Modal/ActionModal";
import { useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CloseOrderRequestFormProps, FinancingTermsRequest } from "services/closeOrder/models";

const DeleteTermModal = ({
  index,
  setValue,
  financingTermsRequest,
}: {
  index: number;
  setValue: UseFormSetValue<CloseOrderRequestFormProps> | undefined;
  financingTermsRequest: FinancingTermsRequest[] | undefined;
}) => {
  const { t } = useTranslation("Orders");
  const [isModalOpened, setIsModalOpened] = useState(false);

  const onDeleteConfirmed = async () => {
    if (financingTermsRequest) {
      const updatedFinancingTerms = financingTermsRequest.filter((_, idx) => idx !== index);
      if (setValue) {
        setValue("financingTermsRequest", updatedFinancingTerms);
      }
    }

    setIsModalOpened(false);
  };

  return (
    <>
      <IconButton
        icon="icon-delete"
        innerIconColor="danger"
        fitToIconWidth
        title={t("Common:delete")}
        onClick={() => {
          setIsModalOpened(true);
        }}
      />

      <ActionModal
        isOpen={isModalOpened}
        onClose={() => {
          setIsModalOpened(false);
        }}
        headerMsg={t("Common:areYouSure")}
        subMsg={t("deleteFundingTermConfirmation")}
        actionIcon="icon-delete"
        confirmBtnText={t("Common:delete")}
        iconWrapperColor={"danger"}
        onActionConfirm={onDeleteConfirmed}
      />
    </>
  );
};

export default DeleteTermModal;
