import SimpleDonutChart from "components/blocks/SimpleDonutChart";
import FormViewComponent from "components/formBuilder/FormViewComponent";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ExaminationCriteriaField from "./ExaminationCriteraField";
import {
  SubmittedExaminerOrderEvaluationFormRequest,
  ExaminerOrderEvaluationFormResult,
} from "services/examiners/Model/ExaminerTypes";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FileUploadWithPreview from "components/blocks/FileUploadWithPreview";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  SubmittedExaminerOrderEvaluationFormRequestFormValueType,
  getCreateExaminerEvaluationSchema,
} from "./createExaminerEvaluation.schema";
import { useTranslation } from "react-i18next";
import { FileUploadResponse } from "services/shared-models/sharedTypes";
import FormInput from "components/blocks/FormInput";
import { useNotification } from "hooks/useNotification";
import useMutation from "hooks/useMutation";
import { ExaminersService } from "services/examiners";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useNavigate } from "react-router-dom";
import { OpenFundingTaskResult } from "services/tasks/models/tasksTypes";
import { useQuery } from "hooks/useQuery";

export type ExaminerEvaluationFormProps = {
  serialGuid: string;
  openedTask: OpenFundingTaskResult | null;
  evaluationForm?: ExaminerOrderEvaluationFormResult["evaluationForm"];
  examinerEvaluationFormValue?: ExaminerOrderEvaluationFormResult["examinerEvaluationFormValue"];
};

export type ExaminerEvaluationFormRef = {
  submitForm: () => Promise<void>;
  getRecommendationsPropsOnReturn: () => { recommendations: string; isValid: boolean };
};

const ExaminerEvaluationForm = forwardRef<ExaminerEvaluationFormRef, ExaminerEvaluationFormProps>(
  ({ evaluationForm, examinerEvaluationFormValue, serialGuid, openedTask }, ref) => {
    // Hooks
    const navigate = useNavigate();
    const { t } = useTranslation(["ExaminerTask", "Common"]);
    const { showNotification } = useNotification();
    const formRef = useRef<HTMLFormElement>(null);

    //States
    const [criteries, setCriteries] = useState(evaluationForm?.criteries ?? []);
    const [files, setFiles] = useState<(FileUploadResponse & { file: File })[]>([]);
    const {
      handleSubmit,
      getValues,
      control,
      setValue,
      watch,
      setError,
      reset,
      formState: { errors },
    } = useForm<SubmittedExaminerOrderEvaluationFormRequestFormValueType>({
      resolver: getCreateExaminerEvaluationSchema(t),
      defaultValues: {
        criteriaValuesRequest: [],
        evaluationPercentage: 0,
        filesIds: [],
        formBuilderValue: "",
        recommendations: "",
        isFormValueReady: false,
        actionName: "Complete",
        serialGuid: serialGuid,
      },
    });

    const { data: examinerEvaluationHistory } = useQuery({
      queryFn: async () => {
        return await ExaminersService.examinerLastEvaluationHistory(openedTask?.orderId ?? 0);
      },
      triggers: [openedTask?.orderId],
      options: {
        enabled: !!openedTask?.orderId,
      },
    });

    // Effects
    useEffect(() => {
      if (examinerEvaluationFormValue?.evaluationPercentage) {
          
        reset({
          ...examinerEvaluationFormValue,
          filesIds: examinerEvaluationFormValue.files.map((f) => f.id),
          criteriaValuesRequest: examinerEvaluationFormValue.criteriaValues.map((cv) => ({
            criteriaId: cv.criteriaId,
            id: cv.id,
            notes: cv.notes,
            value: cv.value,
          })),
        });

       
        setFiles(
          examinerEvaluationFormValue?.files.map((f) => ({
            uuid: f.id,
            fileName: f.name ?? "",
            id: f.id,
            size: f.size,
            file: new File([], f.name ?? ""),
          })) ?? [],
        );
      } else if (examinerEvaluationHistory) {

        reset({
          ...examinerEvaluationHistory.examinerEvaluationFormValue,
          filesIds: examinerEvaluationHistory.examinerEvaluationFormValue.files?.map((f) => f.id),
          criteriaValuesRequest: examinerEvaluationHistory.examinerEvaluationFormValue.criteriaValues.map((cv) => ({
            criteriaId: cv.criteriaId,
            id: cv.id,
            notes: cv.notes,
            value: cv.value,
          })),
        });
        setFiles(
          examinerEvaluationHistory.examinerEvaluationFormValue.files?.map((f) => ({
            uuid: f.id,
            fileName: f.name ?? "",
            id: f.id,
            size: f.size,
            file: new File([], f.name ?? ""),
          })) ?? [],
        );
      }

      if (evaluationForm) {
        setCriteries(evaluationForm.criteries);
      }
    }, [evaluationForm, examinerEvaluationHistory]);

    useImperativeHandle(ref, () => ({
      submitForm: async () => {
        await handleSubmit(async (values) => {
          await onSubmit(values);
          formRef.current?.classList.add("was-validated");
        })();
      },
      getRecommendationsPropsOnReturn: () => {
        const recommendations = getValues("recommendations");
        if (!recommendations) {
          setError("recommendations", {
            type: "manual",
            message: t("Common:invalidValue"),
          });

          showNotification({
            description: t("recommendationsIsRequiredToContinueReturnTask"),
            type: "error",
          });
        }

        return {
          recommendations: recommendations,
          isValid: !!recommendations,
        };
      },
    }));

    const { loading, mutateAsync } = useMutation({
      queryFn: async (values: SubmittedExaminerOrderEvaluationFormRequest) => {
        return await ExaminersService.submitExaminerOrderEvaluationForm(openedTask?.examinerId ?? 0, values);
      },
    });

    const onSubmit = async (values: SubmittedExaminerOrderEvaluationFormRequest) => {
      values.filesIds = files.map((f) => f.uuid);
      values.serialGuid = serialGuid;
      values.actionName = "Complete";
      values.activityGuid = openedTask?.activityGuid;


      const res = await mutateAsync(values);
      if (!res?.hasError) {
        navigate("/home/tasks");
      }
    };

    const criteriaValues = watch("criteriaValuesRequest");

    return (
      <form ref={formRef} noValidate>
        <LoadingOverlay visible={loading} className="h-100" />
        <Accordion>
          <AccordionItem title={t("evaluationForm")} initiallyOpen elementId="evaluation-form">
            <Controller
              name="evaluationPercentage"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    {criteries.map((c, index) => {
                      const criteria = criteriaValues[index];

                      return (
                        <ExaminationCriteriaField
                          key={c.id}
                          criteriaValue={{
                            criteria: {
                              criteriaDetail: c.criteriaDetail,
                              id: c.id,
                              weight: c.weight ?? 0,
                            },
                            notes: criteria?.notes,
                            value: criteria?.value ?? 0,
                          }}
                          index={index}
                          setValue={setValue}
                          errors={{
                            criteriaValue: errors.criteriaValuesRequest?.[index]?.value?.message,
                            notes: errors.criteriaValuesRequest?.[index]?.notes?.message,
                          }}
                          evaluationPercentage={field.value}
                        />
                      );
                    })}
                  </>
                );
              }}
            />
          </AccordionItem>
        </Accordion>

        <div className="divider" />

        <div className="mt-4">
          <h6 className="fw-bold">{t("finalResult")} </h6>
          <Controller
            name="evaluationPercentage"
            control={control}
            render={({ field }) => <SimpleDonutChart size={100} percentage={field.value} />}
          />
          {errors.evaluationPercentage?.message && (
            <span className="invalid-feedback my-2 d-block">{errors.evaluationPercentage?.message}</span>
          )}
        </div>

        <div className="divider" />

        <FileUploadWithPreview files={files} setFiles={setFiles} formProps={{}} />

        <div className="divider my-4" />
        <div className="col-sm-12">
          <label className="mb-1">{t("recommendations")}</label>
          <textarea
            className="form-control"
           value={watch("recommendations")}
            rows={5}
            placeholder={t("recommendations")}
            onChange={(e) => setValue("recommendations", e.target.value)}
            style={{ resize: "none" }}
      
        />
              
       
          {errors.recommendations?.message && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {errors.recommendations?.message} 
            </div>
          )}
        </div>

        <Controller
          name="formBuilderValue"
          control={control}
          render={({ field }) => {
            return (
              <FormViewComponent
                data={field.value ? JSON.parse(field.value) : {}}
                formDefinition={evaluationForm?.jsonSchema ? JSON.parse(evaluationForm.jsonSchema) : {}}
                onChange={(values) => {
                  setValue("isFormValueReady", values.isValid);
                  setValue("formBuilderValue", JSON.stringify(values.data));
                }}
              />
            );
          }}
        />

        {errors.isFormValueReady?.message && (
          <span className="invalid-feedback my-2" style={{ display: "block" }}>
            {errors.isFormValueReady?.message}
          </span>
        )}
      </form>
    );
  },
);

export default ExaminerEvaluationForm;
