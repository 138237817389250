import { Routes, Route, Navigate } from "react-router-dom";
import NotFoundLayout from "../../views/notfound/NotFoundLayout";
import Cookies from "js-cookie";
import { RouteItemConfig } from "../../routes/models";
import { useCookieContext } from "../../contexts";
import WelcomePageView from "views/welcomePage/WelcomePageView";
import React from "react";

interface SwitchRouteProps {
  routes: Array<RouteItemConfig>;
}

export const SwitchRoute: React.FC<SwitchRouteProps> = ({ routes }) => {
  const { userSession } = useCookieContext();

  // Recursive function to generate routes with role checking
  const getRecursion = (value: RouteItemConfig): React.ReactNode[] => {
    const nestedRoutes: React.ReactNode[] = [];

    if (value.subMenu && value.subMenu.length > 0) {
      // Find default route or fallback to the first route
      const defaultRoute = value.subMenu.find((sub) => sub.default) || value.subMenu[0];

      nestedRoutes.push(
        <Route
          path={value.path}
          element={<Navigate to={defaultRoute.path} replace />}
          key={`redirectRoute${value.path}`}
        />,
      );

      value.subMenu.forEach((subValue) => {
        nestedRoutes.push(...getRecursion(subValue));
      });

      return [
        <Route path={value.path} element={<value.component />} key={`layoutRoute${value.path}`}>
          {nestedRoutes}
        </Route>,
      ];
    } else {
      const key = Math.floor(Math.random() * 1000);

      // Authorization check
      const requiresAuth = value.authorize;
      const hasRequiredRoles = /* hasRequiredRole(userRoles, value.roles) */ true;

      if (requiresAuth) {
        if (Cookies.get("Session") || (userSession && hasRequiredRoles)) {
          nestedRoutes.push(<Route path={value.path} element={<value.component />} key={`privateRoute${key}`} />);
        } else {
          nestedRoutes.push(
            <Route path={value.path} element={<Navigate to="/account" replace />} key={`redirectRoute${key}`} />,
          );
        }
      } else {
        nestedRoutes.push(<Route path={value.path} element={<value.component />} key={`publicRoute${key}`} />);
      }

      return nestedRoutes;
    }
  };

  // Render default route
  const renderDefaultRoute = () => {
    return <Route path="/" element={<WelcomePageView />} key={`defaultRoute`} />;
  };

  return (
    <>
      {routes.length > 0 && (
        <Routes>
          {renderDefaultRoute()}
          {routes.map((routeItem) => {
            return getRecursion(routeItem);
          })}
          <Route path="*" element={<NotFoundLayout />} />
        </Routes>
      )}
    </>
  );
};
