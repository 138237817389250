import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import AttachmentCard from "components/blocks/AttachmentCard";
import IconButton from "components/blocks/IconButton";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import Modal from "components/blocks/Modal";
import { useCookieContext } from "contexts";
import { useQuery } from "hooks/useQuery";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/orders";
import { ProgramMinutesResult } from "services/orders/models/OrdersTypes";

const PreviousRecords = ({
  programOrderId,
  shouldUpdateTable,
}: {
  programOrderId: number;
  shouldUpdateTable: boolean;
}) => {
  const { t } = useTranslation("Orders");
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { isAr } = useCookieContext();
  const [attachmentsModalProps, setAttachmentsModalProps] = useState<
    { isOpen: boolean; files: ProgramMinutesResult["files"] } | undefined
  >({
    isOpen: false,
    files: [],
  });

  const { data, loading } = useQuery({
    queryFn: async () => {
      return await OrdersService.getAllProgramMinutesByProgramOrderId(programOrderId);
    },
    triggers: [shouldUpdateTable, isAr],
  });

  const previousRecordTableHeaders = useMemo<GridSchema[]>(() => {
    return [
      {
        accessor: "programMinutesNumber",
        field: "programMinutesNumber",
        displayName: t("recordNumber"),
        showOnMobile: true,
      },
      {
        accessor: "date",
        field: "date",
        displayName: t("date"),
        render: (row: ProgramMinutesResult) => <>{toSystemThemeDateFormat(row.date)}</>,
      },
      {
        accessor: "members",
        field: "members",
        displayName: t("members"),
        render: (row: ProgramMinutesResult) => <>{!isAr ? row.users.join(", ") : row.usersAr?.join(", ")}</>,
      },
      {
        accessor: "description",
        field: "description",
        displayName: t("recordDetails"),
      },
      {
        accessor: "files",
        field: "files",
        displayName: t("files"),
        showOnMobile: true,
        render(row: ProgramMinutesResult) {
          return !!row.files.length ? (
            <div className="d-flex ">
              <IconButton
                icon="icon-view"
                variant="transparent"
                innerIconColor="primary"
                onClick={() => {
                  setAttachmentsModalProps({
                    isOpen: true,
                    files: row.files,
                  });
                }}
              />
            </div>
          ) : (
            <></>
          );
        },
      },
    ];
  }, [t, toSystemThemeDateFormat]);

  return (
    <div className="position-relative">
      <LoadingOverlay visible={loading} />
      <Grid gridSchema={previousRecordTableHeaders} data={data?.result ?? []} />
      <Modal
        isOpen={attachmentsModalProps?.isOpen}
        onClose={() => setAttachmentsModalProps({ isOpen: false, files: [] })}
        containerClassName="bg-white"
        title={t("attachments")}
      >
        <div className="d-flex gap-2 flex-wrap">
          {attachmentsModalProps?.isOpen &&
            attachmentsModalProps.files.map((file) => (
              <AttachmentCard
                attachment={{
                  size: file.size ?? "",
                  extension: file.extension ?? "",
                  id: file.id,
                  fileName: file.name ?? "",
                }}
              />
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default PreviousRecords;
