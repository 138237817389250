import AttachmentCard from "components/blocks/AttachmentCard";
import FilePreview from "components/blocks/FileInput/FilePreview";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import RichTextEditor from "components/RichTextEditor";
import { useCookieContext } from "contexts";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FileService } from "services/fileService";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";

const AboutProgramTab = ({ program }: { program: ProgramByIDResponse }) => {
  const { t } = useTranslation("Programs");
  const { isAr } = useCookieContext();

  const programDetails = program.programDetails?.find((x) => x.locale === (isAr ? "Ar" : "En"));

  const generatePdf = (content: string, fileName?: string) => async () => {
    if (!content) return;
    await FileService.htmlToPdf(content, `${fileName ?? "template"}.pdf`);
  };

  const financingGridSchema = useMemo<GridSchema[]>(
    () => [
      {
        displayName: t("fundingType"),
        field: "-",
        showOnMobile: true,
        render: (item: ProgramByIDResponse["financingCalculationItems"][0]) =>
          item.financingType.financingTypesDetail.displayName,
      },
      {
        displayName: t("calculationMethod"),
        field: "-",
        render: (item: ProgramByIDResponse["financingCalculationItems"][0]) =>
          item.financingType?.calculationWay?.calculationWayDetail?.displayName,
      },
      {
        displayName: t("amount"),
        field: "-",
        showOnMobile: true,
        render: (item: ProgramByIDResponse["financingCalculationItems"][0]) => `${item.fromValue} - ${item.toValue}`,
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column gap-2">
      <div>
        <h6 className="fw-bold mb-4">{t("details")}</h6>
        <p className="px-2">{programDetails?.description}</p>
      </div>
      <div className="d-flex flex-column gap-2">
        <h6 className="fw-bold mb-0">{t("rules")}</h6>
        <RichTextEditor initialData={programDetails?.ruleAndConditionTemplate ?? ""} isReadOnly onChange={() => {}} />
        <FilePreview
          wrapperClassName="align-self-start"
          title={programDetails?.ruleAndConditionName}
          callbackValue="1"
          onShow={generatePdf(programDetails?.ruleAndConditionTemplate ?? "", programDetails?.ruleAndConditionName)}
          hideDeleteButton
        />
      </div>
        <div className="d-flex flex-column gap-2 mt-2">
            <h6 className="fw-bold mb-0">{t("commitment")}</h6>
            <RichTextEditor initialData={programDetails?.commitmentTemplate ?? ""} isReadOnly onChange={() => {
            }}/>
            <FilePreview
                wrapperClassName="align-self-start"
                title={programDetails?.commitmentName}
                callbackValue="2"
                onShow={generatePdf(programDetails?.commitmentTemplate ?? "", programDetails?.commitmentName)}
                hideDeleteButton
            />
            <div className="divider"/>
        </div>
        <div>
            <h6 className="fw-bold">{t("Common:attachments")}</h6>
            <div className="d-flex gap-2">
                {program.files && program.files.length ? program.files?.map((file) => (
                    <AttachmentCard
                        key={file.id}
                        attachment={{
                            extension: file.extension,
                            fileName: file.name,
                            id: file.id,
                            size: file.size,
                        }}
                    />
                )) : <p>{t("Common:noData")}</p>}
            </div>
            <div className="divider"/>
        </div>

        <div>
            <h6 className="fw-bold mb-3">{t("financingCalculationTerms")}</h6>
        <Grid data={program.financingCalculationItems} gridSchema={financingGridSchema} />
      </div>

      <div className="divider" />

      <div className="d-flex flex-column">
        <h6 className="fw-bold mb-3">{t("programForm")}</h6>
        {/* TODO: name of the template */}
        <FilePreview
          wrapperClassName="align-self-start"
          title="template"
          callbackValue="3"
          onShow={() => {}}
          hideDeleteButton
        />
      </div>
    </div>
  );
};

export default AboutProgramTab;
