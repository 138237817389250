import Dropdown from "components/blocks/Dropdown";
import FileUploadWithPreview from "components/blocks/FileUploadWithPreview";
import FormInput from "components/blocks/FormInput";
import LookupDropdown from "components/blocks/LookupDropdown";
import { useTranslation } from "react-i18next";
import { CloseOrderFormProps } from "views/home/orders/OrderView/ResearcherCloseRequestPage";

const AddAchievementFileForm = ({ closeOrderFormProps }: { closeOrderFormProps?: CloseOrderFormProps }) => {
  const { t } = useTranslation("Orders");

  if (!closeOrderFormProps) {
    return null;
  }

  return (
    <div className="d-flex flex-column gap-2">
      <FileUploadWithPreview
        files={closeOrderFormProps?.files}
        setFiles={closeOrderFormProps?.setFiles}
        label={t("publishedScientificPapers")}
        clickHereForLabel={t("clickHereToUploadScientificPapers")}
        onChange={(files) =>
          closeOrderFormProps?.setValue(
            "filesIds",
            files.map((file) => file.uuid),
          )
        }
      />

      <div className="d-flex gap-2 flex-wrap">
        <FormInput
          label={t("journalSearchLink")}
          wrapperClassName="flex-1"
          style={{ minWidth: "150px" }}
          placeholder={t("journalSearchLink")}
          value={closeOrderFormProps?.watch("journalSearchLink")}
          onChange={(e) => closeOrderFormProps?.setValue("journalSearchLink", e.target.value)}
          error={closeOrderFormProps?.errors.journalSearchLink?.message}
        />
        <FormInput
          label={t("journalName")}
          wrapperClassName="flex-1"
          style={{ minWidth: "150px" }}
          placeholder={t("journalName")}
          value={closeOrderFormProps?.watch("journalName")}
          onChange={(e) => closeOrderFormProps?.setValue("journalName", e.target.value)}
          error={closeOrderFormProps?.errors.journalName?.message}
        />
        <LookupDropdown
          idValueKey="id"
          textValueKey="journalClassificationDetail.displayName"
          service="lookupService"
          endpoint="getAllJournalClassifications"
          label={t("journalClassification")}
          noSelectionPlaceholder={t("journalClassification")}
          wrapperClassName="flex-1"
          value={closeOrderFormProps?.watch("journalClassificationId") ?? ""}
          onChange={(value) => closeOrderFormProps?.setValue("journalClassificationId", +value)}
          error={closeOrderFormProps?.errors.journalClassificationId?.message}
          useReactSelect
        />
        <LookupDropdown
          idValueKey="id"
          textValueKey="journalRankingDetail.displayName"
          service="lookupService"
          endpoint="getAllJournalRanking"
          label={t("journalOrder")}
          noSelectionPlaceholder={t("journalOrder")}
          wrapperClassName="flex-1"
          value={closeOrderFormProps?.watch("journalRankingId") ?? ""}
          onChange={(value) => closeOrderFormProps?.setValue("journalRankingId", +value)}
          error={closeOrderFormProps?.errors.journalRankingId?.message}
          useReactSelect
        />
      </div>
    </div>
  );
};

export default AddAchievementFileForm;
