import { zodResolver } from "@hookform/resolvers/zod";
import { CreateAdditionalAttachmentRequest } from "services/orders/models/OrdersTypes";
import { z } from "zod";

export const getAdditionalAttachments = (t: any) => {
  const schema = z.object({
    fileId: z.string().min(1, t("Common:invalidValue")),
    programOrderId: z.number().min(1, t("Common:invalidValue")),
    description: z.string().min(1, t("Common:invalidValue")),
  }) as z.ZodType<CreateAdditionalAttachmentRequest>;

  return zodResolver(schema);
};
