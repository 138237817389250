import Grid from "components/GridView/components/Grid";
import AddExaminerModal from "../AddExaminerModal";
import { Dispatch, MouseEvent, SetStateAction, useMemo, useRef, useState } from "react";
import { GridSchema } from "components/GridView/GridView.types";
import { useTranslation } from "react-i18next";
import IconButton from "components/blocks/IconButton";
import { useQuery } from "hooks/useQuery";
import { ExaminersService } from "services/examiners";
import { AccreditationTypes, ExaminerListResult } from "services/examiners/Model/ExaminerTypes";
import ReferExaminerModal from "../ReferExaminersModal";
import { DeleteExaminerModal, DeleteExaminerModalProps } from "./DeleteExaminerModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ExaminerSelectedTemplateType } from "..";
import ViewExaminerEvaluationModal, { ViewExaminerEvaluationModalProps } from "./ViewExaminerEvaluationModal";
import AcceptRejectExaminerEvaluationDDL from "./AcceptRejectExaminerEvaluationDDL";
import AcceptExaminerRewardModal, { AcceptExaminerRewardModalProps } from "./AcceptExaminerRewardModal";
import { useCookieContext } from "contexts";

export const EXAMINER_TASK_STATUS = {
  Pending: {
    key: "examinerTaskPending",
    value: "Pending",
  },
  Cancel: {
    key: "examinerTaskCancel",
    value: "Cancel",
  },
  Apologies: {
    key: "examinerTaskApologies",
    value: "Apologies",
  },
  Returned: {
    key: "examinerTaskReturned",
    value: "Returned",
  },
  Submitted: {
    key: "examinerTaskSubmitted",
    value: "Submitted",
  },
  Evaluated: {
    key: "examinerTaskEvaluated",
    value: "Evaluated",
  },
  Accepted: {
    key: "examinerTaskAccepted",
    value: "Accepted",
  },
};
type ExaminerTaskStatus = keyof typeof EXAMINER_TASK_STATUS;

const ExaminersTable = ({
  orderId,
  programOrderEvaluationId,
  selectedTemplate,
  fundingUnitNotes,
  setTriggerOrderUpdate,
}: {
  orderId: number;
  fundingUnitNotes: string;
  programOrderEvaluationId: number;
  selectedTemplate: ExaminerSelectedTemplateType;
  setTriggerOrderUpdate: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isAr } = useCookieContext();
  const { t } = useTranslation(["Orders"]);

  const examinersEvaluationStatusRef = useRef<{
    [key: string]: { examinerId: number; shouldShowAcceptRejectEvaluationDropdown: boolean }[];
  }>({});
  const [triggerTableUpdate, setTriggerTableUpdate] = useState(false);
  const [checkedExaminerToReferer, setCheckedExaminerToReferer] = useState<ExaminerListResult[]>([]);
  const [isAddExaminerModalOpened, setIsAddExaminerModalOpened] = useState(false);
  const [refererExaminersModalProps, setRefererExaminersModalProps] = useState<{
    isOpen: boolean;
    alreadyReferredExaminerId: number | undefined;
  }>({
    isOpen: false,
    alreadyReferredExaminerId: undefined,
  });
  const [viewExaminerEvaluationModalProps, setViewExaminerEvaluationModalProps] =
    useState<ViewExaminerEvaluationModalProps | null>(null);
  const [deleteExaminerModalProps, setDeleteExaminerModalProps] = useState<
    DeleteExaminerModalProps | null | undefined
  >();
  const [AcceptExaminerRewardModalProps, setAcceptExaminerRewardModalProps] = useState<
    AcceptExaminerRewardModalProps | null | undefined
  >();

  const { data: selectedExaminers } = useQuery({
    queryFn: async () => {
      const data = await ExaminersService.getAllExaminersByOrderId(orderId);

      //! Order by examinerName and then by id
      data.data.sort((a, b) => a.examinerName.localeCompare(b.examinerName) || a.id - b.id);

      data.data.forEach((examiner) => {
        if (!examinersEvaluationStatusRef.current[examiner.examinerName]) {
          examinersEvaluationStatusRef.current[examiner.examinerName] = [];
        }

        if (!!examiner.status) {
          examinersEvaluationStatusRef.current[examiner.examinerName].forEach((examinerEvaluation) => {
            examinerEvaluation.shouldShowAcceptRejectEvaluationDropdown = false;
          });
        }

        examinersEvaluationStatusRef.current[examiner.examinerName].push({
          examinerId: examiner.id,
          shouldShowAcceptRejectEvaluationDropdown: !!examiner.status,
        });
      });

      return data;
    },
    triggers: [orderId, triggerTableUpdate],
    options: {
      enabled: !!orderId,
    },
  });

  const onCheckExaminer = (e: MouseEvent<HTMLInputElement, globalThis.MouseEvent>, userId: string | number) => {
    if (e.currentTarget.checked) {
      setCheckedExaminerToReferer((old) => [...old, selectedExaminers!.find((examiner) => examiner.id === userId)!]);
    } else {
      setCheckedExaminerToReferer((old) => old.filter((examiner) => examiner.id !== userId));
    }
  };

  const onSelectAllNewExaminer = (e: MouseEvent<HTMLInputElement, globalThis.MouseEvent>) => {
    if (e.currentTarget.checked) {
      setCheckedExaminerToReferer(selectedExaminers?.filter((examiner) => !examiner.status) ?? []);
    } else {
      setCheckedExaminerToReferer([]);
    }
  };

  const onDeleteExaminer = (row: ExaminerListResult) => {
    setDeleteExaminerModalProps({
      isOpen: true,
      onClose: (shouldUpdateExaminersTable: boolean) => {
        setDeleteExaminerModalProps(null);
        if (shouldUpdateExaminersTable) {
          setTriggerTableUpdate(!triggerTableUpdate);
        }
      },
      selectedExaminerId: row.id,
      examinerStatus: row.status,
      examinerName: row.examinerName,
    });
  };

  const onViewExaminerEvaluation = (row: ExaminerListResult) => {
    setViewExaminerEvaluationModalProps({
      evaluationPercentage: row.evaluationPercentage,
      onClose: () => {
        setViewExaminerEvaluationModalProps(null);
      },
      examinerEvaluationFormValue: row.examinerEvaluationFormValue,
      recommendations: row.recommendations,
      selectedTemplate: selectedTemplate,
      formDefinition: row.evaluationForm.jsonSchema,
    });
  };

  const onAcceptExaminerReward = (row: ExaminerListResult) => {
    setAcceptExaminerRewardModalProps({
      isOpen: true,
      onClose: (shouldUpdateTable: boolean) => {
        setAcceptExaminerRewardModalProps(null);
        if (shouldUpdateTable) {
          setTriggerTableUpdate(!triggerTableUpdate);
        }
      },
      programOrderExaminerId: row.id,
    });
  };

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "",
        field: "",
        displayName: "",
        showOnMobile: true,
        render: (row: ExaminerListResult) => {
          return (
            <input
              type="checkbox"
              disabled={
                row.status === EXAMINER_TASK_STATUS.Pending.value || row.status === EXAMINER_TASK_STATUS.Accepted.value
              }
              checked={checkedExaminerToReferer.some((examiner) => examiner.id === row.id)}
              onClick={(e) => {
                if (
                  !(
                    row.status === EXAMINER_TASK_STATUS.Submitted.value ||
                    row.status === EXAMINER_TASK_STATUS.Evaluated.value ||
                    row.status === EXAMINER_TASK_STATUS.Cancel.value
                  )
                ) {
                  onCheckExaminer(e, row.id);
                } else {
                  setRefererExaminersModalProps({
                    isOpen: true,
                    alreadyReferredExaminerId: row.id,
                  });
                }
              }}
            />
          );
        },
        renderHeader: () => {
          return <input type="checkbox" onClick={onSelectAllNewExaminer} />;
        },
      },
      {
        field: "examinerName",
        displayName: t("examinerName"),
        showOnMobile: true,
        render: (row: ExaminerListResult) => {
          return (
            <span className={row.status === EXAMINER_TASK_STATUS.Cancel.value ? "text-overline" : ""}>
              {row.examinerName}
            </span>
          );
        },
      },
      {
        field: "round",
        displayName: t("round"),
        render: (row: ExaminerListResult) => {
          const isDelayed =
            row.deadLine && row.status !== EXAMINER_TASK_STATUS.Submitted.value
              ? new Date().getTime() > new Date(row.deadLine).getTime()
              : false;

          return (
            <div className="d-flex justify-content-between">
              <span>{row.iteration}</span>
              {row.status === EXAMINER_TASK_STATUS.Pending.value && row.isSentInvitation && (
                <OverlayTrigger placement="top" overlay={<Tooltip>{t("notRegisteredWithSentInvitation")}</Tooltip>}>
                  <i className="icon-help text-primary"></i>
                </OverlayTrigger>
              )}
              {isDelayed && <i className="icon-warning text-danger"></i>}
            </div>
          );
        },
      },
      {
        field: "evaluationPercentage",
        displayName: t("evaluation"),
        render: (row: ExaminerListResult) => {
          return <>{row.evaluationPercentage ? `${row.evaluationPercentage.toFixed(2)}%` : "-"}</>;
        },
      },
      {
        field: "recommendations",
        displayName: t("recommendations"),
        render: (row) => {
          return row.recommendations;
        },
      },
      {
        field: "taskStatus",
        displayName: t("taskStatus"),
        render: (row) => {
          return row.status ? t(EXAMINER_TASK_STATUS[row.status as ExaminerTaskStatus].key) : "-";
        },
      },
      {
        field: "confirmExaminersResult",
        displayName: t("confirmExaminersResult"),
        render: (row) => {
          return row.status === EXAMINER_TASK_STATUS.Submitted.value &&
            examinersEvaluationStatusRef.current[row.examinerName].some(
              (examinerEvaluation) =>
                examinerEvaluation.shouldShowAcceptRejectEvaluationDropdown && examinerEvaluation.examinerId === row.id,
            ) ? (
            <AcceptRejectExaminerEvaluationDDL row={row} setTriggerTableUpdate={setTriggerTableUpdate} />
          ) : (
            "-"
          );
        },
      },
      {
        field: "actions",
        displayName: t("actions"),
        render: (row: ExaminerListResult) => {
          const isDeleteIconShown =
            row.status !== EXAMINER_TASK_STATUS.Submitted.value &&
            row.status !== EXAMINER_TASK_STATUS.Cancel.value &&
            row.status !== EXAMINER_TASK_STATUS.Apologies.value;

          const isRewardIconShown =
            row.status === EXAMINER_TASK_STATUS.Submitted.value &&
            examinersEvaluationStatusRef.current[row.examinerName].some(
              (examinerEvaluation) =>
                examinerEvaluation.shouldShowAcceptRejectEvaluationDropdown && examinerEvaluation.examinerId === row.id,
            );

          const isViewEvaluationIconShown =
            row.status === EXAMINER_TASK_STATUS.Submitted.value || row.status === EXAMINER_TASK_STATUS.Evaluated.value;

          return (
            <div className="d-flex gap-2">
              {isViewEvaluationIconShown && (
                <IconButton
                  icon="icon-view"
                  fitToIconWidth
                  innerIconColor="primary"
                  size="md"
                  onClick={() => {
                    onViewExaminerEvaluation(row);
                  }}
                />
              )}
              {isDeleteIconShown && (
                <IconButton
                  icon="icon-delete"
                  fitToIconWidth
                  innerIconColor="danger"
                  size="md"
                  onClick={() => onDeleteExaminer(row)}
                />
              )}

              {isRewardIconShown && (
                <IconButton
                  icon={row.isConfirmFees ? "icon-reward-accepted" : "icon-reward"}
                  innerIconColor={row.isConfirmFees ? "success" : "dark"}
                  fitToIconWidth
                  size="md"
                  onClick={
                    !row.isConfirmFees || row.accreditationType === AccreditationTypes.Reject.label
                      ? () => onAcceptExaminerReward(row)
                      : () => {}
                  }
                />
              )}
            </div>
          );
        },
      },
    ],
    [checkedExaminerToReferer, selectedExaminers, isAr],
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4>{t("evaluations")}</h4>
        <div>
          <button
            className="btn btn-primary p-10-32P rounded-4"
            disabled={!selectedTemplate.id}
            onClick={() => {
              setIsAddExaminerModalOpened(true);
            }}
          >
            {t("addExaminers")}
          </button>
          {!!checkedExaminerToReferer?.length && (
            <button
              className="btn border me-2 p-10-32P rounded-4 text-dark"
              onClick={() => {
                setRefererExaminersModalProps({
                  isOpen: true,
                  alreadyReferredExaminerId: undefined,
                });
              }}
            >
              {t("referExaminers")}
            </button>
          )}
        </div>
      </div>

      {!!selectedExaminers?.length && <Grid data={selectedExaminers} gridSchema={gridSchema} />}

      {fundingUnitNotes && (
        <div>
          <h6 className="fw-bold">{t("fundingUnitNotes")}</h6>
          <div className="fs-14px ">{fundingUnitNotes}</div>
        </div>
      )}

      <AddExaminerModal
        isOpen={isAddExaminerModalOpened}
        programOrderEvaluationId={programOrderEvaluationId}
        onClose={(shouldUpdateTable?: boolean) => {
          setIsAddExaminerModalOpened(false);
          if (shouldUpdateTable) {
            setTriggerTableUpdate(!triggerTableUpdate);
          }
        }}
      />

      <ReferExaminerModal
        isOpen={refererExaminersModalProps.isOpen}
        alreadyReferredExaminerId={refererExaminersModalProps.alreadyReferredExaminerId}
        checkedExaminerToReferer={checkedExaminerToReferer}
        onClose={(shouldUpdate) => {
          setRefererExaminersModalProps({
            isOpen: false,
            alreadyReferredExaminerId: undefined,
          });
          setCheckedExaminerToReferer([]);

          if (shouldUpdate) {
            setTriggerTableUpdate(!triggerTableUpdate);
            setTriggerOrderUpdate((prev) => !prev);
          }
        }}
      />

      <DeleteExaminerModal props={deleteExaminerModalProps} />
      <ViewExaminerEvaluationModal props={viewExaminerEvaluationModalProps} />
      <AcceptExaminerRewardModal
        isOpen={!!AcceptExaminerRewardModalProps?.isOpen}
        onClose={AcceptExaminerRewardModalProps?.onClose}
        programOrderExaminerId={AcceptExaminerRewardModalProps?.programOrderExaminerId}
      />
    </>
  );
};

export default ExaminersTable;
