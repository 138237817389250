import FormInput from "components/blocks/FormInput";
import { useTranslation } from "react-i18next";
import { CreateProfileSectionProps } from "./PersonalInformation";
import { Controller } from "react-hook-form";
import LookupDropdown from "components/blocks/LookupDropdown";

const BankingInformation = ({ errors, register, setValue, control }: CreateProfileSectionProps) => {
  const { t } = useTranslation("UserProfile");

  return (
    <div className="d-flex flex-wrap flex-column gap-2">
      <div className="row g-2">
        <FormInput
          label={t("beneficiaryName")}
          wrapperClassName="col-md-6 col-sm-12"
          {...register("beneficiaryName")}
          error={errors.beneficiaryName?.message}
        />
        <FormInput
          label={t("bankName")}
          wrapperClassName="col-md-6 col-sm-12"
          {...register("bankName")}
          error={errors.bankName?.message}
        />
      </div>
      <div className="row g-2">
        <FormInput
          label={t("bankAccountIBAN")}
          wrapperClassName="col-md-6 col-sm-12"
          {...register("ibanNumber")}
          error={errors.ibanNumber?.message}
        />

        <Controller
          name="currencyId"
          control={control}
          render={({ field }) => (
            <LookupDropdown
              useReactSelect
              label={t("currency")}
              wrapperClassName="col-md-6 col-sm-12"
              service="lookupService"
              endpoint="getAllCurrencies"
              idValueKey="id"
              textValueKey="currencyDetail.displayName"
              value={field.value || ""}
              noSelectionPlaceholder={t("currency")}
              onChange={(v) => {
                setValue("currencyId", v ? +v : 0);
              }}
              error={errors.bankName?.message}
            />
          )}
        />
      </div>
    </div>
  );
};

export default BankingInformation;
