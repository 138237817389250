import { useEffect, useState } from "react";
import { useNotification } from "hooks/useNotification";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EvaluationFormsService } from "services/evaluationFormsService";
import { CreateEvaluationFormRequest, createEvaluationFormSchema } from "./evaluationForm.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import FormInput from "components/blocks/FormInput";
import Switch from "components/blocks/Switch";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import LookupDropdown from "components/blocks/LookupDropdown";
import Dropdown from "components/blocks/Dropdown";
import CriteriaElement from "./CriteriaElement";
import CriteriaElementTemplate from "./CriteriaElementTemplate";
import FormBuilderComponent from "components/formBuilder/FormBuilderComponent";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";

const EvaluationFormAddUpdateView = () => {
  // Hooks
  const { t } = useTranslation("EvaluationForms");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { showNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumb();

  // State
  const [evaluationFormSerialNumber, setEvaluationFormSerialNumber] = useState<string | null>(null);
  const evaluationFormId = searchParams.get("id");
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (evaluationFormId) {
      fetchEvaluationForm(+evaluationFormId);
    }
  }, [evaluationFormId]);

  const fetchEvaluationForm = async (evaluationFormId: number) => {
    setLoading(true);
    const response = await EvaluationFormsService.getById(evaluationFormId);
    if (response.hasError) {
      showNotification({ description: response.description, type: "error" });
      navigate(-1);
    } else {
      const mappedResponse = {
        systemTypeId: response.data.systemType.id,
        userTypeId: response.data.userType.id,
        status: response.data.status,
        jsonSchema: response.data.jsonSchema || "",
        evaluationFormDetails: response.data.evaluationFormDetails,
        criteries: response.data.criteries,
      };
      reset(mappedResponse);
    }
    setLoading(false);
  };

  const onSubmit = async (data: CreateEvaluationFormRequest) => {
    // validate total weight is 100%
    const totalWeight = data.criteries.reduce((acc, curr) => acc + curr.weight, 0);
    if (totalWeight !== 100) {
      showNotification({ description: t("totalWeightMustBe100"), type: "warning" });
      return;
    }

    setSubmitting(true);
    try {
      const response = evaluationFormId
        ? await EvaluationFormsService.update(+evaluationFormId, data)
        : await EvaluationFormsService.create(data);

      if (response.hasError) {
        showNotification({ description: response.description, type: "error" });
      } else {
        showNotification({ description: t("Common:success"), type: "success" });
        navigate(-1);
      }
    } catch {
      showNotification({ description: t("Common:internalServerError"), type: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm<CreateEvaluationFormRequest>({
    resolver: zodResolver(createEvaluationFormSchema),
    values: {
      status: "Active",
      systemTypeId: 0,
      userTypeId: 0,
      jsonSchema: "{}",
      evaluationFormDetails: [
        {
          displayName: "",
          description: "",
          locale: "Ar",
        },
        {
          displayName: "",
          description: "",
          locale: "En",
        },
      ],
      criteries: [],
    },
  });

  const onCriteriaDelete = (index: number) => {
    setValue(
      "criteries",
      getValues("criteries").filter((_, i) => i !== index),
    );
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "evaluationForms",
        path: "/home/evaluationForms",
        menuItemId: "evaluationForms",
      },
      {
        localizationKey: evaluationFormId ? "editEvaluationForms" : "addEvaluationForms",
        path: evaluationFormId ? `/home/evaluationForms/form?id=${evaluationFormId}` : "/home/evaluationForms/form",
        menuItemId: "evaluationForms",
      },
    ]);
  }, []);

  return (
    <form className="position-relative" noValidate onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay visible={loading} className="position-fixed" />

      {/* Page Header */}
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <div className="d-flex gap-2 align-items-start">
          <div>
            <h4 className="text-dark">{evaluationFormId ? t("updateForm") : t("addForm")}</h4>
            {evaluationFormSerialNumber && <span>{evaluationFormSerialNumber}</span>}
          </div>
          <Switch
            checked={watch("status") === "Active"}
            onChange={(checked) => {
              setValue("status", checked ? "Active" : "InActive");
            }}
          />
        </div>
        <div className="d-flex gap-2">
          <button className="btn btn-primary px-3" type="submit" disabled={submitting}>
            {submitting && <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />}
            {t("Common:save")}
          </button>
          <button
            className="btn btn-outline-secondary px-3"
            type="button"
            disabled={submitting}
            onClick={() => navigate(-1)}
          >
            {t("Common:cancel")}
          </button>
        </div>
      </div>

      <div className="divider" />

      {/* Main Info */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("mainInfo")} elementId={1}>
          <div className="row mb-3">
            <FormInput
              wrapperClassName="col-md-4 col-sm-12"
              label={t("formNameAr")}
              {...register("evaluationFormDetails.0.displayName")}
              error={t(errors.evaluationFormDetails?.[0]?.displayName?.message ?? "")}
            />
            <FormInput
              {...register("evaluationFormDetails.0.description")}
              error={t(errors.evaluationFormDetails?.[0]?.description?.message ?? "", { length: 3 })}
              wrapperClassName="col-md-8 col-sm-12"
              label={t("formDescAr")}
            />
          </div>
          <div className="row mb-3">
            <FormInput
              wrapperClassName="col-md-4 col-sm-12"
              label={t("formNameEn")}
              {...register("evaluationFormDetails.1.displayName")}
              error={t(errors.evaluationFormDetails?.[1]?.displayName?.message ?? "")}
            />
            <FormInput
              wrapperClassName="col-md-8 col-sm-12"
              label={t("formDescEn")}
              {...register("evaluationFormDetails.1.description")}
              error={t(errors.evaluationFormDetails?.[1]?.description?.message ?? "", { length: 3 })}
            />
          </div>
          <div className="row">
            {/* <LookupDropdown
              wrapperClassName="col-md-4 col-sm-12 mb-2"
              label={t("systemType")}
              service="lookupService"
              endpoint="getAllSystemTypes"
              formProps={register("systemTypeId", { valueAsNumber: true })}
              textValueKey="systemTypeDetails.displayName"
              idValueKey="id"
              error={t(errors.systemTypeId?.message ?? "")}
            /> */}
            {/* TODO: remove this after backend is ready - should return only elements available for funding portal admin */}
            <Dropdown
              wrapperClassName="col-md-4 col-sm-12 mb-2"
              label={t("systemType")}
              useReactSelect
              onChange={(value) => {
                setValue("systemTypeId", +value);
              }}
              value={watch("systemTypeId")}
              data={[
                {
                  id: 1,
                  value: "تمويل",
                },
              ]}
              error={t(errors.systemTypeId?.message ?? "")}
            />
            <LookupDropdown
              wrapperClassName="col-md-4 col-sm-12 mb-2"
              label={t("userType")}
              service="lookupService"
              endpoint="getAllUserTypes"
              textValueKey="userTypeDetails.displayName"
              idValueKey="id"
              error={t(errors.userTypeId?.message ?? "")}
              useReactSelect
              onChange={(value) => {
                setValue("userTypeId", +value);
              }}
              value={watch("userTypeId")}
            />
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* Criteria */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("criteria")} elementId={2}>
          {watch("criteries").map((_, index) => (
            <CriteriaElement
              key={index}
              register={register}
              index={index}
              onDelete={onCriteriaDelete}
              errors={errors}
            />
          ))}
          <CriteriaElementTemplate
            onAdd={({ nameAr, nameEn, weight }) => {
              setValue("criteries", [
                ...getValues("criteries"),
                {
                  weight,
                  criteriaDetails: [
                    { displayName: nameAr, locale: "Ar" },
                    { displayName: nameEn, locale: "En" },
                  ],
                },
              ]);
            }}
          />

          {watch("criteries").length > 0 && watch("criteries").reduce((acc, curr) => acc + curr.weight, 0) !== 100 && (
            <div className="text-danger">{t("pleaseCheckTotalWeight")}</div>
          )}
        </AccordionItem>
      </Accordion>

      {/* Form Builder */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("Common:description")} elementId={3}>
          <FormBuilderComponent
            formSchema={getValues().jsonSchema ? JSON.parse(getValues().jsonSchema ?? "{}") : {}}
            setFormSchema={(data) => {
              setValue("jsonSchema", JSON.stringify(data));
            }}
          />
        </AccordionItem>
      </Accordion>
    </form>
  );
};

export default EvaluationFormAddUpdateView;
