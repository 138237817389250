import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "components/blocks/FormInput";
import Modal from "components/blocks/Modal";
import useMutation from "hooks/useMutation";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/orders";
import { ProgramExtendTimeRequest } from "services/orders/models/OrdersTypes";
import { z } from "zod";
import { useNotification } from "../../../../../../../../../hooks/useNotification";

const ExtendRequestModal = ({
  isOpen,
  onClose,
  onConfirm,
  orderId,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  orderId: number;
}) => {
  const { t } = useTranslation("Orders");
  const { showNotification } = useNotification();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<ProgramExtendTimeRequest>({
    defaultValues: {
      notes: "",
      extendedTime: 0,
    },
    resolver: zodResolver(
      z.object({
        notes: z.string().min(1, t("Common:invalidValue")),
        extendedTime: z.number().min(1, t("Common:invalidValue")),
      }),
    ),
  });

  const { loading: isSubmitting, mutateAsync } = useMutation({
    queryFn: async (data: ProgramExtendTimeRequest) => {
      return await OrdersService.programOrderExtendTimeRequest(orderId, data);
    },
  });

  const onCancel = () => {
    onClose?.();
    reset();
  };

  // TODO: fund: editOrderByDeanOfScientificResearchTask research filed not loaded add researcher note
  const onSubmit = async (values: ProgramExtendTimeRequest) => {
    const res = await mutateAsync(values);

    if (res && !res.hasError) {
      onConfirm();
      showNotification({ description: t("Common:success"), type: "success" });
      reset();
    }
  };

  return (
    <Modal
      containerClassName="bg-white pb-2"
      isOpen={isOpen}
      onClose={onClose}
      title={t("extendRequest")}
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
      bodyMargin="py-3"
    >
      <form noValidate className="d-flex flex-column gap-2" onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label={t("extendDuration")}
          leftElement={<span className="text-primary">{t("month")}</span>}
          placeholder={t("extendDuration")}
          type="number"
          onChange={(e) => setValue("extendedTime", +e.target.value)}
          error={errors.extendedTime?.message}
          wrapperClassName="hide-number-controls"
        />

        <div>
          <label className="form-label mt-2">{t("Common:notes")}</label>
          <textarea
            className={`form-control`}
            rows={5}
            placeholder={t("Common:notes")}
            onChange={(e) => setValue("notes", e.target.value)}
            style={{ resize: "none" }}
          />
        </div>

        <div className="divider mt-3"></div>

        <div className="d-flex justify-content-center gap-2 w-100">
          <button type="button" className="btn text-dark rounded-3 p-10-32P" onClick={onCancel}>
            {t("Common:cancel")}
          </button>
          <button
            type="submit"
            className="btn btn-primary rounded-3 p-10-32P"
            disabled={isSubmitting || !watch("notes") || !watch("extendedTime")}
          >
            {!isSubmitting ? t("send") : <div className="custom-loader"></div>}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ExtendRequestModal;
