import type { RefereeSignUpFormType } from "../register.schema";
import { useTranslation } from "react-i18next";
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import FormInput from "components/blocks/FormInput";
import { useEffect, useState } from "react";
import { LookupService } from "services/lookupService";
import { DegreeResultResponse, JobResultResponse } from "services/lookupService/models/LookupsType";
import { useCookieContext } from "contexts";
import Dropdown, { DropdownItemType } from "components/blocks/Dropdown";
import UserTypeGuideModal from "./UserTypeGuideModal";
import "../registerView.scss";
import LookupDropdown from "components/blocks/LookupDropdown";

type ProfessionalInfoProps = {
  getFormProps: UseFormRegister<RefereeSignUpFormType>;
  formErrors: FieldErrors<RefereeSignUpFormType>;
  watch: UseFormWatch<RefereeSignUpFormType>;
  setValue: UseFormSetValue<RefereeSignUpFormType>;
  control: Control<RefereeSignUpFormType>;
};

const ProfessionalInfo = ({ control, getFormProps, formErrors, watch, setValue }: ProfessionalInfoProps) => {
  const { t } = useTranslation("RegisterView");
  const { isAr } = useCookieContext();

  const [isUserTypeGuideModalOpen, setIsUserTypeGuideModalOpen] = useState(false);
  const [JobTitleDdl, setJobTitleDdl] = useState<JobResultResponse[]>([]);
  const [degreeDdl, setDegreeDdl] = useState<DegreeResultResponse[]>([]);
  const userTypesDdl: DropdownItemType[] = [
    { value: t("postgraduateStudent"), id: 5 },
    { value: t("specialist"), id: 6 },
  ];

  const initializeAllDropDowns = async () => {
    const [jobsRes, degreesRes] = await Promise.all([LookupService.getJobs(), LookupService.getDegrees()]);

    setJobTitleDdl(jobsRes.data);
    setDegreeDdl(degreesRes.data);
  };

  useEffect(() => {
    initializeAllDropDowns();
  }, [isAr]);

  return (
    <>
      <div className="row">
        <div className="form-group my-2 col-6">
          <Dropdown
            noSelectionPlaceholder={t("userType")}
            label={
              <div className="d-flex align-items-center justify-content-between">
                <label>{t("userType")}</label>
                <div role="button" className="user-type-guide-button" onClick={() => setIsUserTypeGuideModalOpen(true)}>
                  <span>!</span>
                </div>
              </div>
            }
            data={userTypesDdl}
            error={formErrors.userType?.message}
            value={watch("userType")}
            onChange={(value) => {
              setValue("userType", +value);
            }}
            useReactSelect
          />
        </div>
        <div className="form-group my-2 col-6">
          <Dropdown
            noSelectionPlaceholder={t("job")}
            label={t("job")}
            data={JobTitleDdl.map((item) => ({
              value: item.jobDetail.displayName,
              id: item.id,
            }))}
            error={formErrors.jobId?.message}
            value={watch("jobId")}
            onChange={(value) => {
              setValue("jobId", +value);
            }}
            useReactSelect
          />
        </div>
      </div>

      <div className="row">
        <Controller
          name="specializationId"
          control={control}
          render={({ field: specialization }) => (
            <>
              <LookupDropdown
                label={t("generalSpecialization")}
                wrapperClassName="form-group my-2 col-6"
                useReactSelect
                service="lookupService"
                endpoint="getAllGeneralSpecialization"
                idValueKey="id"
                textValueKey="generalSpecializationDetail.displayName"
                value={specialization.value || ""}
                noSelectionPlaceholder={t("generalSpecialization") as string}
                onChange={(v, item) => {
                  setValue("specializationId", v ? +v : 0);
                  setValue("specialization", item?.value || "");
                }}
                error={formErrors.specialization?.message}
              />
              <Controller
                name="subspecialtyId"
                control={control}
                render={({ field }) => (
                  <LookupDropdown
                    label={t("specificSpecialization")}
                    wrapperClassName="form-group my-2 col-6"
                    useReactSelect
                    service="lookupService"
                    endpoint="getAllSubspecialtyByGeneralSpecializationId"
                    idValueKey="id"
                    disableApi={!specialization.value}
                    query={{ id: specialization.value }}
                    textValueKey="subspecialtyByGeneralSpecializationDetail.displayName"
                    value={field.value || ""}
                    noSelectionPlaceholder={t("specificSpecialization") as string}
                    onChange={(v) => {
                      setValue("subspecialtyId", v ? +v : 0);
                    }}
                    error={formErrors.subspecialtyId?.message}
                  />
                )}
              />
            </>
          )}
        />
      </div>
      <div className="row">
        <div className="form-group my-2 col-6">
          <FormInput
            type="text"
            label={t("university")}
            inputClassName="form-control"
            placeholder={t("university")}
            error={formErrors.university?.message}
            {...getFormProps("university")}
          />
        </div>
        <div className="form-group my-2 col-6">
          <Dropdown
            noSelectionPlaceholder={t("degree")}
            label={t("degree")}
            data={degreeDdl.map((item) => ({
              value: item.degreeDetail.displayName,
              id: item.id,
            }))}
            error={formErrors.degreeId?.message}
            value={watch("degreeId")}
            onChange={(value) => {
              setValue("degreeId", +value);
            }}
            useReactSelect
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group my-2 col-6">
          <LookupDropdown
            label={t("college")}
            wrapperClassName="col-lg-3 col-md-6 col-sm-12"
            useReactSelect
            service="lookupService"
            endpoint="getAllCollegesDropDown"
            idValueKey="id"
            textValueKey="collegeDetail.displayName"
            value={watch("collegeId")}
            noSelectionPlaceholder={t("college") as string}
            onChange={(v, item) => {
              setValue("collegeId", v ? +v : 0);
            }}
            error={formErrors.collegeId?.message}
          />
        </div>
      </div>

      <UserTypeGuideModal isOpen={isUserTypeGuideModalOpen} onClose={() => setIsUserTypeGuideModalOpen(false)} />
    </>
  );
};

export default ProfessionalInfo;
