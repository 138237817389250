import useMutation from "hooks/useMutation";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OrdersService } from "services/orders";
import { WorkFlowTaskRequest } from "services/orders/models/OrdersTypes";

const useGenericActions = ({ serialGuid, orderId,activityGuid }: { serialGuid: string; orderId: number, activityGuid:string }) => {
  const { t } = useTranslation("Orders");
  const navigate = useNavigate();
  const [genericModalOpenProps, setGenericModalOpenProps] = useState({
    isOpen: false,
    msg: "",
    action: "",
  });

  const { mutateAsync: genericWorkFlowAction, loading: confirmLoading } = useMutation({
    queryFn: async ({ data }: { data: WorkFlowTaskRequest }) => {
      return await OrdersService.genericWorkFlowAction(data, orderId);
    },
    notificationSuccessMessage: t("Common:success"),
  });

  const onGenericActionClicked = async (notes: string, action: string) => {
    const res = await genericWorkFlowAction({
      data: {
        actionName: action,
        activityGuid,
        serialGuid,
        notes,
      },
    });

    if (res && !res.hasError) {
      setGenericModalOpenProps({
        isOpen: false,
        msg: "",
        action: "",
      });
      navigate(`/home/tasks`);
    }
  };

  return {
    confirmLoading,
    onGenericActionClicked,
    genericModalOpenProps,
    setGenericModalOpenProps,
  };
};

export default useGenericActions;
