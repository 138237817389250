import { zodResolver } from "@hookform/resolvers/zod";
import useMutation from "hooks/useMutation";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/orders";
import { UpdateProgramExtendedRequest } from "services/programOrderUpdate/models";
import { z } from "zod";

const useEditExtendRequestForm = ({
  note,
  order,
  serialGuid,
  type,
  onModelClose,
  setTriggerOrderUpdate,
}: {
  type: string;
  order: any;
  serialGuid: string;
  note: string;
  onModelClose: (navigateTo?: string) => void;
  setTriggerOrderUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation("Orders");
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<UpdateProgramExtendedRequest>({
    defaultValues: {
      extendedTime: 0,
      fundingAmount: 0,
      notes: "",
    },
    resolver: zodResolver(
      z.object({
        extendedTime: z.number().min(1, t("extendPeriodMustBeGreaterThan0")),
      }),
    ),
  });

  const formRef = useRef<HTMLFormElement | null>(null);
  const { mutateAsync: sendProgramOrderUpdateResponse, loading: sendProgramOrderUpdateResponseLoading } = useMutation({
    queryFn: async (req: UpdateProgramExtendedRequest) => {
      const res =
        type === "ApproveExtendRequest"
          ? await OrdersService.acceptProgramOrderExtendRequest(order?.id ?? 0, {
              actionName: "Approve",
              notes: note,
              serialGuid: serialGuid,
            })
          : await OrdersService.updateProgramOrderExtendRequest(order?.id ?? 0, {
              ...req,
              notes: note,
            });

      if (res && !res.hasError) {
        if (type !== "ApproveExtendRequest") {
          setTriggerOrderUpdate((perv) => !perv);
          onModelClose();
        } else {
          onModelClose("/home/tasks");
        }
      }

      return res;
    },
    useDefaultNotification: true,
  });

  useEffect(() => {
    setValue("extendedTime", order?.extendedPeriodInMonth ?? 0);
    setValue("fundingAmount", order?.fundingAmount ?? 0);
  }, [order, type]);

  return {
    formRef,
    sendProgramOrderUpdateResponse,
    sendProgramOrderUpdateResponseLoading,
    setValue,
    errors,
    watch,
    handleSubmit,
  };
};

export default useEditExtendRequestForm;
