import { zodResolver } from "@hookform/resolvers/zod";
import DatePicker from "components/blocks/DatePicker";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import LookupDropdown from "components/blocks/LookupDropdown";
import Modal from "components/blocks/Modal";
import { useCookieContext } from "contexts";
import useMutation from "hooks/useMutation";
import { useQuery } from "hooks/useQuery";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UserResult } from "services/accountService/models/AccountType";
import { outOfOfficeService } from "services/outOfOfficeService";
import { OutOfOfficeRequest } from "services/outOfOfficeService/models";
import { z } from "zod";
import { GzipProvider } from "../../../../helpers/compress/gzipProvider";
import Cookies from "js-cookie";

type OutOfOfficeFormProps = OutOfOfficeRequest & {
  fromTime?: Date;
  toTime?: Date;
};

const OutOfOfficeModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { t, i18n } = useTranslation("Shared");
  const { userSession, setCookie } = useCookieContext();

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    control,
  } = useForm<OutOfOfficeFormProps>({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      fromTime: new Date(),
      toTime: new Date(),
      toUserId: "",
    },
    resolver: zodResolver(
      z
        .object({
          fromDate: z.date(),
          toDate: z.date(),
          fromTime: z.date(),
          toTime: z.date(),
          toUserId: z.string().nonempty({ message: "SelectUser" }),
        })
        .refine(
          (data) => {
            const fromTime = new Date(`${data.fromDate.toISOString().split("T")[0]} ${data.fromTime?.toTimeString()}`);
            const toTime = new Date(`${data.toDate.toISOString().split("T")[0]} ${data.toTime?.toTimeString()}`);

            return fromTime < toTime;
          },
          {
            path: ["fromDate"],
            message: "ToDateShouldBeGreaterThanFromDate",
          },
        ),
    ),
  });

  const { loading, mutateAsync } = useMutation({
    queryFn: async (outOfOfficeRequest: OutOfOfficeFormProps) => {
      return userSession?.outOfOfficeId
        ? await outOfOfficeService.updateOutOfOffice(userSession.outOfOfficeId, outOfOfficeRequest)
        : await outOfOfficeService.setOutOfOffice(outOfOfficeRequest);
    },
  });

  const { data, loading: LoadingOutOfOfficeData } = useQuery({
    queryFn: async () => {
      return await outOfOfficeService.getOutOfOfficeById(userSession?.outOfOfficeId ?? 0);
    },
    options: {
      enabled: !!userSession?.outOfOfficeId,
    },
  });

  useEffect(() => {
    if (data && !LoadingOutOfOfficeData) {
      setValue("fromDate", new Date(data.fromDate));
      setValue("toDate", new Date(data.toDate));
      setValue("fromTime", new Date(data.fromDate));
      setValue("toTime", new Date(data.toDate));
    }
  }, [data, LoadingOutOfOfficeData]);
  const gzipProvider = new GzipProvider();

  function isDateBetween(targetDate: Date, startDate: Date, endDate: Date) {
    const target = new Date(targetDate);
    const start = new Date(startDate);
    const end = new Date(endDate);

    return target >= start && target <= end;
  }
  const updateOutOfOfficeSession = (fromDate: Date, toDate: Date, res: any) => {
    let newSession = JSON.parse(gzipProvider.decompress(Cookies.get("Session")));
    if (isDateBetween(new Date(), fromDate, toDate)) newSession.isOutOfOffice = true;
    else newSession.isOutOfOffice = false;
    newSession.outOfOfficeId = res.data.id;
    setCookie("Session", gzipProvider.compress(JSON.stringify(newSession)), {
      sameSite: "strict",
      secure: true,
      expires: 7,
    });
  };
  const onSubmit = async (data: OutOfOfficeFormProps) => {
    const fromDate = new Date(`${data.fromDate.toISOString().split("T")[0]} ${data.fromTime?.toTimeString()}`);
    const toDate = new Date(`${data.toDate.toISOString().split("T")[0]} ${data.toTime?.toTimeString()}`);

    const outOfOfficeRequest: OutOfOfficeRequest = {
      fromDate,
      toDate,
      toUserId: data.toUserId,
    };

    const res: any = await mutateAsync(outOfOfficeRequest);

    if (res && !res.hasError) {
      updateOutOfOfficeSession(fromDate, toDate, res);
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("OutOfOffice")}
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
      bodyMargin="py-3"
      containerClassName="bg-white pb-2"
      bodyClassName="overflow-visible"
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <LoadingOverlay visible={loading || LoadingOutOfOfficeData} />
        <div className="d-flex flex-column gap-3 mb-2">
          <div className="d-flex gap-2 flex-wrap">
            <div style={{ minWidth: "200px" }} className="d-flex flex-column gap-2 flex-1">
              <label className="mb-1">{t("DateFromTo")}</label>
              <div className="d-flex gap-2 form-control align-items-center p-0-important">
                <DatePicker
                  placeholder={t("From")}
                  value={watch("fromDate")}
                  hideIcon
                  onChange={(date) => {
                    if (date) {
                      setValue("fromDate", date);
                    }
                  }}
                  inputClassNames="border-0"
                />
                <span>-</span>
                <DatePicker
                  placeholder={t("To")}
                  value={watch("toDate")}
                  onChange={(date) => {
                    if (date) {
                      setValue("toDate", date);
                    }
                  }}
                  inputClassNames="border-0"
                />
              </div>
            </div>
            <div style={{ minWidth: "200px" }} className="d-flex flex-column gap-2 flex-1">
              <label className="mb-1">{t("TimeFromTo")}</label>
              <div className="d-flex gap-2 form-control align-items-center p-0-important">
                <DatePicker
                  onlyTimePicker
                  hideIcon
                  value={watch("fromTime")}
                  placeholder={t("From")}
                  onChange={(date) => {
                    if (date) {
                      setValue("fromTime", date);
                    }
                  }}
                  inputClassNames="border-0"
                />
                <span>-</span>
                <DatePicker
                  onlyTimePicker
                  value={watch("toTime")}
                  placeholder={t("To")}
                  onChange={(date) => {
                    if (date) {
                      setValue("toTime", date);
                    }
                  }}
                  inputClassNames="border-0"
                />
              </div>
            </div>
          </div>
          {errors.fromDate?.message && (
            <div className="invalid-feedback d-block">{t("ToDateShouldBeGreaterThanFromDate")}</div>
          )}
          <div className="flex-1">
            <Controller
              control={control}
              name="toUserId"
              render={({ field: { value, onChange, onBlur } }) => (
                <LookupDropdown
                  label={t("Assign")}
                  enableSearch
                  service="accountService"
                  endpoint="getUsersDropdown"
                  idValueKey="id"
                  textValueKey={i18n.language === "ar" ? "arabicName" : "englishName"}
                  isPaginated
                  noSelectionPlaceholder={t("SelectUser")}
                  value={value}
                  onChange={(v) => {
                    onChange(v);
                  }}
                  query={{
                    search: data?.toUserName,
                  }}
                  onFetchEnd={(res: UserResult[]) => {
                    if (res) {
                      const findUser = res.find((user) => user.username === data?.toUserName);
                      if (findUser) {
                        setValue("toUserId", findUser.id);
                      }
                    }
                  }}
                  error={t(errors.toUserId?.message ?? "")}
                  useReactSelect
                />
              )}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center gap-2 my-3">
          <button type="button" className="p-10-56P btn border border-1 text-dark" onClick={onClose}>
            {t("Cancel")}
          </button>
          <button className="p-10-56P btn btn-primary text-white" disabled={loading}>
            {t("Assign")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default OutOfOfficeModal;
