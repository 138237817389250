import ActionModal from "components/blocks/Modal/ActionModal";
import useMutation from "hooks/useMutation";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExaminersService } from "services/examiners";
import { ExaminerEvaluationFormRef } from "../ExaminerTaskContent/ExaminerEvaluationForm";
import { ReturnExaminerOrderEvaluationFormRequest } from "services/examiners/Model/ExaminerTypes";

type ReturnButtonProps = {
  t: any;
  examinerProgramOrderId: number;
  serialGuid: string;
  activityGuid?: string;
  getRecommendations?: ExaminerEvaluationFormRef["getRecommendationsPropsOnReturn"];
};

const ReturnButton = ({ t, examinerProgramOrderId, serialGuid, getRecommendations ,activityGuid}: ReturnButtonProps) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, loading, mutateAsync, reset } = useMutation({
    queryFn: async ({
      examinerProgramOrderId,
      actionName,
      serialGuid, 
      recommendations,
    }: {
      examinerProgramOrderId: number;
    } & ReturnExaminerOrderEvaluationFormRequest) => {
      return await ExaminersService.returnExaminerOrderEvaluationForm(examinerProgramOrderId, {
        actionName,
        serialGuid,
        activityGuid,
        recommendations,
      });
    },
  });

  const returnEvaluationTask = async () => {
    if (!getRecommendations) return;
    const { recommendations, isValid } = getRecommendations();

    if (!isValid) {
      return;
    }

    await mutateAsync({
      actionName: "Return",
      examinerProgramOrderId,
      serialGuid: serialGuid,
      activityGuid,
      recommendations,
    });

    navigate("/home/tasks");
    reset();
  };

  return (
    <>
      {loading ? (
        <div className="custom-loader"></div>
      ) : (
        <button className="btn btn-outline-secondary px-4" onClick={() => setIsModalOpen(true)}>
          <i className="icon-return"></i>
          <span className="px-2">{t("returnEvaluationTask")}</span>
        </button>
      )}
      <ActionModal
        isOpen={isModalOpen}
        iconWrapperColor="secondary"
        actionIcon="icon-warning"
        headerMsg={t("confirmReturnEvaluationTaskHeaderMsg")}
        subMsg={t("confirmReturnEvaluationTaskSubMsg")}
        loading={loading}
        onActionConfirm={async () => {
          await returnEvaluationTask();
        }}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default ReturnButton;
