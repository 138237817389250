import React from "react";
import { iconClassNames } from "../../assets/style/theme/icon-classes";
import { useCookieContext } from "contexts";

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  type?: string;
  label?: string;
  required?: boolean;
  error?: string;
  correctValueMessage?: string;
  inputClassName?: string;
  labelClassName?: string;
  iconClassName?: keyof typeof iconClassNames;
  wrapperClassName?: string;
  leftElement?: React.ReactNode;
  withAsterisk?: boolean;
}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      placeholder,
      type = "text",
      label,
      required,
      error,
      correctValueMessage,
      inputClassName,
      labelClassName,
      iconClassName,
      wrapperClassName,
      leftElement,
      readOnly,
      withAsterisk,
      ...rest
    },
    ref,
  ) => {
    const { isRTL } = useCookieContext();
console.log(isRTL,"isRTL")
    return (
      <div className={wrapperClassName}>
        {label && (
          <div className={`mb-1 ${labelClassName} position-relative`}>
            <label className={`${withAsterisk ? "px-2" : ""}`}>{label}</label>
              {withAsterisk  && (
                  <span
                      className={`top-0 text-danger`}
                      style={{
                          fontSize: "0.75rem",
                      }}
                  >
                *
              </span>
              )}
          </div>
        )}
        <div
          className={`position-relative  ${error ? "invalid-input-wrapper" : ""} ${
            iconClassName ? "input-with-icon" : ""
          } ${readOnly ? "pe-none" : ""}`}
        >
          <input
            type={type}
            className={`form-control ${error ? "is-invalid" : ""} ${inputClassName ? inputClassName : ""}`}
            placeholder={placeholder}
            required={required}
            ref={ref}
            {...rest}
            readOnly={readOnly}
          />
          {leftElement && (
            <div
              className={`position-absolute ${
                isRTL ? "start-0" : "end-0"
              } top-50 translate-middle-y px-2 user-select-none`}
            >
              {leftElement}
            </div>
          )}

          {iconClassName && <i className={iconClassName} />}
        </div>
        {error && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {error}
          </div>
        )}
        {correctValueMessage && <div className="valid-feedback">{correctValueMessage}</div>}
      </div>
    );
  },
);

export default FormInput;
