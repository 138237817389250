import { useTranslation } from "react-i18next";
import { UserInformationResult } from "services/accountService/models/AccountType";

const BankingInfo = ({ user }: { user?: UserInformationResult | null }) => {
  const { t } = useTranslation("UserProfile");

  return (
    <div className="striped d-flex flex-column position-relative ">
      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("fullName")}</div>
        <div className="flex-2">{user?.beneficiaryName}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("bankName")}</div>
        <div className="flex-2">{user?.bankName}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{"IBAN"}</div>
        <div className="flex-2">{user?.ibanNumber}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("currency")}</div>
        <div className="flex-2">{user?.currency?.currencyDetail.displayName}</div>
      </div>
    </div>
  );
};

export default BankingInfo;
