import { zodResolver } from "@hookform/resolvers/zod";
import { CreateProgramMinutes } from "services/programs/models/ProgramTypes";
import { z } from "zod";

export const getAddProgramMinutesSchema = (t: any) => {
  const schema = z.object({
    committeeDecisionDate: z
      .string()
      .min(1, { message: t("Common:invalidValue") })
      .refine((val) => !isNaN(Date.parse(val)), { message: t("Common:invalidValue") }),
    committeeDecisionNumber: z.string().min(1, { message: t("Common:invalidValue") }),
    minuteDescription: z.string().min(1, { message: t("Common:invalidValue") }),
    filesIds: z.array(z.string()).min(1, { message: t("Common:pleaseUploadAtLeastOneFile") }),
  }) as z.ZodType<CreateProgramMinutes>;

  return zodResolver(schema);
};
