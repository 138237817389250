import { useCookieContext } from "contexts";
import { Dispatch, SetStateAction, useRef } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";

export interface DropdownItemType extends Record<string, any> {
  value: string;
  id: string | number;
}

export type DropdownProps = {
  label?: string | React.ReactNode;
  data: DropdownItemType[];
  noSelectionPlaceholder?: string;
  wrapperClassName?: string;
  value?: string | number;
  onChange?: (value: string, item: DropdownItemType, dbRes?: any[]) => void;
  disabled?: boolean;
  formProps?: any;
  selectClassName?: string;
  changeEffect?: (value?: any) => void;
  error?: string;
  labelClassName?: string;
  withOutMinWidth?: boolean;
  readOnly?: boolean;
  withAsterisk?: boolean;
  defaultValue?: string | number;
  wrapperStyle?: React.CSSProperties;
  useReactSelect?: boolean;
  minWidth?: number;
  dbRes?: any[];
  setQueryParams?: Dispatch<SetStateAction<Record<string, any> | undefined>>;
};

/**
 * @param {DropdownProps} props
 * @param {boolean} props.useReactSelect - If true, the component will use react-select instead of the default select element and formProps is ignored
 * @returns {React.FC}
 */
const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    label,
    data,
    noSelectionPlaceholder,
    wrapperClassName,
    value,
    onChange,
    disabled,
    selectClassName,
    formProps,
    changeEffect,
    error,
    labelClassName,
    withOutMinWidth,
    readOnly,
    withAsterisk,
    defaultValue,
    wrapperStyle,
    useReactSelect,
    minWidth,
    dbRes,
    setQueryParams,
  } = props;

  const searchDebounceId = useRef<NodeJS.Timeout>();
  const { isRTL } = useCookieContext();
  const { t } = useTranslation();
  const phoneNumberSelect = selectClassName?.includes("phone-number");

  const onLocalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(e.target.value, data.find((item) => `${item.id}` === e.target.value)!, dbRes);
    formProps?.onChange?.(e);
    changeEffect?.(e.target.value);
  };

  const onLocalReactSelectChange = (selectedOption: SingleValue<DropdownItemType>) => {
    onChange?.(`${selectedOption?.id}`, selectedOption as DropdownItemType, dbRes);
    changeEffect?.(selectedOption?.id);
  };

  const hasError = !value && !!error;
  const listItems = noSelectionPlaceholder ? data.concat({ id: "", value: noSelectionPlaceholder }) : data;

  return (
    <div
      className={`${wrapperClassName}`}
      style={
        !withOutMinWidth
          ? { minWidth: minWidth ?? 150, ...(wrapperStyle ? wrapperStyle : {}) }
          : { ...(wrapperStyle ? wrapperStyle : {}) }
      }
    >
      {label && (
        <div className={`mb-1 ${labelClassName} position-relative`}>
          {typeof label === "string" ? <label className={`${withAsterisk ? "px-2" : ""}`}>{label}</label> : label}
          {withAsterisk && (
              <span
                  className={`text-danger`}
                  style={{
                    fontSize: "0.75rem",
                  }}
              >
              *
            </span>
          )}
        </div>
      )}
      {useReactSelect ? (
        <Select
          isDisabled={disabled}
          className={`${hasError ? "is-invalid" : ""}`}
          placeholder={noSelectionPlaceholder ?? label}
          value={listItems.find((item) => item.id === (value ?? ""))}
          onChange={onLocalReactSelectChange}
          isOptionDisabled={(option) => option.id === ""} // Disable the placeholder option
          options={listItems}
          getOptionLabel={(option) => option.value}
          noOptionsMessage={() => t("Common:noData")}
          menuPortalTarget={document.body} // Render the dropdown outside the container
          menuPosition="absolute" // Ensure dropdown is fixed and independent of parent scroll
          styles={{
            control: (styles) => ({
              ...styles,
              borderColor: hasError ? "red" : styles.borderColor,
              backgroundColor: hasError ? "#f8d7da" : styles.backgroundColor,
              height: "42px",
            }),
            singleValue: (styles) => ({
              ...styles,
              ...(phoneNumberSelect &&
                isRTL && {
                  direction: "ltr",
                  textAlign: "right",
                }),
            }),
            menuList: (styles) => ({
              ...styles,
              ...(phoneNumberSelect &&
                isRTL && {
                  direction: "ltr",
                  textAlign: "right",
                }),
            }),
            menuPortal: (styles) => ({
              ...styles,
              zIndex: 2500, // Ensure dropdown renders above all other elements
            }),
          }}
          onInputChange={(inputValue) => {
            if (!setQueryParams) return;

            clearTimeout(searchDebounceId.current);
            searchDebounceId.current = setTimeout(() => {
              setQueryParams(
                formProps?.queryParams
                  ? {
                      ...formProps.queryParams,
                      search: inputValue,
                    }
                  : { search: inputValue },
              );
            }, 500);
          }}
        />
      ) : (
        <select
          value={value}
          className={`form-select form-control ${selectClassName} ${hasError ? "is-invalid" : ""} ${
            readOnly ? "bg-white" : ""
          }`}
          aria-label={label}
          disabled={disabled || readOnly}
          {...formProps}
          onChange={onLocalChange}
          defaultValue={defaultValue}
          onInput={(e) => {
            if (!setQueryParams) return;

            clearTimeout(searchDebounceId.current);
            searchDebounceId.current = setTimeout(() => {
              setQueryParams(
                formProps?.queryParams
                  ? {
                      ...formProps.queryParams,
                      search: e.currentTarget.value,
                    }
                  : { search: e.currentTarget.value },
              );
            }, 500);
          }}
        >
          <option value="" className="text-muted" disabled>
            {noSelectionPlaceholder ?? label}
          </option>
          {data.map((item) => (
            <option key={item.id} value={item.id}>
              {item.value}
            </option>
          ))}
        </select>
      )}
      {hasError && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default Dropdown;
