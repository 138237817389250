import FormInput from "components/blocks/FormInput";
import React from "react";
import { FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UpdateProgramExtendedRequest } from "services/programOrderUpdate/models";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";

const EditExtendRequestForm = ({
  formRef,
  type,
  program,
  setValue,
  errors,
  watch,
}: {
  formRef: React.RefObject<HTMLFormElement>;
  type: string;
  program: ProgramByIDResponse | null;
  setValue: UseFormSetValue<UpdateProgramExtendedRequest>;
  errors: FieldErrors<UpdateProgramExtendedRequest>;
  watch: UseFormWatch<UpdateProgramExtendedRequest>;
}) => {
  const { t } = useTranslation("Orders");

  // on submit do not refresh the page
  return (
    <form ref={formRef} noValidate className="d-flex gap-3 my-2">
      {type !== "ApproveExtendRequest" && (
        <FormInput
          type="number"
          label={t("newExtendDuration")}
          placeholder={t("newExtendDuration")}
          onChange={(e) => {
            setValue("extendedTime", +e.target.value);
          }}
          value={watch("extendedTime")}
          wrapperClassName="flex-1"
          error={errors.extendedTime?.message}
          leftElement={<span className="text-primary">{t("month")}</span>}
        />
      )}

      <FormInput
        label={t("newContractDuration")}
        placeholder={t("newContractDuration")}
        disabled /* ={type === "ApproveExtendRequest"} */
        value={(program?.fundingDurationInMonths ?? 0) + watch("extendedTime")}
        wrapperClassName="flex-1"
        leftElement={<span className="text-primary">{t("month")}</span>}
      />

      <FormInput
        label={t("contractAmount")}
        placeholder={t("contractAmount")}
        onChange={(e) => {
          setValue("fundingAmount", +e.target.value);
        }}
        disabled
        value={watch("fundingAmount")}
        error={errors.fundingAmount?.message}
        wrapperClassName="flex-1"
        leftElement={<span className="text-primary">{t("ryal")}</span>}
      />
    </form>
  );
};

export default EditExtendRequestForm;
