import { useTranslation } from "react-i18next";
import { GridFilterProps } from "components/GridView/GridView.types";
import DatePicker from "components/blocks/DatePicker";
import useGridStore from "components/GridView/grid.store";
import { useShallow } from "zustand/react/shallow";
import Dropdown from "components/blocks/Dropdown";
import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";
import { useMemo, useRef, useState } from "react";
import { DateObject } from "react-multi-date-picker";
import LookupDropdown from "components/blocks/LookupDropdown";

export const prepareFilters = (filters: Record<string, any>) => {
  return Object.keys(filters)
    .filter((key) => filters[key])
    .reduce((acc, key) => {
      const value = filters[key];

      if (value.isDate) {
        const startDate = value.value[0];
        const endDate = value.value[1];

        const startOfTheDay = new Date(startDate);
        startOfTheDay.setHours(0, 0, 0, 0);
        acc.push({
          field: key,
          value: startOfTheDay,
          operator: "GreaterThan",
        });

        if (endDate) {
          const endOfTheDay = new Date(endDate);
          endOfTheDay.setHours(23, 59, 59, 999);
          acc.push({
            field: key,
            value: endOfTheDay,
            operator: "LessThan",
          });
        }
      } else {
          if(value.value && value.value !=="")
        acc.push({
          field: key,
          value:
            typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
              ? filters[key].dateType && filters[key].dateType=="number"?  parseInt(filters[key].value) : filters[key].value
              : filters[key].dateType && filters[key].dataType=="number"?  parseInt(filters[key]) : filters[key],
          operator:
            typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
              ? filters[key].operator
              : "Contains",
        });
      }

      return acc;
    }, [] as { field: string; value: any; operator: string }[]);
};

const GridFilter: React.FC<GridFilterProps> = ({ gridSchema }) => {
  const { t } = useTranslation("Common");

  const { modelFilterPined, toggleModelFilterPin, modelFilterVisible, setFilters } = useGridStore(
    useShallow((state) => ({
      modelFilterPined: state.modelFilterPined,
      toggleModelFilterPin: state.toggleModelFilterPin,
      modelFilterVisible: state.modelFilterVisible,
      filters: state.filters,
      setFilters: state.setFilters,
    })),
  );

  const [gridFilters, initialState] = useMemo(() => {
    const f = gridSchema
      .filter((schema) => schema.filterable)
      .map((schema) => ({
        field: schema.field,
        filterDisplayValue: schema.filterDisplayValue, 
          operator: schema.operator,
          dataType:schema.dataType || undefined,
        displayName: schema.displayName,
        inputType: schema.filterOptions?.type || "text",
        options: schema.filterOptions?.options || [],
        serverSideSelectProps: schema.filterOptions?.serverSideSelectProps || null,
      }));
    const s = f.reduce((acc, filter) => ({ ...acc, [filter.field]: {value:""} }), {});
    return [f, s];
  }, [gridSchema]);

  const [localFilters, setLocalFilters] = useState<Record<string, any>>(initialState);

  const onValueChange = (filed: string, value: any) => {
    setLocalFilters((prev) => ({ ...prev, [filed]: value }));
  };

  const onSubmit = () => {
    setFilters(localFilters);
  };

  const onReset = () => {
    setLocalFilters(initialState);
    
    // setFilters({});
  };

  return (
    <div
      className={`w-100 my-3 d-flex justify-content-between align-items-end flex-wrap ${
        modelFilterPined || modelFilterVisible ? "" : "d-none"
      }`}
    >
      <div className="d-flex flex-wrap gap-3 align-items-end flex-grow-mobile">
        {gridFilters.map((filter) => {
          if (filter.inputType === "text") {
            return (
              <FormInput
                wrapperClassName="flex-grow-mobile"
                key={filter.displayName}
                label={filter.filterDisplayValue || filter.displayName}
                placeholder={filter.filterDisplayValue || filter.displayName}
                value={localFilters[filter.field].value}
                onChange={(e) => {
                  onValueChange(filter.field, { value:e.currentTarget.value, operator: filter.operator??'contains',dateType:filter.dataType} );
                }}
              />
            );
          } else if (filter.inputType === "select") {
            return (
              <Dropdown
                key={filter.displayName}
                wrapperClassName="flex-grow-1"
                label={filter.filterDisplayValue || filter.displayName}
                noSelectionPlaceholder={filter.filterDisplayValue || filter.displayName}
                value={localFilters[filter.field].value ?? ""}
                data={filter.options}
                onChange={(value) => {
                  onValueChange(filter.field, {value, operator: filter.operator??'contains',dateType:filter.dataType});
                }}
                useReactSelect
              />
            );
          } else if (filter.inputType === "serverSideSelect" && filter.serverSideSelectProps) {
              <LookupDropdown
                  key={filter.displayName}
                  {...filter.serverSideSelectProps}
                  service={filter.serverSideSelectProps.service}
                  endpoint={filter.serverSideSelectProps.endpoint as never}
                  value={localFilters[filter.field] ?? ""}
                  onChange={(value: any) => {
                      onValueChange(filter.field, {value, operator: filter.operator??'contains',dateType:filter.dataType});
                  }}
                  multiselect={filter.serverSideSelectProps.multiselect as any}
                  useReactSelect
              />;
          } else if (filter.inputType === "date") {
            return (
              <DatePicker
                range={true}
                key={filter.displayName}
                wrapperClassName="flex-grow-1"
                placeholder={filter.filterDisplayValue || filter.displayName}
                label={filter.filterDisplayValue || filter.displayName}
                value={localFilters[filter.field].value}
                onChange={(dates) => {
                  if (dates) {
                    const datePart = dates.map((date) => new DateObject(date).format("YYYY-MM-DD"));
                    onValueChange(filter.field, { value: datePart, operator: "GreaterThan", isDate: true });
                  }
                }}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      <div className="form-group d-flex gap-2 align-items-end mt-2">
        <button type="button" className="btn btn-outline-primary p-2" onClick={onSubmit}>
          {t("filterNow")}
        </button>
        <button type="button" className="btn btn-outline-danger p-2" onClick={onReset}>
          {t("reset")}
        </button>
        <IconButton
          icon="icon-pin"
          onClick={toggleModelFilterPin}
          size="md"
          variant={modelFilterPined ? "secondary" : "outline-secondary"}
        />
      </div>
      <input id="jsonData" name="jsonData" type="hidden" value="" />
    </div>
  );
};

export default GridFilter;
