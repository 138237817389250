import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./routes/appRoutes/appRoutes";
import { SwitchRoute } from "./components/route/SwitchRoute";
import "@progress/kendo-theme-default/dist/all.css";

function App() {
  return (
    <div className="h-100">
      <Router>
        <SwitchRoute routes={AppRoutes} />
      </Router>
    </div>
  );
}

export default App;
