import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { ExaminerRewardsService } from "services/examinerRewards";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { CurrentExaminerRewardLocalResult } from "services/examinerRewards/Models/ExaminerRewardsTypes";
import { useCookieContext } from "contexts";
import { prepareFilters } from "components/GridView/components/GridFilter";

export default function MyRewardsGridView() {
  const { t } = useTranslation(["ExaminerRewards", "Common"]);
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { showNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumb();
  const { isAr } = useCookieContext();

  const [loading, setLoading] = useState(false);
  const [ExaminerRewards, setExaminerRewards] = useState<CurrentExaminerRewardLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const ready = useRef(false);

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchExaminerRewards = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await ExaminerRewardsService.getAllExaminerRewardsForCurrentUser({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: prepareFilters(filters),
      });

      setExaminerRewards(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchExaminerRewards();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchExaminerRewards({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "rewards",
        path: "/home/rewards",
        menuItemId: "rewards",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "activityName",
        displayName: t("Tasks:taskName"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
      },
      {
        field: "workFlowName",
        filterable: true,
        displayName: t("Tasks:workflowName"),
        sortable: true,
      },
      {
        field: "activityDate",
        displayName: t("Tasks:taskDate"),
        sortable: true,
        filterable: true,
        render(row: CurrentExaminerRewardLocalResult) {
          return <>{toSystemThemeDateFormat(row.activityDate)}</>;
        },
      },
      {
        field: "activityDescription",
        displayName: t("Tasks:description"),
      },
      {
        field: "value",
        displayName: t("rewardAmount"),
        sortable: true,
        filterable: true,
        render(row: CurrentExaminerRewardLocalResult) {
          return (
            <span>
              {row.value} {t("riyal")}
            </span>
          );
        },
      },
      {
        field: "status",
        displayName: t("paymentStatus"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        filterOptions: {
          type: "select",
          options: [
            { id: "Paid", value: t("paid") },
            { id: "NotPaid", value: t("notpaid") },
            { id: "Reject", value: t("reject") },
            { id: "Pending", value: t("pending") },
          ],
        },
        render(row: CurrentExaminerRewardLocalResult) {
          const statusClassName =
            row.status === "NotPaid" || row.status === "Reject"
              ? "bg-danger text-danger"
              : row.status === "Paid"
              ? "bg-success text-success"
              : "bg-primary text-primary";
          return (
            <span className={`badge rounded-4 ${statusClassName} bg-opacity-10 py-2`}>
              {t(row.status.toLowerCase())}
            </span>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("rewards"),
    singularName: t("reward"),
    totalCount: totalCount,
  };

  return (
    <GridView
      loading={loading}
      gridProps={{
        data: ExaminerRewards ?? [],
        gridSchema,
      }}
      viewHeaderProps={viewHeader}
    />
  );
}
