import PageWithTabs from "components/PageWithTabs";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { myWorkStatusClassNames } from "../MyUnsignedWorkGridView";
import AboutProgramTab from "views/home/programs/ViewProgram/tabs/AboutProgramTab";
import { useCallback, useEffect, useState } from "react";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import { useNotification } from "hooks/useNotification";
import { ProgramsService } from "services/programs";
import { useCookieContext } from "contexts";
import { OrderStatus } from "../../../../../shared/lookups";
import {useQuery} from "../../../../../hooks/useQuery";
import {OrdersService} from "../../../../../services/orders";

const MyUnsignedWorkView = () => {
  // Hooks
  const { t } = useTranslation("Programs");
  const { isAr } = useCookieContext();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { orderId } = useParams();
  const navigate = useNavigate();

  // State
  const { data: order, loading } = useQuery({
    queryFn: async () => {
      return await OrdersService.getOneById(+orderId!);
    },
    options: {
      enabled: !!orderId,
    },
    triggers: [orderId],
    onError: () => {
      navigate(-1);
    },
    initialLoading: !!orderId,
  });

  //Program Api
  const { data: programData, loading: programLoading } = useQuery({
    queryFn: async () => {
      const id = order?.programId ? order.programId : 0;

      return await ProgramsService.getProgramById(id);
    },
    triggers: [order?.programId],
    options: { enabled: !!order?.programId },
  });

  const programName = programData
    ? `${programData?.programDetails.find((x) => x.locale === (isAr ? "Ar" : "En"))?.displayName}`
    : "";

  return (
    <>
      <PageWithTabs
        title={<div className="d-flex gap-3">{programName}</div>}
        subTitle={` ${t("Orders:programNumber")}: ${programData?.serialNumber}`}
        loading={programLoading || loading}
        pageHeaders={[
          {
            title: t("OrdersUnsignedWork:orderNumber"),
            value: order?.serialNumber ?? "",
          },
          {
            title: t("OrdersUnsignedWork:submissionDate"),
            value: toSystemThemeDateFormat(order?.createdOn),
          },
          {
            title: t("OrdersUnsignedWork:researchCenter"),
            value: order?.centreManagement?.displayName ?? "",
          },
          {
            title: t("OrdersUnsignedWork:orderPhase"),
              value: order?.orderPhase ?? "",
          },
        ]}
        actions={(selectedTabId) => {
          return (
            <span className={`badge rounded-4 ${myWorkStatusClassNames[order?.orderStatusId ?? OrderStatus.Inprogress] as string} py-2`}>
              {order?.orderStatus}
            </span>
          );
        }}
        tapContent={
          <>
            {loading && <LoadingOverlay visible={loading} />}
            {!loading && !!programData && <AboutProgramTab program={programData} />}
          </>
        }
      />
    </>
  );
};

export default MyUnsignedWorkView;
