import { HttpClient } from "helpers";
import { BaseResponseType, DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import {
  AdditionalAttachmentsResult,
  CentreManagementLocalResult,
  CreateAdditionalAttachmentRequest,
  CreateOrderRequest,
  CreateProgramMinuteRequest,
  CreateProgramSpeechesRequest,
  GetAllOrdersResponse,
  ProgramOrderLocalResult,
  OrderLocalItemResult,
  OrderResult,
  PdfTemplateLocalResult,
  ProgramMinutesResult,
  ProgramSpeechesResult,
  UserProgramOrdersResult,
  GetAllMyProgramsOrderSignedStatusResult,
  OrderActionResult,
  UpdateProgramEvaluationOrderRequest,
  ReturnOrderRequest,
  SendToFinanceUnitRequest,
  ConfirmFundingUnitRequest,
  ReturnFundingUnitRequest,
  FinalCommitteeDecisionType,
  WorkFlowTaskRequest,
  ProgramExtendTimeRequest,
  ProgramApologiesRequest,
  ProgramOrderUpdateRequest,
  PdfTemplateLocalItemResult,
} from "./models/OrdersTypes";
import { AxiosResponse } from "axios";
import { SendProgramExtendedRequest, UpdateProgramExtendedRequest } from "services/programOrderUpdate/models";
import { ResearcherPaymentLocalResult } from "services/researcherPayments/models/ResearcherPaymentsType";

export const OrdersService = {
  getAllOrdersAsync: async (data: DataGridRequest) => {
    return await HttpClient.post<GetAllOrdersResponse>("/api/Orders/GetAllOrders", data);
  },
  getOneById: async (id: number) => {
    return await HttpClient.get<BaseResponseType<OrderLocalItemResult>>(`/api/Orders/GetOrderById/${id}`);
  },
  createOrder: async (data: CreateOrderRequest) => {
    return await HttpClient.post<BaseResponseType<OrderResult>>("/api/Orders/CreateOrder", data);
  },
  exportToExcel: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/Orders/ExportOrdersToExcel", data, {
      responseType: "blob",
    });
  },
  getManagementCentersDropdown: async (data: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<CentreManagementLocalResult>>(
      "/api/Orders/GetManagementCentersDropdown",
      data,
    );
  },
  updateOrder: async (id: number, data: CreateOrderRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(`/api/Orders/UpdateOrder/${id}`, data);
  },
  returnOrder: async (programOrderId: number, data: ReturnOrderRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(`/api/Orders/ReturnOrder/${programOrderId}`, data);
  },

  sendToFinanceUnit: async (programOrderId: number, data: SendToFinanceUnitRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(`/api/Orders/SendToFinanceUnit/${programOrderId}`, data);
  },

  confirmFundingUnit: async (programOrderId: number, data: ConfirmFundingUnitRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/ConfirmFundingUnit/${programOrderId}`,
      data,
    );
  },
  returnOrderToCentreManagement: async (programOrderId: number, data: ReturnFundingUnitRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/ReturnOrderToCentreManagement/${programOrderId}`,
      data,
    );
  },
  changeFinalCommitteeDecisionProgramOrderStatus: async (id: number, request: FinalCommitteeDecisionType) => {
    return await HttpClient.put<BaseResponseType<any>>(
      `/api/Orders/ChangeFinalCommitteeDecisionProgramOrderStatus/${id}/type/${request}`,
    );
  },

  programOrderResearcherSignContract: async (id: number, request: WorkFlowTaskRequest) => {
    return HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/ProgramOrderResearcherSignContract/${id}`,
      request,
    );
  },
  programOrderResearcherApologiesContract: async (id: number, request: WorkFlowTaskRequest) => {
    return HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/ProgramOrderResearcherApologiesContract/${id}`,
      request,
    );
  },
  programOrderResearcherClarificationRequestContract: async (id: number, request: WorkFlowTaskRequest) => {
    return HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/ProgramOrderResearcherClarificationRequestContract/${id}`,
      request,
    );
  },
  programOrderDSRSignContract: async (id: number, request: WorkFlowTaskRequest) => {
    return HttpClient.put<BaseResponseType<OrderResult>>(`/api/Orders/ProgramOrderDSRSignContract/${id}`, request);
  },
  programOrderDSRReturnContract: async (id: number, request: WorkFlowTaskRequest) => {
    return HttpClient.put<BaseResponseType<OrderResult>>(`/api/Orders/ProgramOrderDSRReturnContract/${id}`, request);
  },

  // program minutes
  createProgramMinute: async (data: CreateProgramMinuteRequest) => {
    return await HttpClient.post<BaseResponseType<ProgramMinutesResult>>("/api/Orders/CreateProgramMinute", data);
  },
  getAllProgramMinutesByProgramOrderId: async (programOrderId: number) => {
    return await HttpClient.get<PagedResponseType<ProgramMinutesResult>>(
      `/api/Orders/GetAllProgramMinutesByProgramOrderId?programOrderId=${programOrderId}`,
    );
  },
  // program speeches
  createProgramSpeeches: async (request: CreateProgramSpeechesRequest) => {
    return await HttpClient.post<BaseResponseType<ProgramSpeechesResult>>("/api/Orders/CreateProgramSpeeches", request);
  },
  getAllProgramSpeechesByProgramOrderId: async (programOrderId: number) => {
    return await HttpClient.get<PagedResponseType<ProgramSpeechesResult>>(
      `/api/Orders/GetAllProgramSpeechesByProgramOrderId?programOrderId=${programOrderId}`,
    );
  },
  deleteProgramSpeech: async (id: number) => {
    return await HttpClient.delete<BaseResponseType<ProgramSpeechesResult>>(`/api/Orders/DeleteProgramSpeech?id=${id}`);
  },
  downloadSpeechAsPdf: async (speechId: number) => {
    return await HttpClient.get<AxiosResponse<Blob>>(`/api/Orders/ProgramSpeeches/DownloadPdf/${speechId}`, {
      responseType: "blob",
    });
  },
  downloadSpeechAsDoc: async (speechId: number) => {
    return await HttpClient.get<AxiosResponse<Blob>>(`/api/Orders/ProgramSpeeches/DownloadDoc/${speechId}`, {
      responseType: "blob",
    });
  },
  // additional attachments
  getAllAdditionalAttachmentsByProgramOrderId: async (programOrderId: number) => {
    return await HttpClient.get<PagedResponseType<AdditionalAttachmentsResult>>(
      `/api/Orders/GetAllAdditionalAttachmentsByProgramOrderId?programOrderId=${programOrderId}`,
    );
  },
  createAdditionalAttachment: async (request: CreateAdditionalAttachmentRequest) => {
    return await HttpClient.post<BaseResponseType<AdditionalAttachmentsResult>>(
      "/api/Orders/CreateAdditionalAttachment",
      request,
    );
  },
  // pdf templates
  getAllPdfTemplates: async (data: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<PdfTemplateLocalResult>>("/api/Orders/GetAllPdfTemplates", data);
  },
  getOrderUsersByProgramId: async (programId: number, data: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<UserProgramOrdersResult>>(
      `/api/Orders/GetOrderUsersByProgramId?programId=${programId}`,
      data,
    );
  },
  getAllMyProgramsOrder: async (data: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<ProgramOrderLocalResult>>("/api/Orders/getAllMyProgramsOrder", data);
  },
  getAllMyProgramsOrderSignedStatus: async (data: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<GetAllMyProgramsOrderSignedStatusResult>>(
      "/api/Orders/GetAllMyProgramsOrderSignedStatus",
      data,
    );
  },
  exportMyProgramsOrderToExcel: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/Orders/ExportMyProgramsOrderToExcel", data, {
      responseType: "blob",
    });
  },
  exportMyProgramsOrderSignedStatusToExcel: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/Orders/ExportMyProgramsOrderSignedStatusToExcel", data, {
      responseType: "blob",
    });
  },
  deleteOrder: async (id: number) => {
    return await HttpClient.delete<BaseResponseType<OrderResult>>(`/api/Orders/DeleteOrder/${id}`);
  },
  getOrderActions: async (orderId: number) => {
    return await HttpClient.get<BaseResponseType<OrderActionResult[]>>(
      `/api/Orders/GetAllProgramOrderActionsByOrderId?orderId=${orderId}`,
    );
  },
  updateProgramEvaluationOrder: async (programOrderEvaluationId: number, data: UpdateProgramEvaluationOrderRequest) => {
    return await HttpClient.put<BaseResponseType<any>>(
      `/api/Orders/UpdateProgramEvaluationOrder?programOrderEvaluationId=${programOrderEvaluationId}`,
      data,
    );
  },

  // Program Order Update
  programOrderUpdateRequest: async (id: number, request: ProgramOrderUpdateRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(`/api/Orders/ProgramOrderUpdateRequest/${id}`, request);
  },
  programOrderApologiesRequest: async (id: number, request: ProgramApologiesRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/ProgramOrderApologiesRequest/${id}`,
      request,
    );
  },
  programOrderExtendTimeRequest: async (id: number, request: ProgramExtendTimeRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/ProgramOrderExtendTimeRequest/${id}`,
      request,
    );
  },

  acceptProgramOrderUpdateRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/AcceptProgramOrderUpdateRequest/${id}`,
      request,
    );
  },
  rejectProgramOrderUpdateRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/RejectProgramOrderUpdateRequest/${id}`,
      request,
    );
  },
  returnOrRejectProgramOrderUpdateRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/ReturnProgramOrderUpdateRequest/${id}`,
      request,
    );
  },
  sendProgramOrderUpdateRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/SendProgramOrderUpdateRequest/${id}`,
      request,
    );
  },

  acceptProgramOrderApologiesRequestByDeanOfScientificResearch: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/AcceptProgramOrderApologiesRequest/${id}`,
      request,
    );
  },
  rejectProgramOrderApologiesRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/RejectProgramOrderApologiesRequest/${id}`,
      request,
    );
  },
  returnProgramOrderApologiesRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/ReturnProgramOrderApologiesRequest/${id}`,
      request,
    );
  },
  sendProgramOrderApologiesRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/SendProgramOrderApologiesRequest/${id}`,
      request,
    );
  },
  centralManagerAcceptProgramOrderApologiesRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/CentralManagerAcceptProgramOrderApologiesRequest/${id}`,
      request,
    );
  },

  acceptProgramOrderExtendRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/AcceptProgramOrderExtendRequest/${id}`,
      request,
    );
  },
  rejectProgramOrderExtendRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/RejectProgramOrderExtendRequest/${id}`,
      request,
    );
  },
  updateProgramOrderExtendRequest: async (id: number, request: UpdateProgramExtendedRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/UpdateProgramOrderExtendRequest/${id}`,
      request,
    );
  },
  returnProgramOrderExtendRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/ReturnProgramOrderExtendRequest/${id}`,
      request,
    );
  },
  sentProgramOrderExtendRequest: async (id: number, request: SendProgramExtendedRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/SentProgramOrderExtendRequest/${id}`,
      request,
    );
  },

  startProgramOrderQueryRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/StartProgramOrderQueryRequest/${id}`,
      request,
    );
  },
  centerManagerProgramOrderQueryRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/CenterManagerProgramOrderQueryRequest/${id}`,
      request,
    );
  },

  researcherProgramOrderQueryRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(
      `/api/Orders/ResearcherProgramOrderQueryRequest/${id}`,
      request,
    );
  },

  // Generic workflow actions
  genericWorkFlowAction: async (data: WorkFlowTaskRequest, orderId: number) => {
    return await HttpClient.put<BaseResponseType<boolean>>(`/api/Orders/GenericWorkflowAction/${orderId}`, data);
  },

  getContractPdfByOrderId: async (id: number) => {
    return await HttpClient.get<BaseResponseType<PdfTemplateLocalItemResult>>(
      `/api/Orders/GetContractPdfByOrderId/${id}`,
    );
  },

  getReceiptByOrderId: async (id: number) => {
    return await HttpClient.get<PagedResponseType<ResearcherPaymentLocalResult>>(
      `/api/Orders/GetReceiptByOrderId/${id}`,
    );
  },
};
