import IconButton from "components/blocks/IconButton";
import Switch from "components/blocks/Switch";
import { useTranslation } from "react-i18next";

import "./ProgramFormView.scss";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FormInput from "components/blocks/FormInput";
import Dropdown, { DropdownItemType } from "components/blocks/Dropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import DatePicker from "components/blocks/DatePicker";
import FileInput from "components/blocks/FileInput";
import FilePreview from "components/blocks/FileInput/FilePreview";
import RangeInput from "components/blocks/RangeInput";
import { useEffect, useState } from "react";
import { LookupService } from "services/lookupService";
import { useForm } from "react-hook-form";
import { CreateProgramRequest, createProgramSchema } from "../program.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import LookupDropdown from "components/blocks/LookupDropdown";
import { useCookieContext } from "contexts";
import ProgramTemplateEditorModal from "./ProgramTemplateEditorModal";
import FinancingCalculationItemTemplate from "./FinancingCalculationItemTemplate";
import { ProgramsService } from "services/programs";
import { useNotification } from "hooks/useNotification";
import useProgramForm from "./useProgramForm";
import PaginatedLookupDropdown, {
  DropdownItemType as PaginatedDropdownItemType,
} from "components/blocks/PaginatedLookupDropdown";
import FormBuilderComponent from "../../../../../components/formBuilder/FormBuilderComponent";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";

// TODO: Extract the building blocks into components (e.g. Page Header...)
// TODO: Error handling

const now = new Date();

const ProgramFormView = () => {
  // Hooks
  const { t } = useTranslation("Programs");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isRTL, isAr } = useCookieContext();
  const { setBreadcrumbs } = useBreadcrumb();
  const { showNotification } = useNotification();
  const { mapProgramByIdToCreateRequest } = useProgramForm();

  // State
  const programId = searchParams.get("id");
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fundingTypeLookups, setFundingTypeLookups] = useState<DropdownItemType[]>([]);
  const [isConditionAndRulesTemplateLoading, setIsConditionAndRulesTemplateLoading] = useState(false);
  const [isCommitmentTemplateLoading, setIsCommitmentTemplateLoading] = useState(false);
  const [programSerialNumber, setProgramSerialNumber] = useState("");
  const [conditionAndRulesIds, setConditionAndRulesIds] = useState({
    ruleId: 0,
    commitmentId: 0,
  });
  const [files, setFiles] = useState<{
    attachments: {
      file: File;
      uuid: string;
      size?: string;
    }[];
    adImage?: {
      file: File;
      uuid: string;
      size?: string;
    };
  }>({
    attachments: [],
    adImage: undefined,
  });

  const [editorModalProps, setEditorModalProps] = useState<{
    id: number | string;
    isOpen: boolean;
    target?: "rule" | "commitment";
  }>({
    id: 0,
    isOpen: false,
    target: undefined,
  });

  // Effects
  useEffect(() => {
    const fetchFinancingTypes = async () => {
      const financingTypes = await LookupService.getAllFinancingTypes();
      setFundingTypeLookups(
        financingTypes.data.map((type) => ({
          value: type.financingTypesDetail.displayName,
          id: type.id,
          calculationWay: type.calculationWay?.calculationWayDetail?.displayName,
        })),
      );
    };

    fetchFinancingTypes();
  }, [isAr]);

  useEffect(() => {
    if (editorModalProps.id && editorModalProps.target && !editorModalProps.isOpen) {
      if (editorModalProps.target === "rule") {
        fetchConditionsAndRulesTemplate();
      } else if (editorModalProps.target === "commitment") {
        fetchCommitmentsTemplate();
      }
    }
  }, [editorModalProps.id, editorModalProps.target]);

  const fetchCommitmentsTemplate = async () => {
    setIsCommitmentTemplateLoading(true);
    const response = await ProgramsService.getPdfTemplateById(+editorModalProps.id);

    const arData = response.data.pdfTemplateDetails.find((x) => x.locale === "Ar");
    const enData = response.data.pdfTemplateDetails.find((x) => x.locale === "En");
    const content = {
      ar: arData?.templateContent ?? "",
      en: enData?.templateContent ?? "",
    };

    setValue("programDetails.0.commitmentName", arData?.displayName ?? "");
    setValue("programDetails.1.commitmentName", enData?.displayName ?? "");

    onEditorContentSave(content, "commitment");
    setEditorModalProps((prev) => ({
      ...prev,
      content,
    }));

    setIsCommitmentTemplateLoading(false);
  };

  const fetchConditionsAndRulesTemplate = async () => {
    setIsConditionAndRulesTemplateLoading(true);
    const response = await ProgramsService.getConditionAndRuleById(+editorModalProps.id);

    const arData = response.data.conditionAndRuleDetails.find((x) => x.locale === "Ar");
    const enData = response.data.conditionAndRuleDetails.find((x) => x.locale === "En");
    const content = {
      ar: arData?.templateContent ?? "",
      en: enData?.templateContent ?? "",
    };

    setValue("programDetails.0.ruleAndConditionName", arData?.displayName ?? "");
    setValue("programDetails.1.ruleAndConditionName", enData?.displayName ?? "");

    onEditorContentSave(content, "rule");
    setEditorModalProps((prev) => ({
      ...prev,
      content,
    }));

    setIsConditionAndRulesTemplateLoading(false);
  };

  useEffect(() => {
    if (programId) {
      fetchProgram(+programId);
    }
  }, [programId]);

  const fetchProgram = async (programId: number) => {
    setLoading(true);
    const response = await ProgramsService.getProgramById(programId);
    if (response.hasError) {
      showNotification({ description: response.description, type: "error" });
      navigate(-1);
    } else {
      // TODO: map the response to the form
      const mappedResponse = mapProgramByIdToCreateRequest(response.data);
      setProgramSerialNumber(response.data.serialNumber);
      setFiles({
        attachments:
          response.data.files?.map((file) => ({
            file: new File([], file.name),
            uuid: file.id,
            size: file.size,
          })) ?? [],
        adImage: {
          uuid: response.data.adsImageId,
          file: new File([], response.data.adsImageName),
          size: response.data.adsImageSize,
        },
      });
      reset(mappedResponse);
    }
    setLoading(false);
  };

  // Callbacks
  const onFinancingCalculationItemDelete = (index: number) => {
    const terms = getValues("financingCalculationItems").filter((_, i) => i !== index);
    setValue("financingCalculationItems", terms);
  };

  const onFinancingCalculationItemAdd = (item: CreateProgramRequest["financingCalculationItems"][0]) => {
    setValue("financingCalculationItems", [...getValues("financingCalculationItems"), item]);
  };

  const onConditionsAndRulesDropdownChange = (item: PaginatedDropdownItemType, target?: "rule" | "commitment") => {
    setEditorModalProps({ isOpen: false, id: item.value, target });
    setConditionAndRulesIds((prev) => ({
      ruleId: target === "rule" ? +item.value : prev.ruleId,
      commitmentId: target === "commitment" ? +item.value : prev.commitmentId,
    }));

    setError(`programDetails.0.${target === "rule" ? "ruleAndConditionName" : "commitmentName"}`, {
      type: "manual",
      message: "",
    });
  };

  const setEditorModalVisibleState = (isOpen: boolean, target?: "rule" | "commitment") => {
    setEditorModalProps((pre) => ({ ...pre, target: target ?? pre.target, isOpen }));
  };

  const onEditorContentSave = (content: { ar: string; en: string }, target: "rule" | "commitment") => {
    if (target === "rule") {
      setValue("programDetails.0.ruleAndConditionTemplate", content.ar);
      setValue("programDetails.1.ruleAndConditionTemplate", content.en);
    } else if (target === "commitment") {
      setValue("programDetails.0.commitmentTemplate", content.ar);
      setValue("programDetails.1.commitmentTemplate", content.en);
    }
  };

  const onSubmit = async (data: CreateProgramRequest) => {
    setSubmitting(true);
    try {
      const response = programId
        ? await ProgramsService.updateProgram(+programId, data)
        : await ProgramsService.createProgram(data);
      if (response.hasError) {
        showNotification({ description: response.description, type: "error" });
      } else {
        showNotification({ description: t("Common:success"), type: "success" });
        navigate(-1);
      }
    } catch {
      showNotification({ description: t("Common:internalServerError"), type: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const onFileUpload = async (files: FileList | null, target: "attachments" | "adImage") => {
    if (files?.[0]) {
      const fileResult = await ProgramsService.fileUpload(files[0]);

      if (target === "adImage") {
        setFiles((prev) => ({ ...prev, adImage: { file: files[0], uuid: fileResult.data.uuid } }));
        setValue("adsImageId", fileResult.data.uuid);
      } else {
        setFiles((prev) => ({
          ...prev,
          attachments: [...prev.attachments, { file: files[0], uuid: fileResult.data.uuid }],
        }));
        setValue("programAttachmentsIds", [...getValues("programAttachmentsIds"), fileResult.data.uuid]);
      }
    }
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allPrograms",
        path: "/home/Programs",
        menuItemId: "programs",
      },
      {
        localizationKey: programId ? "updateProgram" : "addProgram",
        path: `/home/programs/form${programId ? `?id=${programId}` : ""}`,
      },
    ]);
  }, []);

  // Form
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    reset,
    setError,
  } = useForm<CreateProgramRequest>({
    resolver: zodResolver(createProgramSchema),
    values: {
      status: "Draft",
      programTypeId: 0,
      programDetails: [
        {
          displayName: "",
          description: "",
          ruleAndConditionName: "",
          ruleAndConditionTemplate: "",
          commitmentName: "",
          commitmentTemplate: "",
          locale: "Ar",
        },
        {
          displayName: "",
          description: "",
          ruleAndConditionName: "",
          ruleAndConditionTemplate: "",
          commitmentName: "",
          commitmentTemplate: "",
          locale: "En",
        },
      ],
      workflowGid: "",
      classificationProgramId: 0,
      contractStartDate: now,
      fundingDurationInMonths: 0,
      adsStartDate: now,
      adsEndDate: now,
      fundingAmount: 0,
      audienceTypesIds: [],
      cycleYear: 0,
      programCycleId: 0,
      programAttachmentsIds: [],
      adsImageId: "",
      contractId: 0,
      financingCalculationItems: [],
      formBuilderSchema: "{}",
    },
    reValidateMode: "onBlur",
  });

  return (
    <form className="add-program-page-wrapper position-relative" noValidate onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay visible={loading} className="position-fixed" />
      {/* Page Header */}
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <div className="d-flex gap-2 align-items-center">
          <IconButton
            icon={isRTL ? "icon-full-arrow-right" : "icon-full-arrow-left"}
            innerIconColor="black"
            size="lg"
            className="text-dark"
            onClick={() => navigate(-1)}
          />
          <div className="d-flex gap-2 align-items-start">
            <div>
              <h4 className="text-dark">{programId ? t("updateProgram") : t("addProgram")}</h4>
              {programSerialNumber && <span>{programSerialNumber}</span>}
            </div>
            <Switch
              checked={watch("status") === "Completed"}
              onChange={(checked) => {
                setValue("status", checked ? "Completed" : "Draft");
              }}
            />
          </div>
        </div>
        <button className="btn btn-primary px-3" type="submit" disabled={submitting}>
          {submitting && <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true" />}
          {t("Common:save")}
        </button>
      </div>

      <div className="divider" />

      {/* Program Main Info */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("programMainInfo")} elementId={1}>
          <div className="d-flex gap-4 flex-wrap mb-3">
            <FormInput
              wrapperClassName="flex-1"
              label={t("programNameAr")}
              withAsterisk
              {...register("programDetails.0.displayName")}
              error={t(errors.programDetails?.[0]?.displayName?.message ?? "")}
            />
            <FormInput
              {...register("programDetails.0.description")}
              error={t(errors.programDetails?.[0]?.description?.message ?? "", { length: 3 })}
              wrapperClassName="flex-3"
              withAsterisk
              label={t("ProgramDescAr")}
            />
          </div>
          <div className="d-flex gap-4 flex-wrap">
            <FormInput
              wrapperClassName="flex-1"
              label={t("programNameEn")}
              withAsterisk
              {...register("programDetails.1.displayName")}
              error={t(errors.programDetails?.[1]?.displayName?.message ?? "")}
            />
            <FormInput
              wrapperClassName="flex-3"
              label={t("ProgramDescEn")}
              withAsterisk
              {...register("programDetails.1.description")}
              error={t(errors.programDetails?.[1]?.description?.message ?? "", { length: 3 })}
            />
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* About Program */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("aboutTheProgram")} elementId={2}>
          <div className="row">
            <LookupDropdown
              withAsterisk
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("programType")}
              service="lookupService"
              endpoint="getAllProgramTypes"
              onChange={(value) => {
                setValue("programTypeId", +value);
              }}
              value={watch("programTypeId")}
              changeEffect={() => {
                setValue("classificationProgramId", 0);
              }}
              textValueKey="programTypesDetail.displayName"
              idValueKey="id"
              error={t(errors.programTypeId?.message ?? "")}
              useReactSelect
            />
            <LookupDropdown
              withAsterisk
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("programClassification")}
              service="lookupService"
              endpoint="getAllProgramClassificationsByProgramTypeId"
              query={{ programTypeId: watch("programTypeId") }}
              onChange={(value) => {
                setValue("classificationProgramId", +value);
              }}
              value={watch("classificationProgramId")}
              textValueKey="programClassificationsDetail.displayName"
              idValueKey="id"
              disabled={!watch("programTypeId")}
              disableApi={!watch("programTypeId")}
              error={t(errors.classificationProgramId?.message ?? "")}
              useReactSelect
            />

            <DatePicker
              withAsterisk
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("contractStart")}
              onChange={(date) => {
                setValue("contractStartDate", date!);
                setError("contractStartDate", { type: "manual", message: "" });
              }}
              value={watch("contractStartDate")}
              error={t(errors.contractStartDate?.message ?? "")}
            />
            <FormInput
              withAsterisk
              wrapperClassName="col-md-3 col-sm-6 mb-2 hide-number-controls"
              label={t("fundingDuration")}
              maxLength={6}
              leftElement={<span className="text-primary">{t("months")}</span>}
              type="number"
              {...register("fundingDurationInMonths", {
                valueAsNumber: true,
              })}
              error={t(errors.fundingDurationInMonths?.message ?? "")}
            />
            <DatePicker
              withAsterisk
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("adsStart")}
              onChange={(date) => {
                setValue("adsStartDate", date!);
                setError("adsStartDate", { type: "manual", message: "" });
              }}
              value={watch("adsStartDate")}
              error={t(errors.adsStartDate?.message ?? "")}
            />
            <DatePicker
              withAsterisk
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("adsClose")}
              onChange={(date) => {
                setValue("adsEndDate", date!);
                setError("adsEndDate", { type: "manual", message: "" });
              }}
              value={watch("adsEndDate")}
              error={t(errors.adsEndDate?.message ?? "")}
            />
            <FormInput
              withAsterisk
              wrapperClassName="col-md-3 col-sm-6 mb-2 hide-number-controls"
              label={t("fundingAmount")}
              leftElement={<span className="text-primary">{t("Common:riyal")}</span>}
              type="number"
              {...register("fundingAmount", {
                valueAsNumber: true,
              })}
              error={t(errors.fundingAmount?.message ?? "")}
            />
            <LookupDropdown
              withAsterisk
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("targetedAudience")}
              service="lookupService"
              endpoint="getAllAudienceTypes"
              onChange={(value) => {
                setValue("audienceTypesIds", value.map(Number));
              }}
              value={watch("audienceTypesIds")}
              textValueKey="audienceTypeDetail.displayName"
              idValueKey="id"
              multiselect
              useReactSelect
              error={t(errors.audienceTypesIds?.message ?? "")}
            />
            <LookupDropdown
              withAsterisk
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("cycleYear")}
              service="lookupService"
              endpoint="getAllProgramCycleYears"
              changeEffect={() => {
                setValue("programCycleId", 0);
              }}
              noSelectionPlaceholder={t("Common:selectYear")}
              onChange={(value) => {
                setValue("cycleYear", +value);
              }}
              value={watch("cycleYear")}
              textValueKey="programCycleYearDetail.displayName"
              idValueKey="id"
              error={t(errors.cycleYear?.message ?? "")}
              useReactSelect
            />
            <LookupDropdown
              withAsterisk
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("cycle")}
              service="lookupService"
              endpoint="getAllProgramCycleByYear"
              query={{ year: watch("cycleYear") }}
              onChange={(value) => {
                setValue("programCycleId", +value);
              }}
              value={watch("programCycleId")}
              textValueKey="programCycleDetail.displayName"
              idValueKey="id"
              disabled={!watch("cycleYear")}
              disableApi={!watch("cycleYear")}
              error={t(errors.programCycleId?.message ?? "")}
              useReactSelect
            />
            <LookupDropdown
              withAsterisk
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("workFlow")}
              service="programService"
              endpoint="getAllWorkflowProcess"
              onChange={(value) => {
                setValue("workflowGid", value);
              }}
              value={watch("workflowGid")}
              textValueKey="workflowDesignProcessDetail.name"
              idValueKey="workflowGid"
              error={t(errors.workflowGid?.message ?? "")}
              useReactSelect
              isPaginated
            />
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* Program Conditions / Rules */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("rules")} elementId={3}>
          <PaginatedLookupDropdown
            withAsterisk
            wrapperClassName="col-md-3 col-sm-6 mb-2 w-100 mb-2"
            label={t("programRules")}
            service="programService"
            endpoint="getAllConditionsAndRules"
            queryKey="search"
            onChange={(value, item) => {
              onConditionsAndRulesDropdownChange(item, "rule");
            }}
            value={conditionAndRulesIds.ruleId}
            textValueKey="conditionAndRuleDetail.displayName"
            idValueKey="id"
            isPaginated
            error={t(errors.programDetails?.[0]?.ruleAndConditionName?.message ?? "")}
          />

          <FilePreview
            onShow={() => setEditorModalVisibleState(true, "rule")}
            title={watch("programDetails.0.ruleAndConditionName")}
            showButtonLabel={t("openAndEdit")}
            callbackValue="preview"
            loading={isConditionAndRulesTemplateLoading}
            disabled={!watch("programDetails.0.ruleAndConditionTemplate")}
          />

          <PaginatedLookupDropdown
            withAsterisk
            wrapperClassName="col-md-3 col-sm-6 mb-2 w-100 mb-2 mt-4"
            label={t("commitment")}
            service="programService"
            endpoint="getAllPdfTemplates"
            queryKey="search"
            onChange={(value, item) => {
              onConditionsAndRulesDropdownChange(item, "commitment");
            }}
            value={conditionAndRulesIds.commitmentId}
            textValueKey="pdfTemplateDetail.displayName"
            idValueKey="id"
            isPaginated
            error={t(errors.programDetails?.[0]?.commitmentName?.message ?? "")}
          />
          <FilePreview
            onShow={() => setEditorModalVisibleState(true, "commitment")}
            title={watch("programDetails.0.commitmentName")}
            showButtonLabel={t("openAndEdit")}
            callbackValue="preview"
            loading={isCommitmentTemplateLoading}
            disabled={!watch("programDetails.0.commitmentTemplate")}
          />
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* Other */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("others")} elementId={4}>
          <FileInput
            label={t("Common:attachments")}
            onChange={(files) => onFileUpload(files, "attachments")}
            clickHereForLabel={t("Common:toAttachFile")}
          />
          <div className="d-flex gap-2 flex-wrap">
            {files.attachments.map((file) => (
              <FilePreview
                key={file.uuid}
                onDelete={() => {
                  setFiles((prev) => ({
                    ...prev,
                    attachments: prev.attachments.filter((f) => f.uuid !== file.uuid),
                  }));
                  setValue(
                    "programAttachmentsIds",
                    getValues("programAttachmentsIds").filter((id) => id !== file.uuid),
                  );
                }}
                onShow={() => {}}
                file={file.file}
                fileSize={file.size}
              />
            ))}
          </div>

          <div className="divider" />

          <FileInput
            label={t("adImage")}
            onChange={(files) => onFileUpload(files, "adImage")}
            clickHereForLabel={t("toAttachAdImage")}
          />
          {files.adImage && (
            <div className="d-flex ">
              <FilePreview
                onDelete={() => {
                  setFiles((prev) => ({ ...prev, adImage: undefined }));
                  setValue("adsImageId", "");
                }}
                onShow={() => {}}
                file={files.adImage.file}
                fileSize={files.adImage.size}
              />
            </div>
          )}
          {errors.adsImageId?.message && <span className="text-danger">{t(errors.adsImageId.message)}</span>}

          <div className="divider" />

          <PaginatedLookupDropdown
            withAsterisk
            wrapperClassName="col-md-3 col-sm-6 w-100"
            label={t("contractForm")}
            service="programService"
            endpoint="getAllPdfTemplates"
            queryKey="search"
            value={watch("contractId")}
            onChange={(value: string) => {
              setValue("contractId", +value);
              setError("contractId", { type: "manual", message: "" });
            }}
            textValueKey="pdfTemplateDetail.displayName"
            idValueKey="id"
            isPaginated
            error={t(errors.contractId?.message ?? "")}
          />
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* Financing Calculation Terms */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("financingCalculationTerms")} elementId={5}>
          {watch("financingCalculationItems").map((item, index) => (
            <div key={item.financingTypeId + index} className="d-flex gap-4 align-items-end mb-3">
              <div className="row flex-grow-1">
                <Dropdown
                  withAsterisk
                  label={t("fundingType")}
                  wrapperClassName="col-lg-3 col-md-6 col-sm-12"
                  data={fundingTypeLookups}
                  onChange={(value, item) => {
                    setValue(`financingCalculationItems.${index}.financingTypeId`, +value);
                    setValue(`financingCalculationItems.${index}.calculationWay`, item?.calculationWay);
                    setValue(`financingCalculationItems.${index}.notes`, "");
                  }}
                  value={watch(`financingCalculationItems.${index}.financingTypeId`)}
                  error={t(errors.financingCalculationItems?.[index]?.financingTypeId?.message ?? "")}
                  useReactSelect
                />
                <FormInput
                  wrapperClassName="col-lg-3 col-md-6 col-sm-12"
                  label={t("calculationMethod")}
                  readOnly
                  placeholder={t("calculationMethod")}
                  value={watch(`financingCalculationItems.${index}.calculationWay`) ?? ""}
                  error={t(errors.financingCalculationItems?.[index]?.calculationWay?.message ?? "")}
                />
                <FormInput
                  wrapperClassName="col-lg-3 col-md-6 col-sm-12"
                  label={t("Common:notes")}
                  {...register(`financingCalculationItems.${index}.notes`)}
                  error={t(errors.financingCalculationItems?.[index]?.notes?.message ?? "")}
                />
                <RangeInput
                  wrapperClassName="col-lg-3 col-md-6 col-sm-12"
                  label={t("fundingAmountFromTo")}
                  leftElement={<span className="text-primary">{t("Common:riyal")}</span>}
                  placeholderStart={t("Common:from")}
                  placeholderEnd={t("Common:to")}
                  value={[
                    watch(`financingCalculationItems.${index}.fromValue`)?.toString() || "",
                    watch(`financingCalculationItems.${index}.toValue`)?.toString() || "",
                  ]}
                  onChange={(value) => {
                    setValue(`financingCalculationItems.${index}.fromValue`, +value[0]);
                    setValue(`financingCalculationItems.${index}.toValue`, +value[1]);
                  }}
                  error={t(
                    errors.financingCalculationItems?.[index]?.fromValue?.message ??
                      errors.financingCalculationItems?.[index]?.toValue?.message ??
                      "",
                  )}
                />
              </div>
              <IconButton
                size="xl"
                variant="danger"
                icon="icon-delete"
                onClick={() => onFinancingCalculationItemDelete(index)}
              />
            </div>
          ))}

          <FinancingCalculationItemTemplate onAdd={onFinancingCalculationItemAdd} data={fundingTypeLookups} />
          {watch("financingCalculationItems").length === 0 && (
            <span className="text-danger">{t("oneFinancialCalculation")}</span>
          )}
        </AccordionItem>
      </Accordion>

      {/* Description */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("Common:description")} elementId={6}>
          <FormBuilderComponent
            formSchema={getValues().formBuilderSchema ? JSON.parse(getValues().formBuilderSchema) : {}}
            setFormSchema={(data) => {
              setValue("formBuilderSchema", JSON.stringify(data));
            }}
          />
          {/*Form-io builder*/}
        </AccordionItem>
      </Accordion>

      {/* MODALS */}
      {editorModalProps.isOpen && !!editorModalProps.target && (
        <ProgramTemplateEditorModal
          isOpen
          fieldName={editorModalProps.target === "rule" ? "ruleAndConditionTemplate" : "commitmentTemplate"}
          content={getValues().programDetails}
          onClose={() => {
            setEditorModalVisibleState(false);
          }}
          onSave={(content) => onEditorContentSave(content, editorModalProps.target!)}
        />
      )}
    </form>
  );
};

export default ProgramFormView;
