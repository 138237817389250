import FormInput from "components/blocks/FormInput";
import { useTranslation } from "react-i18next";
import { CreateProfileSectionProps } from "./PersonalInformation";
import { Controller } from "react-hook-form";
import LookupDropdown from "components/blocks/LookupDropdown";
import { useEffect, useState } from "react";
import IconButton from "components/blocks/IconButton";
import CreatableSelect from "react-select/creatable";

const ProfessionalInformation = ({
  errors,
  register,
  setValue,
  control,
  userResearchInterests: initialUserResearchInterests,
}: CreateProfileSectionProps & {
  userResearchInterests: {
    researchInterest: string;
  }[];
}) => {
  const { t, i18n } = useTranslation("UserProfile");
  const [userResearchInterests, setUserResearchInterests] = useState<string[]>([]);

  useEffect(() => {
    const interests = initialUserResearchInterests.map((interest) => interest.researchInterest);
    setUserResearchInterests(interests);
  }, [initialUserResearchInterests]);

  return (
    <div className="d-flex flex-wrap flex-column gap-2">
      <div className="row g-2">
        <FormInput
          disabled
          label={t("userType")}
          wrapperClassName="col-lg-3 col-md-6 col-sm-12"
          {...register("type")}
        />
        <Controller
          name="jobId"
          control={control}
          render={({ field }) => (
            <LookupDropdown
              label={t("job")}
              useReactSelect
              disabled
              wrapperClassName="col-lg-3 col-md-6 col-sm-12"
              service="lookupService"
              endpoint="getJobs"
              idValueKey="id"
              textValueKey="jobDetail.displayName"
              value={field.value}
              onChange={(v) => {
                setValue("jobId", v ? +v : 0);
              }}
              error={errors.jobId?.message}
            />
          )}
        />
        <FormInput
          label={t("university")}
          wrapperClassName="col-lg-3 col-md-6 col-sm-12"
          {...register("university")}
          error={errors.university?.message}
        />
        <Controller
          name="degreeId"
          control={control}
          render={({ field }) => (
            <LookupDropdown
              label={t("degree")}
              useReactSelect
              disabled
              wrapperClassName="col-lg-3 col-md-6 col-sm-12"
              service="lookupService"
              endpoint="getDegrees"
              idValueKey="id"
              noSelectionPlaceholder={t("degree")}
              textValueKey="degreeDetail.displayName"
              value={field.value}
              onChange={(v) => {
                setValue("degreeId", v ? +v : 0);
              }}
              error={errors.jobId?.message}
            />
          )}
        />
      </div>
      <div className="row g-2">
        <Controller
          name="collegeId"
          control={control}
          render={({ field: collegeId }) => (
            <LookupDropdown
              label={t("college")}
              wrapperClassName="col-lg-3 col-md-6 col-sm-12"
              useReactSelect
              service="lookupService"
              endpoint="getAllCollegesDropDown"
              idValueKey="id"
              textValueKey="collegeDetail.displayName"
              value={collegeId.value || ""}
              noSelectionPlaceholder={t("college") as string}
              onChange={(v, item) => {
                setValue("collegeId", v ? +v : 0);
              }}
              error={errors.collegeId?.message}
            />
          )}
        />
        <FormInput
          label={t("department")}
          wrapperClassName="col-lg-3 col-md-6 col-sm-12"
          {...register("department")}
          error={errors.department?.message}
        />
        <Controller
          name="specializationId"
          control={control}
          render={({ field: specialization }) => (
            <>
              <LookupDropdown
                label={t("generalSpecialization")}
                wrapperClassName="col-lg-3 col-md-6 col-sm-12"
                useReactSelect
                service="lookupService"
                endpoint="getAllGeneralSpecialization"
                idValueKey="id"
                textValueKey="generalSpecializationDetail.displayName"
                value={specialization.value || ""}
                noSelectionPlaceholder={t("generalSpecialization") as string}
                onChange={(v, item) => {
                  setValue("specializationId", v ? +v : 0);
                  setValue("specialization", item?.value || "");
                }}
                error={errors.specialization?.message}
              />
              <Controller
                name="subspecialtyId"
                control={control}
                render={({ field }) => (
                  <LookupDropdown
                    label={t("specificSpecialization")}
                    wrapperClassName="col-lg-3 col-md-6 col-sm-12"
                    useReactSelect
                    service="lookupService"
                    endpoint="getAllSubspecialtyByGeneralSpecializationId"
                    idValueKey="id"
                    disableApi={!specialization.value}
                    query={{ id: specialization.value }}
                    textValueKey="subspecialtyByGeneralSpecializationDetail.displayName"
                    value={field.value || ""}
                    noSelectionPlaceholder={t("specificSpecialization") as string}
                    onChange={(v) => {
                      setValue("subspecialtyId", v ? +v : 0);
                    }}
                    error={errors.subspecialtyId?.message}
                  />
                )}
              />
            </>
          )}
        />
      </div>
      <div className="d-flex flex-column flex-wrap gap-2">
        <div>
          <label className={`mb-1`}>{t("researchInterests")}</label>
          <CreatableSelect
            value={undefined}
            styles={{
              // make it exactly like bootstrap 5
              control: (styles) => ({
                ...styles,
                borderColor: "#e5e5e5",
                padding: "0.3rem 0.75rem",
              }),
              valueContainer: (styles) => ({ ...styles, padding: "0" }),
              input: (styles) => ({ ...styles, margin: "0" }),
              indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
              dropdownIndicator: (styles) => ({ ...styles, padding: "0.3rem 0.0rem" }),
              singleValue: (styles) => ({ ...styles, padding: "0.3rem 0.1rem", fontSize: "1rem" }),
              placeholder: (styles) => ({ ...styles, padding: "0.3rem 0.75rem" }),
            }}
            onCreateOption={(value) => {
              const existingInterest = userResearchInterests.find((interest) => interest === value);
              if (existingInterest) return;

              const newInterest = [...userResearchInterests, value];
              setUserResearchInterests(newInterest);
              setValue("userResearchInterests", newInterest);
            }}
            placeholder={t("researchInterests")}
          />
        </div>
        <div className="d-flex flex-wrap gap-2">
          {userResearchInterests.map((interest) => (
            <div
              key={interest}
              className="d-flex justify-content-between 
           align-items-center badge bg-light text-dark p-2 rounded-3"
            >
              <span className="fs-14px">{interest}</span>
              <IconButton
                icon="icon-close"
                innerIconColor="dark"
                size="sm"
                className={i18n.language === "ar" ? "me-3" : "ms-3"}
                fitToIconWidth
                onClick={() => {
                  const newInterests = userResearchInterests.filter((ele) => ele !== interest);
                  setUserResearchInterests(newInterests);
                  setValue("userResearchInterests", newInterests);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="row g-2">
        <FormInput
          label={t("employer")}
          wrapperClassName="col-lg-3 col-md-6 col-sm-12"
          {...register("employer")}
          error={errors.employer?.message}
        />
        <FormInput
          label="ORCID"
          wrapperClassName="col-lg-3 col-md-6 col-sm-12"
          {...register("orcid")}
          error={errors.orcid?.message}
        />
        <FormInput
          label="Google Scholar ID"
          wrapperClassName="col-lg-3 col-md-6 col-sm-12"
          {...register("googleScholarId")}
          error={errors.googleScholarId?.message}
        />
        {/*<Controller*/}
        {/*  name="lastUpgradeDate"*/}
        {/*  control={control}*/}
        {/*  render={({ field }) => (*/}
        {/*    <DatePicker*/}
        {/*      label={t("lastUpgradeDate")}*/}
        {/*      wrapperClassName="col-lg-3 col-md-6 col-sm-12"*/}
        {/*      value={field.value}*/}
        {/*      onChange={(v) => {*/}
        {/*        if (v) setValue("lastUpgradeDate", v.toISOString());*/}
        {/*      }}*/}
        {/*      error={errors.lastUpgradeDate?.message}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default ProfessionalInformation;
