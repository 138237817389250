import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import Dropdown from "components/blocks/Dropdown";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FormInput from "components/blocks/FormInput";
import { Ref, RefObject, forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SpeechesTable from "./SpeechesTable";
import { useForm } from "react-hook-form";
import { getSpeechesSchema } from "./speeches.schema";
import { CreateProgramSpeechesRequest, PdfTemplateLocalResult } from "services/orders/models/OrdersTypes";
import PaginatedLookupDropdown, { DropdownItemType } from "components/blocks/PaginatedLookupDropdown";
import useMutation from "hooks/useMutation";
import { OrdersService } from "services/orders";
import Modal from "components/blocks/Modal";
import RichTextEditor from "components/RichTextEditor";
import LoadingOverlay from "components/blocks/LoadingOverlay";

const SpeechesTab = forwardRef(
  (
    {
      programOrderId,
    }: {
      programOrderId: number;
    },
    ref: Ref<HTMLFormElement>,
  ) => {
    const { t } = useTranslation(["Orders", "Common"]);

    const [isTemplateModalOpened, setIsTemplateModalOpened] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<{
      isExported: boolean;
      template?: PdfTemplateLocalResult;
    }>();
    const {
      formState: { errors },
      handleSubmit,
      register,
      setValue,
      reset,
    } = useForm<CreateProgramSpeechesRequest>({
      values: {
        programOrderId: programOrderId,
        pdfTemplateId: 0,
        description: "",
        selectedLocale: "En",
      },
      resolver: getSpeechesSchema(t),
    });

    const { data, mutateAsync, loading } = useMutation({
      queryFn: async (values: CreateProgramSpeechesRequest) => {
        return await OrdersService.createProgramSpeeches(values);
      },
    });

    const onSelectTemplate = (value: string, _: DropdownItemType, options: PdfTemplateLocalResult[]) => {
      const template = options.find((o) => o.id === +value);

      setValue("pdfTemplateId", +value);
      setSelectedTemplate({
        isExported: false,
        template: template,
      });
    };

    const onSubmit = async (values: CreateProgramSpeechesRequest) => {
      await mutateAsync(values);
      setSelectedTemplate(undefined);
      reset();
      (ref as RefObject<HTMLFormElement>).current?.classList.remove("was-validated");
    };

    const onExportResult = () => {
      setSelectedTemplate({
        ...selectedTemplate,
        isExported: true,
      });
    };

    return (
      <form ref={ref} className="position-relative" noValidate onSubmit={handleSubmit(onSubmit)}>
        <LoadingOverlay visible={loading} />
        <Accordion>
          <AccordionItem initiallyOpen title={t("newSpeech")} elementId={"new-speech"}>
            <div className="d-flex flex-column gap-2 pb-4 border-bottom border-1">
              <div className="d-flex flex-wrap gap-2 align-items-start">
                <PaginatedLookupDropdown
                  label={t("speechesTemplates")}
                  wrapperClassName="flex-1"
                  service="orderService"
                  endpoint="getAllPdfTemplates"
                  queryKey="search"
                  isPaginated
                  noSelectionPlaceholder={t("speechesTemplates")}
                  idValueKey="id"
                  textValueKey="pdfTemplateDetail.displayName"
                  {...register("pdfTemplateId")}
                  value={selectedTemplate?.template?.id ?? ""}
                  onChange={onSelectTemplate}
                  error={errors.pdfTemplateId?.message}
                />
                <FormInput
                  label={t("description")}
                  wrapperClassName="flex-2"
                  {...register("description")}
                  error={errors.description?.message}
                />
                <Dropdown
                  data={[
                    { id: "En", value: "En" },
                    { id: "Ar", value: "Ar" },
                  ]}
                  label={t("templateLanguage")}
                  wrapperClassName="flex-1"
                  {...register("selectedLocale")}
                  onChange={(value) => {
                    setValue("selectedLocale", value as any);
                  }}
                />
                <button
                  type="button"
                  className={`btn btn-primary rounded p-10px align-self-${
                    Object.keys(errors).length ? "center" : "end"
                  }`}
                  onClick={onExportResult}
                  disabled={!selectedTemplate}
                >
                  {t("release")}
                </button>
              </div>
              <div className="d-flex ">
                {selectedTemplate?.isExported && (
                  <FilePreview
                    callbackValue={selectedTemplate?.template?.id}
                    title={selectedTemplate?.template?.pdfTemplateDetail.displayName}
                    showButtonLabel={t("Common:show")}
                    onDelete={() => {
                      setSelectedTemplate(undefined);
                    }}
                    onShow={() => {
                      setIsTemplateModalOpened(true);
                    }}
                  />
                )}
              </div>
            </div>
          </AccordionItem>
        </Accordion>

        <Accordion>
          <AccordionItem initiallyOpen title={t("speeches")} elementId={"previous-speeches"}>
            <SpeechesTable programOrderId={programOrderId} createdSpeech={data} />
          </AccordionItem>
        </Accordion>

        <Modal
          isOpen={isTemplateModalOpened}
          onClose={() => setIsTemplateModalOpened(false)}
          title={selectedTemplate?.template?.pdfTemplateDetail.displayName}
          containerClassName="bg-white"
          size="lg"
        >
          <RichTextEditor
            isReadOnly
            initialData={selectedTemplate?.template?.pdfTemplateDetail.templateContent ?? ""}
          />
        </Modal>
      </form>
    );
  },
);

export default SpeechesTab;
