import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FormInput from "components/blocks/FormInput";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useCookieContext } from "contexts";
import { getCountryCodeByPhone } from "helpers/country-codes-helper";
import { useQuery } from "hooks/useQuery";
import { useTranslation } from "react-i18next";
import { AccountService } from "services/accountService";

const ApplicantSection = () => {
  const { t, i18n } = useTranslation(["Orders"]);
  const { isAr } = useCookieContext();

  const { data: userInfo } = useQuery({
    queryFn: async () => {
      return await AccountService.getUserInfoAsync();
    },
    triggers: [isAr],
  });

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("applicantMainInfo")} elementId="ApplicantSection">
        <div className="row g-2 position-relative pb-4 border-bottom border-1">
          <div className="col-lg-3 col-md-6">
            <FormInput
              label={t("fullName")}
              placeholder={t("fullName")}
              disabled
              labelClassName="fw-bold"
              value={i18n.language === "ar" ? userInfo?.arabicName : userInfo?.englishName}
            />
          </div>
          <div className="col-lg-3 col-md-6">
            <FormInput
              label={t("academicDegree")}
              placeholder={t("academicDegree")}
              disabled
              labelClassName="fw-bold"
              value={userInfo?.degree}
            />
          </div>
          <div className="col-lg-3 col-md-6">
            <FormInput
              label={t("collage")}
              placeholder={t("collage")}
              disabled
              value={userInfo?.collegeNavigation?.collegeDetail.displayName ?? userInfo?.college}
              labelClassName="fw-bold"
            />
          </div>
          <div className="col-lg-3 col-md-6">
            <FormInput
              label={t("specialization")}
              placeholder={t("specialization")}
              disabled
              labelClassName="fw-bold"
              value={userInfo?.specialization}
            />
          </div>
          <div className="col-lg-3 col-md-6">
            <FormInput
              label={t("email")}
              placeholder={t("email")}
              disabled
              value={userInfo?.email}
              labelClassName="fw-bold"
            />
          </div>
          <div className="row col-lg-3 col-md-6 g-1">
            <FormInput
              label={t("phone")}
              placeholder={t("phone")}
              disabled
              value={userInfo?.phoneNumber}
              labelClassName="fw-bold"
              wrapperClassName="col-8 align-self-end"
            />
            <FormInput
              disabled
              value={getCountryCodeByPhone(userInfo?.phoneNumber)}
              wrapperClassName="col-4 align-self-end phone-number"
            />
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default ApplicantSection;
