import React from "react";
import { FormCheck } from "react-bootstrap";

type FormCheckBoxProps = {
  label?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  containerClassName?: string;
};

const FormCheckBox = ({ checked, label, onChange, containerClassName, error }: FormCheckBoxProps) => {
  return (
    <div className={containerClassName}>
      <FormCheck
        type="checkbox"
        style={{
          scale: 3.5,
          width: "2.5rem",
        }}
        checked={checked}
        label={label}
        onChange={onChange}
        isInvalid={!!error}
        feedback={error}
      />
    </div>
  );
};

export default FormCheckBox;
