import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import { useTranslation } from "react-i18next";
import { ExaminerOrderEvaluationFormResult } from "services/examiners/Model/ExaminerTypes";
import FormViewComponent from "components/formBuilder/FormViewComponent";
import ExaminerEvaluationForm, { ExaminerEvaluationFormRef } from "./ExaminerEvaluationForm";
import { forwardRef } from "react";
import { OpenFundingTaskResult } from "services/tasks/models/tasksTypes";

const ExaminerTaskContent = forwardRef<
  ExaminerEvaluationFormRef,
  {
    task: ExaminerOrderEvaluationFormResult | null;
    serialGuid: string;
    openedTask: OpenFundingTaskResult | null;
  }
>(({ task, serialGuid, openedTask }, ref) => {
  const { t } = useTranslation(["ExaminerTask", "Common"]);

  return (
    <div className="d-flex flex-column">
      <div>
        <h6 className="fw-bold"> {t("suggestedTitle")}</h6>
        <p className="my-0">{task?.formSubject ?? t("Common:noData")}</p>
      </div>

      <div>
        <h6 className="fw-bold mt-4">{t("fullDescription")} </h6>
        <FormViewComponent
          readOnly
          data={task?.formValue ? JSON.parse(task.formValue) : {}}
          formDefinition={task?.formBuilderSchema ? JSON.parse(task?.formBuilderSchema) : {}}
          onChange={() => {}}
        />
      </div>

      <div className="divider mt-4" />

      <Accordion>
        <AccordionItem title={t("researchCentre")} initiallyOpen elementId="research-center">
          <div className="d-flex gap-3">
            {task?.programOrderEvaluationFiles.map((attachment, index) => (
              <AttachmentCard
                key={attachment.id}
                attachment={{
                  id: attachment.id,
                  extension: attachment.extension ?? "",
                  fileName: attachment.name ?? "",
                  size: attachment.size ?? "",
                }}
              />
            ))}
          </div>

          <div>
            <h6 className="fw-bold mt-4">{t("notes")}</h6>
            <p className="my-0">{task?.notes ?? t("Common:noData")}</p>
          </div>
        </AccordionItem>
      </Accordion>
      <div className="divider" />
      <ExaminerEvaluationForm
        ref={ref}
        evaluationForm={task?.evaluationForm}
        serialGuid={serialGuid}
        examinerEvaluationFormValue={task?.examinerEvaluationFormValue}
        openedTask={openedTask}
      />
    </div>
  );
});

export default ExaminerTaskContent;
