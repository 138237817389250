import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FormInput from "components/blocks/FormInput";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import LookupDropdown from "components/blocks/LookupDropdown";
import { useCookieContext } from "contexts";
import { useQuery } from "hooks/useQuery";
import { useTranslation } from "react-i18next";
import { AccountService } from "services/accountService";
import { CloseOrderFormProps } from "../../../ResearcherCloseRequestPage";
import FormTextArea from "components/blocks/FormTextArea";

const BankingInformation = ({ closeOrderFormProps }: { closeOrderFormProps?: CloseOrderFormProps }) => {
  const { t } = useTranslation("UserProfile");
  const { userSession } = useCookieContext();

  const { data, loading } = useQuery({
    queryFn: async () => {
      return await AccountService.getUserByIdAsync(userSession!.id);
    },
    options: {
      enabled: !!userSession,
    },
    triggers: [userSession],
  });

  return (
    <Accordion>
      <AccordionItem initiallyOpen elementId="BankingInfo" title={t("Orders:bankInformation")}>
        <div className="d-flex flex-wrap flex-column gap-2 position-relative">
          <LoadingOverlay visible={loading} />
          <div className="d-flex flex-wrap gap-2">
            <FormInput
              label={t("beneficiaryName")}
              wrapperClassName="flex-1"
              disabled
              style={{ minWidth: "150px" }}
              value={data?.beneficiaryName}
            />
            <FormInput
              label={t("bankName")}
              wrapperClassName="flex-1"
              disabled
              value={data?.bankName}
              style={{ minWidth: "150px" }}
            />
          </div>
          <div className="d-flex flex-wrap gap-2">
            <FormInput
              label={t("bankAccountIBAN")}
              wrapperClassName="flex-1"
              disabled
              value={data?.ibanNumber}
              style={{ minWidth: "150px" }}
            />
            <LookupDropdown
              label={t("currency")}
              wrapperClassName="flex-1"
              service="lookupService"
              endpoint="getAllCurrencies"
              idValueKey="id"
              textValueKey="currencyDetail.displayName"
              noSelectionPlaceholder={t("currency")}
              disabled
              value={data?.currencyId}
              useReactSelect
            />
          </div>

          <FormTextArea
            label={t("Common:notes")}
            placeholder={t("Common:notes")}
            value={closeOrderFormProps?.watch("notes")}
            onChange={(e) => {
              closeOrderFormProps?.setValue("notes", e.target.value);
              closeOrderFormProps?.setValue("workFlowTaskRequest.notes", e.target.value);
            }}
            rows={5}
            withAsterisk
          />
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default BankingInformation;
