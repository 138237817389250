import Dropdown, { DropdownItemType, DropdownProps } from "./Dropdown";
import { useEffect, useRef, useState } from "react";
import { BaseResponseType } from "services/shared-models/sharedTypes";
import { BackendServices } from "services";
import MultiSelectDropdown, { MultiDropdownProps } from "./MultiSelectDropdown";
import { useCookieContext } from "contexts";

export type LookupDropdownProps<T extends keyof typeof BackendServices> = {
  service: T;
  endpoint: keyof (typeof BackendServices)[T];
  onError?: (error: any) => void;
  textValueKey: string;
  idValueKey: string;
  query?: Record<string, any>;
  isPaginated?: boolean;
  disabled?: boolean;
  formProps?: any & { error?: string };
  changeEffect?: (value?: any) => void;
  multiselect?: boolean;
  error?: string;
  enableSearch?: boolean;
  labelClassName?: string;
  withOutMinWidth?: boolean;
  disableApi?: boolean;
  readOnly?: boolean;
  withAsterisk?: boolean;
  wrapperStyle?: React.CSSProperties;
  useReactSelect?: boolean;
  minWidth?: number;
  onFetchEnd?: (apiResponse: any) => void;
} & (
  | ({ multiselect?: false } & Omit<DropdownProps, "data">)
  | ({ multiselect: true } & Omit<MultiDropdownProps, "data">)
);

const access = (path: string, object: any) =>
  path.split(".").reduce((o, i) => {
    if (o && o[i]) {
      return o[i];
    }
    return "";
  }, object);

/**
 * @param {LookupDropdownProps} props
 * @param {boolean} props.useReactSelect - If true, the component will use react-select instead of the default select element and formProps is ignored
 * @returns {React.FC}
 */
function LookupDropdown<T extends keyof typeof BackendServices>(props: LookupDropdownProps<T>) {
  const {
    endpoint,
    query,
    error,
    textValueKey,
    idValueKey,
    onError,
    service,
    isPaginated,
    disabled,
    multiselect,
    disableApi = false,
    wrapperStyle,
    onFetchEnd,
    ...rest
  } = props;

  const dbRes = useRef<any[]>();
  const [data, setData] = useState<DropdownItemType[]>([]);
  const [queryParams, setQueryParams] = useState(isPaginated ? { ...query, pageIndex: 1, pageSize: 10 } : query);
  const { isAr } = useCookieContext();

  useEffect(() => {
    if (disableApi || !endpoint || !service) return;

    (BackendServices[service][endpoint] as any)(queryParams)
      .then((res: BaseResponseType<any>) => {
        if (!res.hasError) {
          const responseData = isPaginated ? res.data.result : res.data;
          dbRes.current = responseData;
          if (onFetchEnd) {
            onFetchEnd(responseData);
          }
          setData(
            responseData.map((item: any) => ({
              id: access(idValueKey, item),
              value: access(textValueKey, item),
            })),
          );
        } else {
          onError?.(res.description);
        }
      })
      .catch((error: any) => {
        onError?.(error);
      });
  }, [endpoint, onError, queryParams, isAr]);

  useEffect(() => {
    setQueryParams(isPaginated ? { ...query, pageIndex: 1, pageSize: 10 } : query);
  }, [query]);

  return multiselect ? (
    <>
      <MultiSelectDropdown
        dbRes={dbRes.current}
        data={data}
        error={error}
        disabled={disabled}
        {...(rest as Omit<MultiDropdownProps, "data">)}
      />
    </>
  ) : (
    <>
      <Dropdown
        dbRes={dbRes.current}
        data={data}
        error={error}
        disabled={disabled}
        {...(rest as Omit<DropdownProps, "data">)}
        setQueryParams={setQueryParams}
      />
    </>
  );
}

export default LookupDropdown;
