import useMutation from "hooks/useMutation";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OrdersService } from "services/orders";
import { ConfirmFundingUnitRequest, ReturnFundingUnitRequest } from "services/orders/models/OrdersTypes";

const useFundingUnitActions = ({ programOrderId, serialGuid ,  activityGuid }: { programOrderId: number; serialGuid: string,  activityGuid?: string; }) => {
  const { t } = useTranslation("Orders");
  const navigate = useNavigate();
  const [isReturnToCenterModalOpen, setIsReturnToCenterModalOpen] = useState(false);

  const { mutateAsync: confirmFundingUnit, loading: confirmFundingUnitLoading } = useMutation({
    queryFn: async ({ programOrderId, data }: { programOrderId: number; data: ConfirmFundingUnitRequest }) => {
      return await OrdersService.confirmFundingUnit(programOrderId, data);
    },
    notificationSuccessMessage: t("Common:success"),
  });

  const { mutateAsync: returnOrderToCentreManagement, loading: returnOrderToCentreManagementLoading } = useMutation({
    queryFn: async ({ programOrderId, data }: { programOrderId: number; data: ReturnFundingUnitRequest }) => {
      return await OrdersService.returnOrderToCentreManagement(programOrderId, data);
    },
  });

  const onConfirmFundingUnit = async () => {
    const res = await confirmFundingUnit({
      programOrderId,
      data: {
        actionName: "Finish",
        isAccept: true,
        serialGuid,
        activityGuid:activityGuid  ,
      },
    });

    if (res && !res.hasError) {
      navigate(`/home/tasks`);
    }
  };

  const onReturnOrderToCentreManagement = async (fundingUnitNotes: string) => {
    const res = await returnOrderToCentreManagement({
      programOrderId,
      data: {
        actionName: "Return",
        isAccept: false,
        serialGuid,
        fundingUnitNotes,
        activityGuid:activityGuid  ,
      },
    });

    if (res && !res.hasError) {
      setIsReturnToCenterModalOpen(false);
      navigate(`/home/tasks`);
    }
  };

  return {
    onConfirmFundingUnit,
    confirmFundingUnitLoading,
    onReturnOrderToCentreManagement,
    returnOrderToCentreManagementLoading,
    isReturnToCenterModalOpen,
    setIsReturnToCenterModalOpen,
  };
};

export default useFundingUnitActions;
