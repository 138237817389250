import useMutation from "hooks/useMutation";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloseOrderService } from "services/closeOrder";
import { WorkFlowTaskRequest } from "services/orders/models/OrdersTypes";

const usePayrollOfficerActions = ({
  programOrderCloseId,
  activityGuid ,
  serialGuid,
}: {
  programOrderCloseId: number;
  serialGuid: string;
  activityGuid? :string;
}) => {
  const [payrollOfficerModalProps, setPayrollOfficerModalProps] = useState({
    isOpen: false,
  });
  const navigate = useNavigate();

  const { mutateAsync, loading } = useMutation({
    queryFn: async ({ id, request, receiptId }: { id: number; request: WorkFlowTaskRequest; receiptId?: string }) => {
      request.activityGuid= activityGuid;
      const res = await CloseOrderService.finalApprovedOrderRequest(id, {
        receiptId: receiptId!,
        workFlowTaskRequest: request,
      });

      if (res && !res.hasError) {
        navigate(`/home/tasks`);
      }

      return res;
    },
  });

  const onComplete = async (note: string, receiptId: string) => {
    return await mutateAsync({
      id: programOrderCloseId,
      request: {
        actionName: "Complete",
        notes: note,
        serialGuid: serialGuid,
        activityGuid: activityGuid,
      },
      receiptId,
    });
  };

  return {
    payrollOfficerModalProps,
    onComplete,
    loading,
    setPayrollOfficerModalProps,
  };
};

export default usePayrollOfficerActions;
