import Dropdown from "components/blocks/Dropdown";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import useMutation from "hooks/useMutation";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/orders";
import {
  FinalCommitteeDecisionLookup,
  FinalCommitteeDecisionType,
  OrderLocalResult,
} from "services/orders/models/OrdersTypes";
import {Dispatch, SetStateAction, useEffect, useState} from "react";

const ResearcherTypeDropdown = ({ order, setPrimaryResearcherOrderIds }: { order: OrderLocalResult, setPrimaryResearcherOrderIds: Dispatch<SetStateAction<Set<number>>> }) => {
  const { t } = useTranslation("Programs");
  const [type, setType] = useState("");

  const { loading, mutateAsync } = useMutation({
    queryFn: async (type: FinalCommitteeDecisionType) => {
      return await OrdersService.changeFinalCommitteeDecisionProgramOrderStatus(order.id, type);
    },
  });

  const onChange = async (value: number) => {
    await mutateAsync(value);
   
    setType(`${value}`);
    if (value === FinalCommitteeDecisionLookup.Primary.id) {
        setPrimaryResearcherOrderIds((prev) => {
            const newSet = new Set(prev);
            newSet.add(order.id);
            return newSet;
        });
    } else {
      setPrimaryResearcherOrderIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(order.id);
        return newSet;
      });
    }
  };

    useEffect(() => {
        setType(order.finalCommitteeDecision === FinalCommitteeDecisionLookup.Secondary.label
            ? `${FinalCommitteeDecisionLookup.Secondary.id}`
            : order.finalCommitteeDecision === FinalCommitteeDecisionLookup.Primary.label
                ? `${FinalCommitteeDecisionLookup.Primary.id}`
                : "");
    }, [order.finalCommitteeDecision]);

  return (
    <div className="position-relative">
      <LoadingOverlay visible={loading} />
      <Dropdown
        data={[
          {
            id: FinalCommitteeDecisionLookup.Primary.id,
            value: t("primary"),
          },
          {
            id: FinalCommitteeDecisionLookup.Secondary.id,
            value: t("secondary"),
          },
        ]}
        onChange={(value) => onChange(+value)}
        noSelectionPlaceholder={t("pleaseSelectResearcherType")}
        value={type}
      />
    </div>
  );
};

export default ResearcherTypeDropdown;
