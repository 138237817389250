import { zodResolver } from "@hookform/resolvers/zod";
import { CreateOrderRequest } from "services/orders/models/OrdersTypes";
import { z } from "zod";

export const getCreateOrderSchema = (t: any) => {
  const schema = z.object({
    programId: z
      .number({
        message: t("Common:invalidValue"),
      })
      .min(1, t("Common:invalidValue")),
    centreManagementId: z.number().min(1, t("Common:invalidValue")),
    formSubject: z.string().optional(),
    formValue: z.string().optional(),
    isAcceptedConditionAndRules: z.boolean().refine((value) => value === true, {
      message: t("Common:requiredField"),
    }),
    isAcceptedCommitments: z.boolean().refine((value) => value === true, {
      message: t("Common:requiredField"),
    }),
    filesIds: z
      .array(
        z.string({
          message: t("Common:pleaseUploadAtLeastOneFile"),
        }),
      )
      .min(1, t("Common:pleaseUploadAtLeastOneFile")),
    researchFieldId: z.number().min(1, t("Common:invalidValue")),
    subspecialty: z.string().optional(),
    isFormValueReady: z
      .boolean({ message: t("pleaseFillAllRequiredFields") })
      .refine((value) => value === true, { message: t("pleaseFillAllRequiredFields") }),
    isDraft: z.boolean(),
  }) as z.ZodType<CreateOrderRequest>;

  return zodResolver(schema);
};
