import Modal, { ModalProps } from "components/blocks/Modal";
import Switch from "components/blocks/Switch";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import useMutation from "hooks/useMutation";
import { useQuery } from "hooks/useQuery";
import { useEffect, useMemo, useState } from "react";
import { FormCheck } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ExaminersService } from "services/examiners";
import { ExaminerListResult } from "services/examiners/Model/ExaminerTypes";
import { OrdersService } from "services/orders";
import { ReturnOrderRequest } from "services/orders/models/OrdersTypes";
import { BaseResponseType } from "services/shared-models/sharedTypes";
import { EXAMINER_TASK_STATUS } from "../Tabs/OrderInfoTab/components/ExaminersSection/ExaminersTable";

const ReturnOrderToReviewerModal: React.FC<
  ModalProps & {
    isInspectMode?: boolean;
    serialGuid?: string;
    orderId: number;
    activityGuid?: string;
  }
> = (props) => {
  const navigate = useNavigate();
  const { isOpen, onClose, isInspectMode, orderId, serialGuid,activityGuid } = props;
  const { t } = useTranslation("Orders");
  const [attachSwitchState, setAttachSwitchState] = useState<boolean>(false);

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm<ReturnOrderRequest>({
    defaultValues: {
      actionName: "Return",
      selectedProgramOrderExaminers: [],
      serialGuid: serialGuid,
      activityGuid:activityGuid,
      notes: "",
    },
  });

  const { data: selectedExaminers } = useQuery({
    queryFn: async () => {
      const data = await ExaminersService.getAllExaminersByOrderId(orderId);
      const sortedExaminers = data?.data.sort((a, b) => a.id - b.id);

      const uniqueExaminers = sortedExaminers?.reduce((acc, examiner) => {
        if (examiner.status === EXAMINER_TASK_STATUS.Submitted.value) {
          acc[examiner.examinerName] = examiner;
        }

        return acc;
      }, {} as { [key: string]: ExaminerListResult });

      return {
        data: uniqueExaminers ? Object.values(uniqueExaminers) : [],
        code: data?.code,
        hasError: data?.hasError,
        description: data?.description,
      };
    },
    triggers: [isOpen, orderId],
    options: {
      enabled: isOpen,
    },
  });

  const { mutateAsync, loading } = useMutation({
    queryFn: async ({ programOrderId, data }: { programOrderId: number; data: ReturnOrderRequest }) => {
      return await OrdersService.returnOrder(programOrderId, data);
    },
  });

  const gridSchema: GridSchema[] = useMemo(
    () => [
      {
        field: "examinerName",
        displayName: t("examinerName"),
        showOnMobile: true,
        render(row: ExaminerListResult) {
          return row.examinerName;
        },
      },
      {
        field: "",
        displayName: t("recommendations"),
        render(row: ExaminerListResult, index: number) {
          return (
            <FormCheck
              onChange={(e) => {
                setValue(`selectedProgramOrderExaminers.${index}.showRecommendation`, e.currentTarget.checked);
              }}
            />
          );
        },
      },
      {
        field: "",
        displayName: t("Common:attachments"),
        showOnMobile: true,
        render(row, index) {
          return (
            <FormCheck
              onChange={(e) => {
                setValue(`selectedProgramOrderExaminers.${index}.showFiles`, e.currentTarget.checked);
              }}
            />
          );
        },
      },
    ],
    [errors],
  );

  useEffect(() => {
    if (attachSwitchState) {
      selectedExaminers?.forEach((examiner, index) => {
        setValue(`selectedProgramOrderExaminers.${index}.showRecommendation`, false);
        setValue(`selectedProgramOrderExaminers.${index}.showFiles`, false);
        setValue(`selectedProgramOrderExaminers.${index}.programOrderExaminerId`, examiner.id);
      });
    } else {
      setValue("selectedProgramOrderExaminers", []);
    }
  }, [attachSwitchState]);

  const onSubmit = async (values: ReturnOrderRequest) => {
    values.serialGuid = serialGuid!;
    values.activityGuid =activityGuid;
    const res = (await mutateAsync({ programOrderId: orderId, data: values })) as BaseResponseType<any>;
    if (!res.hasError) {
      navigate("/home/tasks");
      onClose?.();
    }
  };

  const onCancel = () => {
    onClose?.();
  };

  return (
    <Modal
      containerClassName="bg-white pb-2"
      isOpen={isOpen}
      onClose={onClose}
      title={t("returnOrderToResearcher")}
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {!!isInspectMode && (
          <>
            <Switch
              checked={attachSwitchState}
              onChange={(value) => {
                setAttachSwitchState(value);
              }}
              label={t("attachReviewersRecommendationsAndFiles")}
            />

            {attachSwitchState && (
              <div className="mt-3">
                <Grid data={selectedExaminers ?? []} gridSchema={gridSchema} />
              </div>
            )}
          </>
        )}

        <label className="form-label mt-2">{t("Common:notes")}</label>
        <textarea
          className={`form-control`}
          rows={5}
          placeholder={t("Common:notes")}
          onChange={(e) => setValue("notes", e.target.value)}
          style={{ resize: "none" }}
        />

        <div className="divider"></div>

        <div className="d-flex justify-content-center gap-2 w-100">
          <button type="button" className="btn text-dark rounded-3 p-10-32P" onClick={onCancel}>
            {t("Common:cancel")}
          </button>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <button className="btn btn-primary rounded-3 p-10-32P" disabled={loading || !field.value?.length}>
                {!loading ? t("returnOrder") : <div className="custom-loader"></div>}
              </button>
            )}
          />
        </div>
      </form>
    </Modal>
  );
};

export default ReturnOrderToReviewerModal;
