import { HttpClient } from "helpers";
import {
  BaseResponseType,
  DataGridRequest,
  FileUploadResponse,
  PagedResponseType,
  PagedResult,
} from "services/shared-models/sharedTypes";
import {
  ConditionsAndRulesByIDResponse,
  ConditionsAndRulesResultResponse,
  PDFTemplateByIDResponse,
  PDFTemplatesResultResponse,
  ProgramLocalItemResult,
  ProgramByIDResponse,
  ProgramLocalResult,
  CreateProgramMinutes,
  ProgramActionResult,
  WorkflowDesignProcessLocalResult,
} from "./models/ProgramTypes";
import { CreateProgramRequest } from "views/home/programs/Forms/program.schema";
import { AxiosResponse } from "axios";

export const ProgramsService = {
  createProgram: async (data: CreateProgramRequest) => {
    return await HttpClient.post<BaseResponseType<any>>("/api/Programs/CreateProgram", data);
  },
  updateProgram: async (id: number, data: CreateProgramRequest) => {
    return await HttpClient.put<BaseResponseType<any>>(`/api/Programs/UpdateProgram/${id}`, data);
  },
  getAllProgramsAsync: async (data: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<ProgramLocalResult>>("/api/Programs/GetAllPrograms", data);
  },
  getProgramById: async (id: number) => {
    return await HttpClient.get<BaseResponseType<ProgramByIDResponse>>(`/api/Programs/GetProgramById/${id}`);
  },
  getAllConditionsAndRules: async (request: { search: string; pageIndex: number; pageSize: number }) => {
    return HttpClient.post<PagedResponseType<ConditionsAndRulesResultResponse[]>>(
      "/api/Programs/GetAllConditionsAndRules",
      request,
    );
  },
  getAllPdfTemplates: async (request: { search: string; pageIndex: number; pageSize: number }) => {
    return HttpClient.post<PagedResponseType<PDFTemplatesResultResponse>>("/api/Programs/GetAllPdfTemplates", request);
  },
  getConditionAndRuleById: async (id: number) => {
    return HttpClient.get<BaseResponseType<ConditionsAndRulesByIDResponse>>(
      `/api/Programs/GetConditionAndRuleById/${id}`,
    );
  },
  getPdfTemplateById: async (id: number) => {
    return HttpClient.get<BaseResponseType<PDFTemplateByIDResponse>>(`/api/Programs/GetPdfTemplateById/${id}`);
  },
  fileUpload: async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return HttpClient.post<BaseResponseType<FileUploadResponse>>("/api/Programs/FileUpload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getAllPublicPrograms: async (data: DataGridRequest) => {
    return HttpClient.post<PagedResponseType<ProgramLocalResult>>(`/api/Programs/GetAllPublicPrograms`, data);
  },
  getPublicProgramById: async (id: number) => {
    return HttpClient.get<BaseResponseType<ProgramLocalItemResult>>(`/api/Programs/GetPublicProgramById/${id}`);
  },
  deleteProgram: async (id: number) => {
    return HttpClient.delete<BaseResponseType<ProgramLocalItemResult>>(`/api/Programs/DeleteProgram/${id}`);
  },
  exportProgramsAsync: async (data: DataGridRequest) => {
    return HttpClient.post<AxiosResponse<Blob>>(`/api/Programs/ExportProgramsToExcel`, data, { responseType: "blob" });
  },
  sendFundingUnitRequest: async (programId: number) => {
    return HttpClient.put<BaseResponseType<ProgramLocalItemResult>>(
      `/api/Programs/SendFundingUnitRequest/${programId}`,
    );
  },
  updateProgramMinutes: async (programMinutesId: number, data: CreateProgramMinutes) => {
    return await HttpClient.put<BaseResponseType<ProgramLocalItemResult>>(
      `/api/Programs/UpdateProgramMinutes/${programMinutesId}`,
      data,
    );
  },
  getAllProgramAction: async (programId: number) => {
    return await HttpClient.get<BaseResponseType<PagedResult<ProgramActionResult>>>(
      `/api/Programs/GatAllProgramAction/${programId}`,
    );
  },
  getAllWorkflowProcess: async (request: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<PagedResult<PagedResult<WorkflowDesignProcessLocalResult>>>>(
      `/api/Programs/GetAllWorkflowProcess`,
      request,
    );
  },
};
