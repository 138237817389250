import { HttpClient } from "helpers";
import { WorkFlowTaskRequest } from "services/orders/models/OrdersTypes";
import { BaseResponseType } from "services/shared-models/sharedTypes";
import { CentreManagerAcceptRequest, CloseOrderRequest, CloseOrderResult, FinalApproveOrderRequest } from "./models";
import { AxiosResponse } from "axios";

export const CloseOrderService = {
  updateCloseProgram: async (id: number, request: CloseOrderRequest) => {
    return await HttpClient.put<BaseResponseType<CloseOrderResult>>(
      `/api/CloseOrder/UpdateCloseProgram/${id}`,
      request,
    );
  },
  generalApproveOrderCloseRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<CloseOrderResult>>(
      `/api/CloseOrder/GeneralApproveOrderCloseRequest/${id}`,
      request,
    );
  },
  generalRejectOrderCloseRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<CloseOrderResult>>(
      `/api/CloseOrder/GeneralRejectOrderCloseRequest/${id}`,
      request,
    );
  },
  generalReturnOrderCloseRequest: async (id: number, request: WorkFlowTaskRequest) => {
    return await HttpClient.put<BaseResponseType<CloseOrderResult>>(
      `/api/CloseOrder/GeneralReturnOrderCloseRequest/${id}`,
      request,
    );
  },
  finalApprovedOrderCloseRequest: async (id: number, request: FinalApproveOrderRequest) => {
    return await HttpClient.put<BaseResponseType<CloseOrderResult>>(
      `/api/CloseOrder/FinalApprovedOrderCloseRequest/${id}`,
      request,
    );
  },
  centreManagerAccept: async (id: number, request: CentreManagerAcceptRequest) => {
    return await HttpClient.put<BaseResponseType<CloseOrderResult>>(
      `/api/CloseOrder/CentreManagerAccept/${id}`,
      request,
    );
  },
  downloadProgressCertificate: async (id: number) => {
    return await HttpClient.get<AxiosResponse<Blob>>(`/api/CloseOrder/DownloadProgressCertificate/${id}`, {
      responseType: "blob",
    });
  },
  finalApprovedOrderRequest: async (id: number, request: FinalApproveOrderRequest) => {
    return await HttpClient.put<BaseResponseType<CloseOrderResult>>(
      `/api/CloseOrder/FinalApprovedOrderRequest/${id}`,
      request,
    );
  },
};
