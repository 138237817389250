import FileUploadWithPreview, { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import Modal, { ModalProps } from "components/blocks/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const PayrollOfficerModal: React.FC<
  ModalProps & {
    onSubmit: (notes: string, receiptId: string) => Promise<any>;
    isSubmitting: boolean;
  }
> = (props) => {
  const { isOpen, onClose, onSubmit, isSubmitting } = props;
  const { t } = useTranslation(["Orders"]);
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState<SelectedFilesType[]>([]);

  const onCancel = () => {
    onClose?.();
  };

  return (
    <Modal
      containerClassName="bg-white pb-2"
      isOpen={isOpen}
      onClose={onClose}
      title={t("attachTransferReceipt")}
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
      bodyMargin="py-3"
    >
      <FileUploadWithPreview
        files={files}
        setFiles={setFiles}
        maxNumberOfFiles={1}
        label={t("transferReceipt")}
        clickHereForLabel={t("toAttachReceipt")}
      />
      <label className="form-label mt-2">{t("Common:notes")}</label>
      <textarea
        className={`form-control`}
        rows={5}
        placeholder={t("Common:notes")}
        onChange={(e) => setNotes(e.target.value)}
        style={{ resize: "none" }}
      />

      <div className="divider mt-3"></div>

      <div className="d-flex justify-content-center gap-2 w-100">
        <button type="button" className="btn text-dark rounded-3 p-10-32P" onClick={onCancel}>
          {t("Common:cancel")}
        </button>
        <button
          type="button"
          className="btn btn-primary rounded-3 p-10-32P"
          disabled={!notes.length || isSubmitting || !files.length}
          onClick={async () => {
            if (!notes.length) return;

            await onSubmit(notes, files[0]?.uuid);
          }}
        >
          {!isSubmitting ? t("Common:save") : <div className="custom-loader"></div>}
        </button>
      </div>
    </Modal>
  );
};

export default PayrollOfficerModal;
