import { OrderUserTypes } from "../../..";
import { useTranslation } from "react-i18next";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { getCloseRequestActionsByUserType } from "../../../PageActions/useCloseRequestActions";

const UpdateNotes = ({
  order,
  userType,
}: {
  order: OrderLocalItemResult | null;
  userType: keyof typeof OrderUserTypes;
}) => {
  const { t } = useTranslation("Orders");

  return (
    <div className="d-flex flex-column gap-2 px-4 text-dark">
      {(userType === OrderUserTypes.ResearcherSendQueryResponse ||
        userType === OrderUserTypes.ResearchCenterManagerQueryRequest) &&
       ( order?.inQueryNotes || order?.centralManagementIQueryNotes) && (
          <>
            <h5 className="fw-bold ">{t("queryRequest")}</h5>
            <p>
              {userType !== OrderUserTypes.ResearchCenterManagerQueryRequest
                ? order?.centralManagementIQueryNotes
                : order?.inQueryNotes}
            </p>
          </>
        )}

      {(userType === OrderUserTypes.ResearchCenterManagerForResearcherUpdateRequest ||
        userType === OrderUserTypes.ResearcherSendUpdateResponseAfterReturnRequest) &&
        order?.updateRequestNotes && (
          <>
            <h5 className="fw-bold ">{t("updateRequest")}</h5>
            <p>{order?.updateRequestNotes}</p>
          </>
        )}
      {(userType === OrderUserTypes.ResearchCenterManagerForResearcherApologiesRequest ||
        userType === OrderUserTypes.ResearcherSendApologizeResponseAfterReturnRequest ||
        userType === OrderUserTypes.DeanOfScientificResearchForResearcherApologiesRequest) &&
        order?.apologyRequestNotes && (
          <>
            <h5 className="fw-bold ">{t("apologizeRequest")}</h5>
            <p>{order?.apologyRequestNotes}</p>
          </>
        )}
      {(userType === OrderUserTypes.ResearchCenterManagerForResearcherExtendRequest ||
        userType === OrderUserTypes.DeanOfScientificResearchForResearcherExtendRequest ||
        userType === OrderUserTypes.ResearcherSendExtendResponseAfterReturnRequest) &&
        order?.extendedRequestNotes && (
          <>
            <h5 className="fw-bold ">{`${t("extendRequest")} ${order.extendedPeriodInMonth} ${t("month")}`}</h5>
            <p>{order?.extendedRequestNotes}</p>
          </>
        )}

      {userType === OrderUserTypes.DeanOfScientificResearch && order?.researcherNotes && (
        <>
          <h5 className="fw-bold ">{t("researcherNotes")}</h5>
          <p>{order?.researcherNotes}</p>
        </>
      )}

      {!!getCloseRequestActionsByUserType(userType) && order?.closeOrder && (
        <div className="d-flex flex-column gap-2">
          {order?.closeOrder?.rejectNotes ??
            (order.closeOrder?.returnNotes && (
              <div>
                <h5 className="fw-bold ">{`${t("closeRequestNotes")}`}</h5>
                <p>{order?.closeOrder?.rejectNotes ?? order.closeOrder?.returnNotes}</p>
              </div>
            ))}

          {/*  {userType === OrderUserTypes.ResearchCenterManagerForCloseRequest && (
            <div>
              <h5 className="fw-bold ">{`${t("researcherResponse")}`}</h5>
              <p>{order?.closeOrder?.notes}</p>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default UpdateNotes;
