import RichTextEditor from "components/RichTextEditor";
import AttachmentCard from "components/blocks/AttachmentCard";
import Modal from "components/blocks/Modal";
import FormViewComponent from "components/formBuilder/FormViewComponent";
import { Dispatch, MutableRefObject, SetStateAction, useState } from "react";
import { FormCheck } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";

const ResearchersTab = ({
  orderData,
  program,
  setIsResearcherAcceptanceButtonDisabled,
  notesForAcceptsRef,
}: {
  orderData?: OrderLocalItemResult | null;
  program: ProgramByIDResponse;
  setIsResearcherAcceptanceButtonDisabled: Dispatch<SetStateAction<boolean>>;
  notesForAcceptsRef?: MutableRefObject<string>;
}) => {
  const { t, i18n } = useTranslation("Programs");
  const [isConditionAndRulesModalOpened, setSetIsConditionAndRulesModalOpened] = useState(false);
  const programDetails = program.programDetails.find((x) => x.locale.toLowerCase() === i18n.language.toLowerCase());

  return (
    <div className="d-flex flex-column gap-3">
      {orderData?.dsrNotes && (
        <div className="d-flex flex-column gap-2">
          <span className="fw-bold fs-6">{t("recommendations")}</span>
          <span className="fs-14px ">{orderData?.dsrNotes}</span>
        </div>
      )}

      <div className="d-flex flex-column gap-2">
        <span className="fw-bold fs-6">{t("suggestedTitle")}</span>
        <span className="fs-14px ">{orderData?.formSubject}</span>
      </div>

      <div className="mt-1">
        <h6 className="fw-bold">{t("fullDescription")}</h6>
        <FormViewComponent
          readOnly
          data={orderData?.formValue ? JSON.parse(orderData.formValue) : {}}
          formDefinition={program.formBuilderSchema ? JSON.parse(program.formBuilderSchema) : {}}
          onChange={() => {}}
        />
      </div>

      <div className="pb-4">
        <h6 className="fw-bold">{t("Common:attachments")}</h6>
        <div className="d-flex gap-3 mt-2 ">
          {orderData?.files?.length ? (
            orderData.files.map((attachment) => (
              <AttachmentCard
                key={attachment.id}
                attachment={{
                  id: attachment.id,
                  fileName: attachment.name ?? "",
                  size: attachment.size ?? "",
                  extension: attachment.extension ?? "",
                }}
              />
            ))
          ) : (
            <span>{t("Common:noData")}</span>
          )}
        </div>
      </div>
      <div className="d-flex gap-2">
        <FormCheck
          type="checkbox"
          onChange={(e) => {
            setIsResearcherAcceptanceButtonDisabled(!e.target.checked);
          }}
        />
        <div className="d-flex">
          <span>{t("acceptContractRulesPart1")}</span>
          <button
            className="text-primary btn btn-link p-0 m-0 px-1"
            onClick={() => {
              setSetIsConditionAndRulesModalOpened(true);
            }}
          >
            {t("contractRules")}
          </button>
          <span>{t("acceptContractRulesPart2")}</span>
        </div>
      </div>
      <div>
        <label className="form-label mt-2">{t("Common:notes")}</label>
        <textarea
          className={`form-control`}
          rows={5}
          placeholder={t("Common:notes")}
          style={{ resize: "none" }}
          onChange={(e) => {
            if (notesForAcceptsRef) notesForAcceptsRef.current = e.target.value;
          }}
        />
      </div>

      <Modal
        isOpen={isConditionAndRulesModalOpened}
        onClose={() => setSetIsConditionAndRulesModalOpened(false)}
        title={t("contractRules")}
        withHeaderBorder
        size="lg"
        titleClassName="fs-6 text-dark"
        bodyMargin="py-3"
        containerClassName="bg-white pb-2"
        footer={
          <div className="d-flex justify-content-end gap-3">
            <button
              type="button"
              className="btn btn-secondary p-10-32P rounded-3"
              onClick={() => setSetIsConditionAndRulesModalOpened(false)}
            >
              {t("Common:cancel")}
            </button>
          </div>
        }
      >
        <div className="row">
          <RichTextEditor initialData={programDetails?.ruleAndConditionTemplate ?? ""} isReadOnly />
        </div>
      </Modal>
    </div>
  );
};

export default ResearchersTab;
