import { BaseResponseType } from "services/shared-models/sharedTypes";
import { HttpClient } from "helpers";
import {
  CountryCodeResultResponse,
  CountryResultResponse,
  DegreeResultResponse,
  GenderResultResponse,
  JobResultResponse,
  AllProgramTypesResultResponse,
  ProgramClassificationResultResponse,
  ProgramCycleYearsResultResponse,
  AudienceTypesResultResponse,
  FinancingTypesResultResponse,
  SubspecialtyResult,
  ResearchFieldResult,
  SystemTypeResultResponse,
  UserTypeResultResponse,
  AccountTypeResultResponse,
  CurrencyResultResponse,
  GeneralSpecializationResult,
  SubspecialtyByGeneralSpecializationResult,
  JournalClassificationResult,
  JournalRankingResult,
  NationalityResultResponse,
  CollegeResult,
} from "./models/LookupsType";
import qs from "qs";

export const LookupService = {
  getCountryCodes: async () => {
    return HttpClient.get<BaseResponseType<CountryCodeResultResponse[]>>("/api/lookup/GetAllCountryCodes");
  },
  getGenders: async () => {
    return HttpClient.get<BaseResponseType<GenderResultResponse[]>>("/api/lookup/GetAllGenders");
  },
  getDegrees: async () => {
    return HttpClient.get<BaseResponseType<DegreeResultResponse[]>>("/api/lookup/GetAllDegrees");
  },
  getJobs: async () => {
    return HttpClient.get<BaseResponseType<JobResultResponse[]>>("/api/lookup/GetAllJobs");
  },
  getNationalities: async () => {
    return HttpClient.get<BaseResponseType<CountryResultResponse[]>>("/api/lookup/GetAllCountries");
  },
  getAllNationalities: async () => {
    return HttpClient.get<BaseResponseType<NationalityResultResponse[]>>("/api/lookup/GetAllNationalitiesDropDown");
  },
  getAllProgramTypes: async () => {
    return HttpClient.get<BaseResponseType<AllProgramTypesResultResponse[]>>("/api/lookup/GetAllProgramTypes");
  },
  getAllProgramClassifications: async () => {
    return HttpClient.get<BaseResponseType<ProgramClassificationResultResponse[]>>(
      "/api/lookup/GetAllProgramClassifications",
    );
  },
  getAllProgramClassificationsByProgramTypeId: async (query: { programTypeId: number }) => {
    return HttpClient.get<BaseResponseType<ProgramClassificationResultResponse[]>>(
      `/api/lookup/GetAllProgramClassificationsByProgramTypeId?${qs.stringify(query)}`,
    );
  },
  getAllProgramCycleYears: async () => {
    return HttpClient.get<BaseResponseType<ProgramCycleYearsResultResponse[]>>("/api/lookup/GetAllProgramCycleYears");
  },
  getAllProgramCycleByYear: async (query: { year?: number }) => {
    if(!query.year) {
      return HttpClient.get<BaseResponseType<ProgramCycleYearsResultResponse[]>>(
          `/api/lookup/GetAllProgramCycleByYear`,
      );
    }
    else
    return HttpClient.get<BaseResponseType<ProgramCycleYearsResultResponse[]>>(
      `/api/lookup/GetAllProgramCycleByYear?${qs.stringify(query)}`,
    );
  },
  getAllAudienceTypes: async () => {
    return HttpClient.get<BaseResponseType<AudienceTypesResultResponse[]>>("/api/lookup/GetAllAudienceTypes");
  },
  getAllFinancingTypes: async () => {
    return HttpClient.get<BaseResponseType<FinancingTypesResultResponse[]>>("/api/lookup/GetAllFinancingTypes");
  },
  getAllResearchFields: async () => {
    return HttpClient.get<BaseResponseType<ResearchFieldResult[]>>("/api/lookup/GetAllResearchFields");
  },
  getAllSubSpecialties: async () => {
    return HttpClient.get<BaseResponseType<SubspecialtyResult[]>>("/api/lookup/GetAllSubSpecialties");
  },
  getAllSystemTypes: async () => {
    return HttpClient.get<BaseResponseType<SystemTypeResultResponse[]>>("/api/lookup/GetAllSystemTypes");
  },
  getAllUserTypes: async () => {
    return HttpClient.get<BaseResponseType<UserTypeResultResponse[]>>("/api/lookup/GetAllUserTypes");
  },
  getAllCurrencies: async () => {
    return HttpClient.get<BaseResponseType<CurrencyResultResponse[]>>("/api/lookup/GetAllCurrencies");
  },
  getAllAccountTypes: async () => {
    return HttpClient.get<BaseResponseType<AccountTypeResultResponse[]>>("/api/lookup/GetAllAccountTypes");
  },
  getAllGeneralSpecialization: async () => {
    return HttpClient.get<BaseResponseType<GeneralSpecializationResult[]>>("/api/lookup/GetAllGeneralSpecialization");
  },
  getAllSubspecialtyByGeneralSpecializationId: async ({ id }: { id: number }) => {
    return HttpClient.get<BaseResponseType<SubspecialtyByGeneralSpecializationResult[]>>(
      `/api/lookup/GetAllSubspecialtyByGeneralSpecializationId/${id}`,
    );
  },
  getAllJournalClassifications: async () => {
    return HttpClient.get<BaseResponseType<JournalClassificationResult[]>>("/api/Lookup/GetAllJournalClassifications");
  },
  getAllJournalRanking: async () => {
    return HttpClient.get<BaseResponseType<JournalRankingResult[]>>("/api/Lookup/GetAllJournalRanking");
  },
  getAllCollegesDropDown: async () => {
    return HttpClient.get<BaseResponseType<CollegeResult[]>>("/api/lookup/GetAllCollegesDropDown");
  },
};
