import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FileUploadWithPreview, { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import FormInput from "components/blocks/FormInput";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ProceduresTable from "./ProceduresTable";
import { useForm } from "react-hook-form";
import {
  CreateProgramMinutes,
  ProgramByIDResponse,
  ProgramLocalItemResult,
} from "services/programs/models/ProgramTypes";
import { getAddProgramMinutesSchema } from "./getAddProgramMinutes.schema";
import DatePicker from "components/blocks/DatePicker";
import useMutation from "hooks/useMutation";
import { ProgramsService } from "services/programs";
import { BaseResponseType } from "services/shared-models/sharedTypes";
import { FinalCommitteeDecisionLookup, OrderLocalResult } from "services/orders/models/OrdersTypes";
import { TaskActionsNames } from "services/tasks/models/tasksTypes";

export type AddEditProgramMinutesRef = {
  submitAddProgramMinuteForm: (notes: string) => Promise<BaseResponseType<ProgramLocalItemResult> | undefined>;
};

const AddProgramMinutes = forwardRef<
  AddEditProgramMinutesRef,
  {
    program: ProgramByIDResponse;
    orders?: OrderLocalResult[];
    serialGuid?: string | null;
    actionNames?: TaskActionsNames[] | null;
    primaryResearcherOrderIds?: Set<number>;
  }
>(({ program, primaryResearcherOrderIds, serialGuid, actionNames }, ref) => {
  const { t } = useTranslation(["Programs", "Common"]);
  const formRef = useRef<HTMLFormElement>(null);
  const [files, setFiles] = useState<SelectedFilesType[]>([]);
  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    watch,
  } = useForm<CreateProgramMinutes>({
    defaultValues: {
      committeeDecisionDate: "",
      committeeDecisionNumber: "",
      minuteDescription: "",
      filesIds: [],
    },
    resolver: getAddProgramMinutesSchema(t),
  });

  const { loading: addProgramMinutesLoading, mutateAsync: addProgramMinutes } = useMutation({
    queryFn: async (data: CreateProgramMinutes) => {
      if (serialGuid && actionNames) {
        data.actionName = "Complete";
        data.serialGuid = serialGuid;
      }

      return await ProgramsService.updateProgramMinutes(program.programMinutes!.id, data);
    },
  });

  useImperativeHandle(ref, () => ({
    submitAddProgramMinuteForm: async (notes) => {
      if (!addProgramMinutesLoading) {
        formRef.current?.classList.add("was-validated");

        let res: BaseResponseType<ProgramLocalItemResult> | undefined;
        await handleSubmit(async (values) => {
          values.notes = notes;
          res = (await addProgramMinutes(values)) as BaseResponseType<ProgramLocalItemResult>;
        })();

        return res;
      }
    },
  }));

  useEffect(() => {
    if (program) {
      setValue("committeeDecisionNumber", program.programMinutes?.committeeDecisionNumber ?? "");
      setValue("committeeDecisionDate", program.programMinutes?.committeeDecisionDate ?? "");
      setValue("minuteDescription", program.programMinutes?.minuteDescription ?? "");
      setValue("filesIds", program.programMinutes?.files?.map((file) => file.id) ?? []);
      setFiles(
        program.programMinutes?.files?.map((file) => ({
          uuid: file.id!,
          name: file.name!,
          size: file.size!,
          type: file.type!,
          file: new File([], file.name!),
          fileName: file.name!,
        })) ?? [],
      );
    }
  }, [program]);

  return (
    <div className="d-flex flex-column gap-2">
      <Accordion>
        <AccordionItem initiallyOpen elementId="funding" title={t("funding")}>
          <div className="d-flex gap-2">
            <FormInput
              disabled
              placeholder={t("primaryResearchersCount")}
              value={primaryResearcherOrderIds?.size ?? 0}
              label={t("primaryResearchersCount")}
            />
            <FormInput
              disabled
              placeholder={t("totalFunding")}
              value={`${program.fundingAmount * (primaryResearcherOrderIds?.size ?? 0)} ${t("ryal")}`}
              label={t("totalFunding")}
            />
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider"></div>

      <Accordion>
        <AccordionItem initiallyOpen elementId="MinutesOfMeeting" title={t("minutesOfMeeting")}>
          <form ref={formRef} noValidate className="d-flex flex-column gap-3">
            <div className="d-flex gap-2">
              <FormInput
                placeholder={t("committeeDecisionNumber")}
                label={t("committeeDecisionNumber")}
                {...register("committeeDecisionNumber")}
                error={errors.committeeDecisionNumber?.message}
              />
              <DatePicker
                placeholder={t("committeeDecisionDate")}
                label={t("committeeDecisionDate")}
                onChange={(date) => setValue("committeeDecisionDate", date?.toISOString() ?? "")}
                value={watch("committeeDecisionDate")}
                error={errors.committeeDecisionDate?.message}
              />
            </div>

            <div>
              <label className="form-label mb-2">{t("minutesOfTheCommitteeMeeting")}</label>
              <textarea
                className="form-control"
                rows={5}
                placeholder={t("minutesOfTheCommitteeMeeting")}
                onChange={(e) => setValue("minuteDescription", e.target.value)}
                style={{ resize: "none" }}
              />
              {errors.minuteDescription?.message && (
                <div className="invalid-feedback d-block mt-2">* {t(errors.minuteDescription?.message)}</div>
              )}
            </div>

            <FileUploadWithPreview
              formProps={{}}
              files={files}
              setFiles={setFiles}
              onChange={(newFiles) =>
                setValue(
                  "filesIds",
                  newFiles.map((file) => file.uuid),
                )
              }
              error={errors.filesIds?.message}
            />
          </form>
        </AccordionItem>
      </Accordion>

      <div className="divider"></div>

      <Accordion>
        <AccordionItem initiallyOpen elementId="Procedure" title={t("Procedures")}>
          <ProceduresTable programId={program.id} />
        </AccordionItem>
      </Accordion>
    </div>
  );
});

export default AddProgramMinutes;
