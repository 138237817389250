import pako from 'pako';

export class GzipProvider {
    // Compress a string and return the Base64 encoded result
    compress(data: string): string {
        const compressed = pako.gzip(data);
        //@ts-ignore
        return btoa(String.fromCharCode(...new Uint8Array(compressed)));
    }

    // Decompress a Base64 encoded GZip string and return the original string
    decompress(compressedData: string| undefined): string {
        if(!compressedData) return "";
        const binaryString = atob(compressedData);
        const byteArray = Uint8Array.from(binaryString, char => char.charCodeAt(0));
        const decompressed = pako.ungzip(byteArray, { to: 'string' });
        return decompressed;
    }
}