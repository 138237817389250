import PageWithTabs from "components/PageWithTabs";
import { OrderActivePages, OrderUserTypes, tabsIds } from "..";
import { OrderInfoTab } from "../Tabs/OrderInfoTab";
import { useTranslation } from "react-i18next";
import useResearcherActions from "./useResearcherActions";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import { Dispatch, SetStateAction } from "react";
import { OpenFundingTaskResult } from "services/tasks/models/tasksTypes";
import { EvaluateProgramUpdateRef } from "../Tabs/OrderInfoTab/components/Updates/useProgramOrderUpdateActions";
import { FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { CloseOrderRequestFormProps } from "services/closeOrder/models";
import { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import ActionModal from "components/blocks/Modal/ActionModal";
import { useQuery } from "hooks/useQuery";
import { AccountService } from "services/accountService";
import { useCookieContext } from "contexts";
import { useNavigate } from "react-router-dom";
import { LoginResult } from "services/users/models/loginResult";
import { ApproveByCenterManagerSubmitHandlerRef } from "../PageActions/useCloseRequestActions";

export type CloseOrderFormProps = {
  errors: FieldErrors<CloseOrderRequestFormProps>;
  setValue: UseFormSetValue<CloseOrderRequestFormProps>;
  watch: UseFormWatch<CloseOrderRequestFormProps>;
  files: SelectedFilesType[];
  setFiles: Dispatch<SetStateAction<SelectedFilesType[]>>;
};

const ResearcherCloseRequestPage = ({
  order,
  orderProgram,
  activePage,
  userType,
  setActivePage,
  openedTask,
  evaluateProgramUpdateRef,
  serialGuid,
  approveByCenterManagerSubmitHandlerRef,
}: {
  orderProgram: ProgramByIDResponse | null;
  order: OrderLocalItemResult | null;
  userType: keyof typeof OrderUserTypes;
  activePage: keyof typeof OrderActivePages;
  setActivePage: Dispatch<SetStateAction<"Default" | "ResearchCloseRequest">>;
  openedTask: OpenFundingTaskResult | null;
  evaluateProgramUpdateRef: React.MutableRefObject<EvaluateProgramUpdateRef | null>;
  serialGuid: string;
  approveByCenterManagerSubmitHandlerRef: React.MutableRefObject<ApproveByCenterManagerSubmitHandlerRef | null>;
}) => {
  const navigate = useNavigate();
  const { userSession } = useCookieContext();
  const { t } = useTranslation(["Orders", "Common", "ExaminerTask"]);
  const {
    confirmResearcherCloseContractLoading,
    onConfirmResearcherCloseContract,
    errors,
    setValue,
    watch,
    files,
    setFiles,
  } = useResearcherActions({
    order: order,
    serialGuid: serialGuid,
    activityGuid: openedTask?.activityGuid,
  });

  const { data: hasErrorInBankingInfo, loading } = useQuery({
    queryFn: async () => {
      const res = await AccountService.checkUserBankInformation();
      return {
        ...res,
        data: res.hasError,
      };
    },
    options: {
      enabled: activePage === "ResearchCloseRequest",
    },
  });

  return (
    <>
      <PageWithTabs
        title={t("closeAgreement")}
        subTitle={`${t("programNumber")}: ${orderProgram?.serialNumber ?? ""} • ${t("cycle")}: ${
          orderProgram?.programCycleDetail.displayName ?? ""
        }`}
        actions={() => (
          <button className="btn btn-primary px-3" onClick={() => onConfirmResearcherCloseContract()}>
            {!confirmResearcherCloseContractLoading ? t("send") : <div className="custom-loader"></div>}
          </button>
        )}
        backButtonCallback={() => {
          if (userType === OrderUserTypes.ResearcherSendCloseResponseAfterReturnRequest) {
            navigate("/home/tasks");
          } else {
            setActivePage(OrderActivePages.Default);
          }
        }}
        tapContent={
          <OrderInfoTab
            order={order}
            orderProgram={orderProgram}
            userType={userType}
            activePage={activePage}
            setActivePage={setActivePage}
            openedTask={openedTask}
            programUpdateHandlersRef={evaluateProgramUpdateRef}
            approveByCenterManagerSubmitHandlerRef={approveByCenterManagerSubmitHandlerRef}
            closeOrderFormProps={{
              errors,
              setValue,
              watch,
              files,
              setFiles,
            }}
          />
        }
      />
      <ActionModal
        isOpen={!!hasErrorInBankingInfo && !loading}
        iconWrapperColor="warning"
        actionIcon="icon-warning-circle"
        headerMsg={t("ExaminerTask:bankingInformationNotCompleted")}
        subMsg={t("ExaminerTask:bankingInformationNotCompletedMsg")}
        confirmBtnText={t("ExaminerTask:goToProfile")}
        loading={loading}
        onActionConfirm={() => {
          navigate(`/home/editUserProfile/${userSession!.id}`);
        }}
        onClose={() => {
          navigate(-1);
        }}
      />
    </>
  );
};

export default ResearcherCloseRequestPage;
