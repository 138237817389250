import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import AttachmentCard from "components/blocks/AttachmentCard";
import Modal from "components/blocks/Modal";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExaminerListResult } from "services/examiners/Model/ExaminerTypes";
import { ExaminerSelectedTemplateType } from "..";
import FormViewComponent from "components/formBuilder/FormViewComponent";
import FormInput from "components/blocks/FormInput";

export type ViewExaminerEvaluationModalProps = {
  evaluationPercentage: number;
  examinerEvaluationFormValue: ExaminerListResult["examinerEvaluationFormValue"];
  onClose: () => void;
  selectedTemplate: ExaminerSelectedTemplateType;
  formDefinition?: string;
  recommendations?: string;
};

const ViewExaminerEvaluation = ({ props }: { props: ViewExaminerEvaluationModalProps | null }) => {
  const { t, i18n } = useTranslation(["Orders", "Common"]);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "criteriaName",
        displayName: t("evaluationCriteria"),
        render: (row: ExaminerListResult["examinerEvaluationFormValue"]["criteriaValues"][0]) => (
          <>{row.criteriaValueDetails.find((c) => c.locale?.toLowerCase() === i18n.language)?.displayName ?? ""}</>
        ),
      },
      {
        field: "value",
        displayName: t("wight"),
        renderHeader: () => <>{t("wight")}%</>,
        render: (row: ExaminerListResult["examinerEvaluationFormValue"]["criteriaValues"][0]) => <>{row.weight!}%</>,
      },
      {
        field: "value",
        displayName: t("evaluation"),
        renderHeader: () => <>{t("evaluation")}%</>,
        render: (row: ExaminerListResult["examinerEvaluationFormValue"]["criteriaValues"][0]) => <>{row.value}%</>,
      },
      {
        field: "value",
        displayName: t("result"),
        renderHeader: () => <>{t("result")}%</>,
        render: (row: ExaminerListResult["examinerEvaluationFormValue"]["criteriaValues"][0]) => (
          <>{(row.value * row.weight!) / 100}%</>
        ),
      },
      {
        field: "notes",
        displayName: t("notes"),
      },
    ],
    [],
  );

  const gridFooter = useMemo(() => {
    return [
      [
        {
          content: <span className="fw-bold p-2">{t("finalSummation")}</span>,
        },
        {
          content: "",
          colSpan: 2,
        },
        {
          content: <span className="fw-bold p-2">{(props?.evaluationPercentage ?? 0).toFixed(2)}%</span>,
        },
        {
          content: "",
        },
      ],
    ];
  }, [props?.examinerEvaluationFormValue]);

  if (!props) {
    return null;
  }

  const { examinerEvaluationFormValue: examinerEvaluation, onClose, selectedTemplate } = props;

  return (
    <Modal
      title={selectedTemplate.title}
      isOpen={true}
      onClose={onClose}
      containerClassName="bg-white"
      size="xl"
      titleClassName="fs-6"
      bodyMargin="p-0 m-0"
    >
      <>
        <div className="divider my-2"></div>
        <div className="d-flex flex-column gap-2">
          <Grid data={examinerEvaluation.criteriaValues} gridSchema={gridSchema} gridFooter={gridFooter} />
          <div className="divider mt-1"></div>
          <FormViewComponent
            data={examinerEvaluation.formBuilderValue ? JSON.parse(examinerEvaluation.formBuilderValue) : {}}
            formDefinition={props.formDefinition ? JSON.parse(props.formDefinition) : {}}
            readOnly
          />
          <FormInput disabled label={t("recommendations")} labelClassName="fw-bold" value={props.recommendations} />
          <div className="divider mt-1"></div>
          <div className="d-flex flex-column gap-1 ">
            <label className="fw-bold">{t("attachments")}</label>
            <div className="d-flex justify-content-start gap-2">
              {examinerEvaluation && examinerEvaluation.files.length > 0 ? (
                examinerEvaluation.files?.map((attachment) => (
                  <AttachmentCard
                    key={attachment.id}
                    attachment={{
                      extension: attachment.extension ?? "",
                      fileName: attachment.name ?? "",
                      size: attachment.size ?? "",
                      id: attachment.id,
                    }}
                  />
                ))
              ) : (
                <span>{t("Common:noData")}</span>
              )}
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ViewExaminerEvaluation;
