import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FormInput from "components/blocks/FormInput";
import Grid from "components/GridView/components/Grid";
import { GridSchema } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const MySignedWordOrderTab: React.FC = () => {
  const { t } = useTranslation("OrdersMyWork");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const ordersGridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "details",
        displayName: "التفاصيل",
        showOnMobile: true,
      },
      {
        field: "progress",
        displayName: "نسبة الانجاز",
        showOnMobile: true,
      },
      {
        field: "date",
        displayName: "التاريخ",
        render: (row) => {
          return toSystemThemeDateFormat(row.date);
        },
      },
      {
        field: "evaluation",
        displayName: "التقييم",
      },
      {
        field: "researchCenterNotes",
        displayName: "ملاحظات مركز البحوث",
      },
      {
        field: "file",
        displayName: "الملف",
        showOnMobile: true,
        render: (row) => {
          return <span role="button" className="icon-document text-primary fs-24px" />;
        },
      },
    ],
    [],
  );

  const ordersData = useMemo(
    () => [
      {
        details: "تفاصيل الطلب",
        progress: "30%",
        date: "2024-09-22T20:54:57.529Z",
        evaluation: "التقييم",
        researchCenterNotes: "ملاحظات مركز البحوث",
        file: "path/some_file.doc",
      },
    ],
    [],
  );

  return (
    <div className="d-flex flex-column">
      <div>
        <h6 className="fw-bold">عنوان البحث</h6>
        <p className="my-0">
          لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات
          دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريد أكسير سيتاشن يللأمكو لابورأس نيسي يت أليكيوب أكس
          أيا كوممودو كونسيكيوات . ديواس
        </p>
      </div>

      <div className="mb-4">
        <h6 className="fw-bold mt-4">الوصف كاملاً</h6>
        <p className="my-0">
          لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات
          دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريد أكسير سيتاشن يللأمكو لابورأس نيسي يت أليكيوب أكس
          أيا كوممودو كونسيكيوات . ديواس
        </p>
      </div>

      <Accordion>
        <AccordionItem title="المرفقات" initiallyOpen elementId="attachments">
          <div className="d-flex gap-3">
            {Array.from({ length: 3 }).map((_, index) => (
              <AttachmentCard
                key={index}
                attachment={{
                  id: index,
                  extension: index % 2 === 0 ? "pdf" : "doc",
                  fileName: "اسم الملف",
                  size: "250MB",
                }}
              />
            ))}
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider mt-4" />

      <Accordion>
        <AccordionItem title="التحديثات" initiallyOpen elementId="updates">
          <div className="d-flex gap-4 flex-wrap align-items-end">
            <FormInput wrapperClassName="flex-1" label="التفاصيل" placeholder="التفاصيل" />
            <FormInput
              wrapperClassName="flex-1"
              label="نسبة الانجاز"
              placeholder="نسبة الانجاز"
              leftElement={<span className="fs-24px text-primary">%</span>}
            />
            <FilePreview
              wrapperClassName="flex-1"
              onShow={() => {}}
              callbackValue="todo"
              title="اسم الملف.doc"
              fileSize="5.7MB"
            />
            <button className="btn btn-primary">{t("Common:save")}</button>
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider mb-4" />

      <Grid data={ordersData} gridSchema={ordersGridSchema} />

      <div className="divider mb-4" />

      <div className="d-flex gap-4">
        <button className="px-3 btn btn-primary">
          <span className="icon-yes fs-24px" /> طلب اغلاق
        </button>
        <button className="px-3 btn btn-outline-dark">
          <span className="icon-edit fs-24px" /> طلب تعديل
        </button>
        <button className="px-3 btn btn-outline-dark">
          <span className="icon-add-time fs-24px" /> طلب تمديد
        </button>
        <button className="px-3 btn btn-outline-dark">
          <span className="icon-apologize fs-24px" /> طلب إعتذار
        </button>
      </div>
    </div>
  );
};

export default MySignedWordOrderTab;
