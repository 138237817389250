import Button from "components/blocks/Button";
import { MutableRefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import ApologizeRequestModal from "./Modals/ApologizeRequestModal";
import RequestAnInquiryModal from "./Modals/RequestAnInquiryModal";
import useMutation from "hooks/useMutation";
import { OrdersService } from "services/orders";
import { WorkFlowTaskRequest } from "services/orders/models/OrdersTypes";
import { useNavigate } from "react-router-dom";
import { TaskActionsNames } from "services/tasks/models/tasksTypes";

const ResearcherActions = ({
  isResearcherAcceptanceButtonDisabled,
  orderId,
  serialGuid,
  actionNames,
  notesForAcceptsRef,
}: {
  isResearcherAcceptanceButtonDisabled: boolean;
  orderId: number;
  serialGuid: string;
  actionNames?: TaskActionsNames[];
  notesForAcceptsRef?: MutableRefObject<string>;
}) => {
  const { t } = useTranslation("Programs");
  const navigate = useNavigate();
  const [modalProps, setModalProps] = useState<{
    isOpen: boolean;
    type?: "requestAnInquiry" | "apology" | "none";
  }>({
    isOpen: false,
    type: "none",
  });

  const { loading: isAcceptanceLoading, mutateAsync } = useMutation({
    queryFn: async ({ id, request }: { id: number; request: WorkFlowTaskRequest }) => {
      return await OrdersService.programOrderResearcherSignContract(id, request);
    },
    notificationSuccessMessage: t("Common:success"),
  });

  const onApprove = async () => {
    await mutateAsync({
      id: orderId,
      request: {
        actionName: "Approve",
        serialGuid: serialGuid,
        notes: notesForAcceptsRef?.current,
      },
    });

    navigate(-1);
  };

  return (
    <div className="d-flex gap-2">
      {actionNames?.includes("Approve") && (
        <Button
          label={t("acceptance")}
          variant="primary"
          className="p-2"
          disabled={isResearcherAcceptanceButtonDisabled || isAcceptanceLoading}
          onClick={() => onApprove()}
        />
      )}
      <Button
        label={t("requestAnInquiry")}
        className="p-2 border text-dark"
        disabled={isAcceptanceLoading}
        onClick={() => setModalProps({ isOpen: true, type: "requestAnInquiry" })}
      />

      {actionNames?.includes("Apology") && (
        <Button
          label={t("apology")}
          variant="outline-danger"
          className="p-2"
          disabled={isAcceptanceLoading}
          onClick={() => setModalProps({ isOpen: true, type: "apology" })}
        />
      )}

      <ApologizeRequestModal
        isOpen={modalProps.isOpen && modalProps.type === "apology"}
        onClose={() => setModalProps({ ...modalProps, isOpen: false })}
        onConfirm={() => {
          setModalProps({ ...modalProps, isOpen: false });
          navigate(-1);
        }}
        orderId={orderId}
        serialGuid={serialGuid}
      />

      <RequestAnInquiryModal
        isOpen={modalProps.isOpen && modalProps.type === "requestAnInquiry"}
        onClose={() => setModalProps({ ...modalProps, isOpen: false })}
        onConfirm={() => {
          setModalProps({ ...modalProps, isOpen: false });
          navigate(-1);
        }}
        orderId={orderId}
        serialGuid={serialGuid}
      />
    </div>
  );
};

export default ResearcherActions;
