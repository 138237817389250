import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { TaskResult } from "services/tasks/models/tasksTypes";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { TasksService } from "services/tasks";
import useFileDownload from "hooks/useFileDownload";
import IconButton from "components/blocks/IconButton";
import ReAssignUserModal from "./ReAssignUserModal";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useCookieContext } from "contexts";
import { prepareFilters } from "components/GridView/components/GridFilter";
import AuthGuard from "components/blocks/AuthGuard";

export default function TasksGridView({}: {}) {
  const { t } = useTranslation("Tasks");
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumb();
  const downloadFile = useFileDownload();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const [reAssignModalProps, setReAssignModalProps] = useState<{
    isOpen: boolean;
    task: TaskResult | null;
    refreshTableTrigger: boolean;
  }>({
    isOpen: false,
    task: null,
    refreshTableTrigger: false,
  });

  const ready = useRef(false);
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const [tasks, setTasks] = useState<TaskResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const { isAr } = useCookieContext();

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchTasks = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await TasksService.getMyTasks({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: prepareFilters(filters),
      });

      setTasks(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchTasks();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }

    fetchTasks({ resetPagination: true });
  }, [sortState, searchValue, filters, reAssignModalProps.refreshTableTrigger, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "tasks",
        path: "/home/tasks",
        menuItemId: "tasks",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "ActivityName",
        displayName: t("taskName"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
        render: (row: TaskResult) => {
          return (
            <button className="btn btn-link" onClick={() => navigate(row.taskUrl)}>
              {row.activityName}
            </button>
          );
        },
      },
      {
        field: "WorkflowName",
        accessor: "workflowName",
        filterable: true,
        displayName: t("workflowName"),
        sortable: true,
      },
      {
        field: "StartDateTime",
        displayName: t("taskDate"),
        render: (row: TaskResult) => {
          return toSystemThemeDateFormat(row.startDateTime);
        },
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
      },
      {
        accessor: "folio",
        field: "Folio",
        displayName: t("description"),
        sortable: true,
      },
      {
        field: "originatorUserName",
        displayName: t("sender"),
        sortable: true,
        filterable: true,
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        sortable: false,
        showOnMobile: true,
        render: (row: TaskResult) => {
          return (
            <span className="d-flex gap-2">
              <IconButton
                fitToIconWidth
                icon="icon-view"
                title={t("Common:view")}
                innerIconColor="primary"
                size="lg"
                onClick={() => navigate(row.taskUrl)}
              />

              <AuthGuard
                requiredRoles={["FundingTasksWrite", "FundingTasksAcceptReject"]}
                extraCheck={() => !!row.reassign}
              >
                <IconButton
                  fitToIconWidth
                  icon={"icon-reassign"}
                  innerIconColor="dark"
                  size="lg"
                  title={t("reAssign")}
                  onClick={() => {
                    setReAssignModalProps({
                      isOpen: true,
                      task: row,
                      refreshTableTrigger: reAssignModalProps.refreshTableTrigger,
                    });
                  }}
                />
              </AuthGuard>
            </span>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("tasks"),
    singularName: t("task"),
    totalCount: totalCount,
    onExportClick: async () => {
      setLoading(true);
      const response = await TasksService.exportTasksToExcel({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: prepareFilters(filters),
      });
      await downloadFile(response, "tasks.xlsx");
      setLoading(false);
    },
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: tasks ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
      <ReAssignUserModal
        isOpen={reAssignModalProps.isOpen}
        task={reAssignModalProps.task}
        onClose={(isReassigned) =>
          setReAssignModalProps({
            isOpen: false,
            task: null,
            refreshTableTrigger: isReassigned
              ? !reAssignModalProps.refreshTableTrigger
              : reAssignModalProps.refreshTableTrigger,
          })
        }
      />
    </>
  );
}
