import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateMyProfileRequest } from "services/accountService/models/AccountType";
import { z } from "zod";

export const getUpdateProfile = (t: any) => {
  const phoneRegex = /^[0-9]\d{5,14}$/;
  // const countryCodeRegex = /^\+\d{1,3}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const schema = z.object({
    employeeId: z.string().min(1, t("Common:invalidValue")),
    username: z.string().min(1, t("Common:invalidValue")),
    fullNameArabic: z.string().min(1, t("Common:invalidValue")),
    fullNameEnglish: z.string().min(1, t("Common:invalidValue")),
    nationality: z.string().min(1, t("Common:invalidValue")),
    nationalId: z.string().min(1, t("Common:invalidValue")),
    email: z.string().email(t("Common:invalidValue")).regex(emailRegex, t("Common:invalidValue")),
    phoneNumber: z.string().regex(phoneRegex, t("Common:invalidValue")).optional(),
    phoneCountryCode: z
      .string() /* .regex(countryCodeRegex, t("Common:invalidValue")) */
      .optional(),
    department: z.string().optional(),
    college: z.string().optional(),
    specialization: z.string().optional(),
    university: z.string().optional(),
    degree: z.string().optional(),
    jobId: z.number().optional(),
    degreeId: z.number().optional(),
    employer: z.string().optional(),
    orcid: z.string().optional(),
    googleScholarId: z.string().optional(),
    beneficiaryName: z.string().optional(),
    bankName: z.string().optional(),
    ibanNumber: z.string().regex(/^SA\d{22}$/, t("UserProfile:ibanNumberShouldBe22DigitsAndStartWithSA")),
    bankRoutingNumber: z.string().optional(),
    currencyId: z.number().optional(),
    userResearchInterests: z.array(z.string()),
    birthDate: z.string().min(1, t("Common:invalidValue")),
    subspecialtyId: z.number().optional(),
    type: z.string().optional(),
    genderId: z.number().min(1, t("Common:invalidValue")),
    nationalityId: z.number().min(1, t("Common:invalidValue")),
    collegeId: z.number().min(1, t("Common:invalidValue")),
  }) as z.ZodType<UpdateMyProfileRequest>;

  return zodResolver(schema);
};
