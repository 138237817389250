import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ResearcherLocalResult } from "services/researchers/models/ResearcherTypes";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { ResearchersService } from "services/researchers";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import IconButton from "components/blocks/IconButton";
import ActionModal from "components/blocks/Modal/ActionModal";
import { AccountService } from "services/accountService";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useCookieContext } from "contexts";
import { prepareFilters } from "components/GridView/components/GridFilter";
import AuthGuard from "components/blocks/AuthGuard";

export default function ResearchersGridView() {
  const { t } = useTranslation(["Researchers", "Common"]);
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { showNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumb();

  const [modalProps, setModalProps] = useState({
    isOpen: false,
    type: "block",
    id: "",
    researcherName: "",
    loading: false,
  });

  const [loading, setLoading] = useState(false);
  const [researchers, setResearchers] = useState<ResearcherLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const ready = useRef(false);
  const { isAr } = useCookieContext();

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchResearchers = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await ResearchersService.getAllResearchersAsync({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: prepareFilters(filters),
      });

      setResearchers(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchResearchers();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchResearchers({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allResearchers",
        path: "/home/allResearchers",
        menuItemId: "allResearchers",
      },
    ]);
  }, []);

  const onChangeResearcherStatus = (isUserBlocked: boolean, name: string, id: string) => {
    // showModal
    setModalProps({
      isOpen: true,
      type: !isUserBlocked ? "block" : "unblock",
      researcherName: name,
      id: id,
      loading: false,
    });
  };

  const onModalConfirm = async () => {
    setModalProps({
      ...modalProps,
      loading: true,
    });

    try {
      const response =
        modalProps.type === "block"
          ? await AccountService.blockUser(modalProps.id)
          : await AccountService.unblockUser(modalProps.id);

      if (response.hasError) {
        showNotification({
          type: "error",
          description: response.description,
        });
        setModalProps({
          ...modalProps,
          loading: false,
        });
        return;
      }

      // TODO: check with @Alaa
      // introduce a 1.2 seconds delay (somehow the data is not updated immediately)
      await new Promise((resolve) => setTimeout(resolve, 1200));

      await fetchResearchers({ resetPagination: true });

      setModalProps({
        isOpen: false,
        type: modalProps.type,
        researcherName: "",
        id: "",
        loading: false,
      });

      showNotification({
        type: "success",
        description: t("Common:success"),
      });
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setModalProps({
        ...modalProps,
        loading: false,
      });
    }
  };

  const onExportClicked = async () => {
    setLoading(true);
    try {
      const response = await ResearchersService.exportResearchersToExcelAsync({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
      });
      const blob = new Blob([response.data], { type: response.headers["content-type"] });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Researchers.xlsx";
      document.body.appendChild(link);
      link.click();
      setLoading(false);
      link.remove();
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setLoading(false);
    }
  };

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: `AppliedUser.${isAr ? "ArabicName" : "EnglishName"}`,
        displayName: t("researcherName"),
        filterable: true,
        sortable: true,
        showOnMobile: true,
        render: (row: ResearcherLocalResult) => {
          return <Link to={`view?researcherId=${row.id}`}>{row.researcherName}</Link>;
        },
      },
      {
        field: "NumberOfOrders",
        accessor: "numberOfOrders",
        filterable: false,
        displayName: t("fundingOrdersCount"),
      },
      {
        field: "CreatedOn",
        displayName: t("createdOn"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
        render(row: ResearcherLocalResult) {
          return <> {row.createdOn ? toSystemThemeDateFormat(row.createdOn) : ""}</>;
        },
      },
      {
        field: "IsActive",
        displayName: t("status"),
        showOnMobile: true,
        sortable: true,
        // sortable: true,
        // filterable: true,
        // filterOptions: {
        //   type: "select",
        //   options: [
        //     { id: "0", value: t("active") },
        //     { id: "1", value: t("blocked") },
        //   ],
        // },
        render(row: ResearcherLocalResult) {
          const statusClassName =
            row.status === "محظور" ? "bg-danger bg-opacity-10 text-danger" : "bg-primary bg-opacity-10 text-primary";
          return <span className={`badge rounded-4 ${statusClassName} py-2`}>{t(row.status)}</span>;
        },
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        render: (row: ResearcherLocalResult) => {
          const innerIconColor = row.status === "محظور" ? "dark" : "danger";

          return (
            <AuthGuard requiredRoles={["FundingResearchersInfoWrite"]}>
              <IconButton
                icon="icon-block"
                title={row.status === "محظور" ? t("Common:unblock") : t("Common:block")}
                fitToIconWidth
                innerIconColor={innerIconColor}
                size="sm"
                onClick={() => onChangeResearcherStatus(row.status === "محظور", row.researcherName, row.id)}
              />
            </AuthGuard>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("researchersInfo"),
    singularName: t("researcher"),
    totalCount: totalCount,
    hideAddButton: false,
    onExportClick: onExportClicked,
  };

  return (
    <div>
      <GridView
        loading={loading}
        gridProps={{
          data: researchers ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
      <ActionModal
        isOpen={modalProps.isOpen}
        onClose={() => {
          setModalProps({
            isOpen: false,
            type: modalProps.type,
            researcherName: "",
            id: "",
            loading: false,
          });
        }}
        headerMsg={
          modalProps.type === "block" ? t("blockResearcherModalHeaderMsg") : t("unblockResearcherModalHeaderMsg")
        }
        subMsg={
          modalProps.type === "block"
            ? t("blockResearcherModalSubMsg", {
                researcherName: modalProps.researcherName,
              })
            : t("unblockResearcherModalSubMsg", {
                researcherName: modalProps.researcherName,
              })
        }
        actionIcon="icon-block"
        confirmBtnText={modalProps.type === "block" ? t("block") : t("unblock")}
        iconWrapperColor={modalProps.type === "block" ? "danger" : "primary"}
        onActionConfirm={onModalConfirm}
        loading={modalProps.loading}
      />
    </div>
  );
}
