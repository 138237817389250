import { useEffect, useState } from "react";
import { DropdownItemType } from "./Dropdown";
import { useTranslation } from "react-i18next";
import { useCookieContext } from "contexts";
import Select, { MultiValue } from "react-select";

export type MultiDropdownProps = {
  label?: string;
  data: DropdownItemType[];
  wrapperClassName?: string;
  value?: string[] | number[];
  onChange?: (value: string[], dbRes?: any[]) => void;
  disabled?: boolean;
  formProps?: any;
  changeEffect?: (value?: any) => void;
  error?: string;
  labelClassName?: string;
  readOnly?: boolean;
  withAsterisk?: boolean;
  wrapperStyle?: React.CSSProperties;
  useReactSelect?: boolean;
  dbRes?: any[];
};

const MultiSelectDropdown: React.FC<MultiDropdownProps> = (props) => {
  const {
    label,
    data,
    error,
    wrapperClassName,
    value,
    onChange,
    readOnly,
    disabled,
    formProps,
    changeEffect,
    labelClassName,
    withAsterisk,
    wrapperStyle,
    useReactSelect,
    dbRes,
  } = props;

  const { t } = useTranslation();
  const { isRTL } = useCookieContext();
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const onLocalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.value;
    if (selectedOption === "0") return;
    const selectedOptions = selectedValues.includes(selectedOption)
      ? selectedValues.filter((v) => v !== selectedOption)
      : [...selectedValues, selectedOption];
    setSelectedValues(selectedOptions);
    onChange?.(selectedOptions, dbRes);
    changeEffect?.(selectedOptions);
  };

  const onLocalReactSelectChange = (selectedOptions: MultiValue<DropdownItemType>) => {
    setSelectedValues((selectedOptions as DropdownItemType[]).map((option) => `${option.id}`));
    onChange?.(
      (selectedOptions as DropdownItemType[]).map((option) => `${option.id}`),
      dbRes,
    );
    changeEffect?.((selectedOptions as DropdownItemType[]).map((option) => `${option.id}`));
  };

  useEffect(() => {
    setSelectedValues(value?.map((v) => `${v}`) ?? []);
  }, [value]);

  const hasError = !value?.length && !!error;

  return (
    <div className={`${wrapperClassName}`} style={{ minWidth: 150, ...(wrapperStyle ? wrapperStyle : {}) }}>
      {label && (
        <div className={`mb-1 ${labelClassName} position-relative`}>
          <label className={`${withAsterisk ? "px-2" : ""}`}>{label}</label>
          {withAsterisk && (
              <span
                  className={`p text-danger`}
                  style={{
                    fontSize: "0.75rem",
                  }}
              >
              *
            </span>
          )}
        </div>
      )}
      {useReactSelect ? (
        <>
          <Select
            options={data}
            value={selectedValues.map((v) => data.find((x) => +x.id === +v)!)}
            onChange={onLocalReactSelectChange}
            getOptionLabel={(option) => option.value}
            isMulti
            noOptionsMessage={() => t("Common:noData")}
            placeholder={selectedValues.length === 0 ? label : `${selectedValues.length} ${t("Common:selected")}`}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            styles={{
              control: (styles) => ({
                ...styles,
                borderColor: hasError ? "red" : styles.borderColor,
                backgroundColor: hasError ? "#f8d7da" : styles.backgroundColor,
                height: "42px",
              }),
            }}
          />
        </>
      ) : (
        <select
          className={`form-select form-control ${hasError ? "is-invalid bg-danger" : ""} ${readOnly ? "bg-white" : ""}`}
          aria-label={label}
          disabled={disabled || readOnly}
          {...formProps}
          value={0}
          onChange={onLocalChange}
        >
          <option value="0" className="text-muted bg-white" disabled>
            {selectedValues.length === 1
              ? data.find((x) => `${x.id}` === selectedValues[0])?.value
              : `${selectedValues.length} ${t("Common:selected")}`}
          </option>
          {data.map((item) => (
            <option
              key={item.id}
              value={item.id}
              className={selectedValues.includes(`${item.id}`) ? "bg-primary text-white" : ""}
            >
              {selectedValues.includes(`${item.id}`) ? `✓ ${item.value}` : item.value}
            </option>
          ))}
        </select>
      )}
      {hasError && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  );
};

export default MultiSelectDropdown;
