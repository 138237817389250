import { useCookieContext } from "contexts";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { FundingRoles } from "services/users/models/loginResult";

export const hasRequiredRole = (userRoles: FundingRoles[], requiredRoles: FundingRoles[]): boolean => {
  return requiredRoles.some((role) => userRoles.includes(role));
};

const AuthGuard = ({
  requiredRoles,
  children,
  fallbackComponent,
  useDefaultFallback: useDefaultFallbackComponent,
  extraCheck,
}: {
  requiredRoles: FundingRoles[];
  children: React.ReactNode;
  fallbackComponent?: ReactNode;
  useDefaultFallback?: boolean;
  extraCheck?: () => boolean;
}) => {
  const { t } = useTranslation("Common");
  const { userSession } = useCookieContext();
  const hasAccess = hasRequiredRole(userSession?.roles || [], requiredRoles) && (!extraCheck || extraCheck());

  if (useDefaultFallbackComponent && !hasAccess) {
    return (
      <div className="d-flex h-100 w-100 algin-items-center justify-content-center">
        <h1>{t("unAuthorized")}</h1>
      </div>
    );
  }

  return hasAccess ? <>{children}</> : fallbackComponent ? <>{fallbackComponent}</> : <></>;
};

export default AuthGuard;
