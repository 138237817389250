import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { useState } from "react";
// import "ckeditor5-premium-features/ckeditor5-premium-features.css";

type RichTextEditorProps = {
  initialData: string;
  onChange?: (data: string) => void;
  isReadOnly?: boolean;
};

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;

const RichTextEditor = (props: RichTextEditorProps) => {
  const { initialData, onChange, isReadOnly } = props;
  const [isEditorReady, setIsEditorReady] = useState(isReadOnly);

  return (
    <Editor
      tools={
        isReadOnly
          ? []
          : [
              [Bold, Italic, Underline, Strikethrough],
              [Subscript, Superscript],
              [AlignLeft, AlignCenter, AlignRight, AlignJustify],
              [Indent, Outdent],
              [OrderedList, UnorderedList],
              FontSize,
              FontName,
              FormatBlock,
              [Undo, Redo],
              [Link, Unlink, InsertImage, ViewHtml],
              [InsertTable],
              [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
              [DeleteRow, DeleteColumn, DeleteTable],
              [MergeCells, SplitCell],
            ]
      }
      onChange={(event) => {
        onChange && onChange(event.html);
      }}
      onMount={(editor) => {
        if (editor) {
          const content = editor.dom;
          if (isReadOnly) {
            if (content)
              setTimeout(() => {
                setIsEditorReady(false);
                content.setAttribute("contenteditable", "false");
              }, 2000);
          } else {
            if (content) content.setAttribute("contenteditable", "true");
          }
        }
      }}
      contentStyle={{ height: 630, pointerEvents: isEditorReady ? "none" : "auto" }}
      defaultContent={initialData}
    />
  );
};

export default RichTextEditor;
