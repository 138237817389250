import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GridViewHeaderProps } from "../GridView.types";
import useGridStore from "../grid.store";
import { useShallow } from "zustand/react/shallow";
import AuthGuard from "components/blocks/AuthGuard";

const GridViewHeader: React.FC<GridViewHeaderProps> = (props) => {
  const { title, totalCount, addPageRoute, singularName, onExportClick, actionsComponent } = props;

  const { t } = useTranslation("Common");
  const { setGeneralSearch, modelFilterVisible, toggleModelFilterVisible } = useGridStore(
    useShallow((state) => ({
      setGeneralSearch: state.setGeneralSearch,
      modelFilterVisible: state.modelFilterVisible,
      toggleModelFilterVisible: state.toggleModelFilterVisible,
    })),
  );
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onExcelExport = () => {
    onExportClick?.();
  };

  return (
    <div className="d-flex flex-wrap justify-content-between align-items-start mb-3 gap-3">
      <div className="">
        <h4 className="text-dark">{title}</h4>
        {singularName && (
          <p className="text-body mb-0">
            {totalCount === 0 ? t("noData") : `${t("youHave")} ${totalCount} ${singularName}`}
          </p>
        )}
      </div>

      {actionsComponent}
      {!props.hideAddButton &&
        addPageRoute &&
        (props.addButtonPermissions ? (
          <AuthGuard requiredRoles={props.addButtonPermissions}>
            <Link to={addPageRoute} className="btn btn-primary p-10-32P me-auto">
              {t("add")} {singularName}
            </Link>
          </AuthGuard>
        ) : (
          <Link to={addPageRoute} className="btn btn-primary p-10-32P me-auto">
            {t("add")} {singularName}
          </Link>
        ))}
      <div className="d-flex flex-wrap flex-no-wrap-mobile mb-3 gap-3 flex-grow-mobile">
        <div className="input-with-icon flex-grow-1">
          <input
            value={searchValue}
            onChange={handleSearchChange}
            onKeyUp={(e) => e.key === "Enter" && setGeneralSearch(searchValue)}
            type="text"
            className="form-control"
            placeholder={t("search")}
            aria-label="Search"
          />
          <i className="icon-search" />
        </div>
        <button
          type="button"
          className={`btn ${modelFilterVisible ? "btn-outline-primary active" : " btn-outline-secondary"}`}
          style={{ width: 42, borderColor: "#D5D5D5" }}
          onClick={toggleModelFilterVisible}
        >
          <i className="icon-filter" />
        </button>
        {props.onExportClick && (
          <button
            id="export-filter-btn"
            type="button"
            className="btn btn-outline-secondary"
            style={{ width: 42, borderColor: "#D5D5D5" }}
            onClick={onExcelExport}
          >
            <i className=" icon-export" />
          </button>
        )}
      </div>
    </div>
  );
};

export default GridViewHeader;
