import { useQuery } from "hooks/useQuery";
import { useState } from "react";
import { ProgramsService } from "services/programs";
import IconButton from "components/blocks/IconButton";
import { useNavigate } from "react-router-dom";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useTranslation } from "react-i18next";
import { LookupService } from "services/lookupService";
import { OperatorFilter } from "services/shared-models/sharedEnums";
import { DataGridRequest } from "services/shared-models/sharedTypes";
import MultiSelectDropdown from "components/blocks/MultiSelectDropdown";
import { useCookieContext } from "contexts";

const ProgramsTaps = () => {
  const { t, i18n } = useTranslation("WelcomePage");
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const navigate = useNavigate();
  const { isAr } = useCookieContext();

  const [activeProgramTypeId, setActiveProgramTypeId] = useState(-1);
  const [activeAudiencesType, setActiveAudiencesType] = useState<number[]>([]);

  const { data, loading } = useQuery({
    queryFn: async () => {
      const filter: DataGridRequest["filter"] =
        activeProgramTypeId === -1
          ? []
          : [
              {
                field: "ClassificationProgram.ProgramTypeId",
                value: activeProgramTypeId,
                operator: OperatorFilter.equal,
              },
            ];

      if (activeAudiencesType.length) {
        filter.push({ field: "audienceTypes", value: activeAudiencesType, operator: OperatorFilter.contains });
      }

      return await ProgramsService.getAllPublicPrograms({
        pageIndex: 1,
        pageSize: 10,
        filter: filter,
      });
    },
    triggers: [activeProgramTypeId, isAr, ...activeAudiencesType],
  });

  const { data: audienceTypes, loading: audienceTypesLoading } = useQuery({
    queryFn: async () => {
      return await LookupService.getAllAudienceTypes();
    },
    triggers: [isAr],
  });

  const { data: programsTypes, loading: programsTypesLoading } = useQuery({
    queryFn: async () => {
      return await LookupService.getAllProgramTypes();
    },
    triggers: [isAr],
  });

  return (
    <div>
      <div className="d-flex gap-2">
        {loading && <LoadingOverlay visible={programsTypesLoading || audienceTypesLoading} />}
        {[
          {
            id: -1,
            programTypesDetail: {
              displayName: t("all"),
              locale: "En",
            },
          },
        ]
          .concat(programsTypes ?? [])
          .map((tab) => (
            <button
              key={tab.id}
              disabled={loading}
              className={`fs-14px p-10px rounded-4 btn  ${
                activeProgramTypeId === tab.id ? "btn-outline-primary active" : "border text-dark"
              }`}
              onClick={() => setActiveProgramTypeId(tab.id)}
            >
              {tab.programTypesDetail.displayName}
            </button>
          ))}

        <MultiSelectDropdown
          data={audienceTypes?.map((ele) => ({ value: ele.audienceTypeDetail.displayName, id: ele.id })) || []}
          onChange={(values) => {
            setActiveAudiencesType(values.map(Number));
          }}
        />
      </div>

      <div className="d-flex flex-wrap gap-3 mt-2 position-relative">
        {loading && <LoadingOverlay visible={loading} />}
        {data?.result.map((program) => (
          <div
            key={program.id}
            className="shadow mt-3 bg-white d-flex gap-3 border rounded-4 p-3 flex-1"
            style={{ minWidth: 500 }}
          >
            <img src={program.adsImage} width={140} className="rounded-4" alt={program.programDetail.description} />
            <div className="d-flex flex-column gap-1 justify-content-between flex-grow-1">
              <div className="d-flex flex-column gap-1">
                <span>{program.programType}</span>
                <h4>{program.programDetail.displayName} </h4>
                <p className="truncate-2lines">{program.programDetail.description}</p>
              </div>
              <div className="d-flex gap-2 text-dark ">
                <div>
                  <div className="text-gray fs-10px ">{t("fundingDuration")}</div>
                  <div className=" fs-10px border rounded-4 p-2" style={{width: 70}}>
                    {program.fundingDurationInMonths} {t("month")}
                  </div>
                </div>
                <div>
                  <div className="text-gray fs-10px ">{t("closeDate")}</div>
                  <div className=" fs-10px border rounded-4 p-2" style={{width: 75}}>{`${toSystemThemeDateFormat(
                      program.adsEndDate,
                  )}`}</div>
                </div>
                <div>
                  <div className="text-gray fs-10px ">{t("audiencesType")} </div>
                  <div className=" fs-10px border rounded-4 p-2">
                    {program.audienceTypes.map((ele) => ele.audienceTypeDetail.displayName).join(",")}
                  </div>

                </div>
                <div className={"flex-1 p-1"}> </div>
                <IconButton
                    icon={i18n.language === "ar" ? "icon-arrow-left" : "icon-arrow-right"}
                    variant="primary"
                    className="rounded-50 align-self-end"
                    fitToIconWidth
                    style={{width: 40, height: 40}}
                    onClick={() => {
                      navigate(`/programDetails/${program.id}`);
                    }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgramsTaps;
