import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import { useTranslation } from "react-i18next";
import PersonalInformation from "./PersonalInformation";
import ProfessionalInformation from "./ProfessionalInformation";
import BankingInformation from "./BankingInformation";
import { useForm } from "react-hook-form";
import useMutation from "hooks/useMutation";
import { AccountService } from "services/accountService";
import { useEffect } from "react";
import { useQuery } from "hooks/useQuery";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCountryCodeAndPhoneByPhone } from "helpers/country-codes-helper";
import { getUpdateProfile } from "./updateProfile.schema";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useCookieContext } from "contexts";
import { GzipProvider } from "../../../../helpers/compress/gzipProvider";
import Cookies from "js-cookie";
import { getUserType } from "../Tabs/ProfessionalInfo";
import { UpdateMyProfileRequest } from "../../../../services/accountService/models/AccountType";

const EditUserProfileForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["UserProfile", "Common"]);
  const { userId } = useParams();
  const { userSession, setCookie } = useCookieContext();
  const gzipProvider = new GzipProvider();
  const id = userId ?? userSession?.id;

  const {
    control,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateMyProfileRequest>({
    defaultValues: {
      fullNameArabic: "",
      email: "",
      phoneNumber: "",
      birthDate: "",
      phoneCountryCode: "",
      ibanNumber: "",
      beneficiaryName: "",
      bankName: "",
      currencyId: undefined,
      userResearchInterests: [],
      jobId: undefined,
      degree: "",
      university: "",
      college: "",
      department: "",
      specialization: "",
      employer: "",
      orcid: "",
      googleScholarId: "",
      lastUpgradeDate: "",
      degreeId: undefined,
      employeeId: "",
      fullNameEnglish: "",
      gender: "",
      genderId: 0,
      jobTitle: "",
      nationalId: "",
      nationality: "",
      subspecialtyId: undefined,
      username: "",
      nationalityId: 0,
      collegeId: 0,
    },
    resolver: getUpdateProfile(t),
  });

  const { data, loading: getUserLoading } = useQuery({
    queryFn: async () => {
      return await AccountService.getUserByIdAsync(id!);
    },
    triggers: [id],
    options: {
      enabled: !!id,
    },
  });

  const { mutateAsync, loading } = useMutation({
    queryFn: async (data: UpdateMyProfileRequest) => {
      if (userId) {
        return await AccountService.updateMyProfile(id!, data);
      } else {
        data.JobRole = data.JobRole ?? []; // ignore by the backend
        return await AccountService.updateMyUser(data);
      }
    },
    useDefaultNotification: true,
  });

  const UpdateMyProfileRequest = async (values: UpdateMyProfileRequest, id: any) => {
    let newSession = JSON.parse(gzipProvider.decompress(Cookies.get("Session")));
    if (id !== newSession.id) return;
    newSession.arabicName = values.fullNameArabic;
    newSession.englishName = values.fullNameEnglish;
    newSession.phoneNumber = `${values.phoneCountryCode}${values.phoneNumber}`;
    newSession.email = values.email;
    setCookie("Session", gzipProvider.compress(JSON.stringify(newSession)), {
      sameSite: "strict",
      secure: true,
      expires: 7,
    });
  };
  const onSubmit = async (values: UpdateMyProfileRequest) => {
    const res = await mutateAsync(values);
    if (res && !res.hasError) {
      if (userId) {
        navigate(`/home/userProfile?userId=${userId}`);
      } else {
        navigate(`/home/myProfile`);
      }
      UpdateMyProfileRequest(values, userId ?? userSession?.id);
    }
  };

  useEffect(() => {
    if (data) {
      const { code, phone } = getCountryCodeAndPhoneByPhone(data.phoneNumber);
      reset({
        ...(data as any),
        fullNameArabic: data.arabicName,
        fullNameEnglish: data.englishName,
        userResearchInterests:
          data.userResearchInterests
            ?.filter((i) => !!i?.researchInterest)
            .map((interest) => interest.researchInterest) ?? [],
        phoneNumber: phone,
        phoneCountryCode: code,
        specializationId: data.subspecialty?.generalSpecializationId,
        specialization: data.specialization,
        nationalityId: data.nationalityNavigation?.id ?? 0,
        subspecialtyId: data.subspecialty?.id,
        type: t(getUserType(data.userType)),
        college: data.college ?? data.collegeNavigation?.collegeDetail.displayName,
        collegeId: data.collegeNavigation?.id,
      });
    }
  }, [data]);

  return (
    <form noValidate className="d-flex flex-column gap-2 position-relative" onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay visible={getUserLoading} />
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <div className="fs-5 fw-bold">{data?.username ?? ""}</div>
          <div className="fs-6 text-gray">
            {t("employeeNumber")} : {data?.employeeId}
          </div>
        </div>

        <div className="d-flex gap-2">
          <button className="btn btn-primary px-4">
            {loading ? <div className="custom-loader"></div> : t("Common:save")}
          </button>
          <Link to={userId ? `/home/UserProfile?userId=${data?.id ?? 0}` : `/home/myProfile`}>
            <button className="btn border text-dark px-4">
              <span className="px-2">{t("Common:cancel")}</span>
            </button>
          </Link>
        </div>
      </div>
      <div className="divider"></div>

      <div
        className="d-flex flex-column gap-2 overflow-y-auto"
        style={{
          maxHeight: "calc(100svh - 250px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Accordion>
          <AccordionItem initiallyOpen title={t("personalInformation")} elementId="PersonalInformation">
            <PersonalInformation errors={errors} control={control} register={register} setValue={setValue} />
          </AccordionItem>
        </Accordion>

        <Accordion>
          <AccordionItem initiallyOpen title={t("professionalInformation")} elementId="ProfessionalInformation">
            <ProfessionalInformation
              errors={errors}
              control={control}
              register={register}
              setValue={setValue}
              userResearchInterests={data?.userResearchInterests?.filter((i) => !!i?.researchInterest) ?? []}
            />
          </AccordionItem>
        </Accordion>

        <Accordion>
          <AccordionItem initiallyOpen title={t("bankingInformation")} elementId="BankingInformation">
            <BankingInformation errors={errors} control={control} register={register} setValue={setValue} />
          </AccordionItem>
        </Accordion>
      </div>
    </form>
  );
};

export default EditUserProfileForm;
