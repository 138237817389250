import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FormInput from "components/blocks/FormInput";
import { useTranslation } from "react-i18next";
import "./Procedures.scss";
import OrderActionsGrid from "views/home/orders/OrderActionsGrid";
import { OrderUserTypes } from "../../..";
import { Dispatch, SetStateAction } from "react";

const Procedures = ({
  orderId,
  userType,
  setLastNote,
}: {
  orderId: number;
  userType: keyof typeof OrderUserTypes;
  setLastNote: Dispatch<
    SetStateAction<{
      text: string;
      createdBy: string;
    }>
  >;
}) => {
  const { t } = useTranslation("Orders");

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("procedures")} elementId={3}>
        <div className="pb-2">
          <OrderActionsGrid
            orderId={orderId}
            visibleColumns={["notes","activityName" , "workflowName", "action", "appliedBy", "createdOn"]}
            setLastNote={setLastNote}
          />

          <FormInput placeholder={t("notes")} label={t("notes")} />
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default Procedures;
