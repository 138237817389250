import { useQuery } from "hooks/useQuery";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/orders";
import { OrderLocalItemResult, PdfTemplateLocalItemResult } from "services/orders/models/OrdersTypes";
import { ResearcherPaymentLocalResult } from "services/researcherPayments/models/ResearcherPaymentsType";

export type ReceiptModalProps = {
  isOpen: boolean;
  type: "Receipt" | "Contract";
} & (
  | {
      type: "Receipt";
      data: ResearcherPaymentLocalResult[];
    }
  | {
      type: "Contract";
      data: PdfTemplateLocalItemResult | undefined | null;
    }
);

const PageHeaders = ({
  order,
  programOrderTaskState,
  setShowModalProps,
}: {
  order: OrderLocalItemResult | null;
  programOrderTaskState:
    | {
        actions: string[];
        canReturn: boolean;
        orderId: number;
      }
    | undefined;
  setShowModalProps: Dispatch<SetStateAction<ReceiptModalProps>>;
}) => {
  const { t } = useTranslation("Orders");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const { data: pdfContract } = useQuery({
    queryFn: async () => {
      return await OrdersService.getContractPdfByOrderId(order?.id ?? 0);
    },
    options: {
      enabled: !!order?.id,
    },
    triggers: [order?.id],
  });

  const { data: receipt } = useQuery({
    queryFn: async () => {
      return await OrdersService.getReceiptByOrderId(order?.id ?? 0);
    },
    options: {
      enabled: !!order?.id,
    },
    triggers: [order?.id],
  });

  return [
    {
      title: t("orderNumber"),
      value: order?.serialNumber,
      renderCondition: () => !!programOrderTaskState,
    },
    {
      title: t("appliedOn"),
      value: toSystemThemeDateFormat(order?.createdOn),
      renderCondition: () => !!programOrderTaskState,
    },
    {
      title: t("contractStartDate"),
      value: toSystemThemeDateFormat(order?.contractStartDate),
      renderCondition: () => !programOrderTaskState,
    },
    {
      title: t("researchesCenter"),
      value: order?.centreManagementDetail?.displayName
        ? order?.centreManagementDetail?.displayName
        : order?.centreManagement?.displayName,
    },
    {
      title: t("researchField"),
      value: order?.researchField?.displayName,
    },
    {
      title: t("subspecialty"),
      value: order?.subspecialty,
    },
    {
      title: t("funding"),
      value: order?.fundingDurationInMonths
        ? `${order?.fundingDurationInMonths + (order?.extendedPeriodInMonth ?? 0)} ${t("month")} - ${
            order?.fundingAmount
          } ${t("ryal")}`
        : "",
      renderCondition: () => !programOrderTaskState,
    },
    {
      title: t("fundingType"),
      value: order?.financingTypes?.financingTypesDetail.displayName,
      renderCondition: () => !programOrderTaskState,
    },
    {
      title: `${t("contract")}`,
      value: (
        <button
          className="btn btn-link fw-bold p-0"
          onClick={() => {
            setShowModalProps({
              isOpen: true,
              type: "Contract",
              data: pdfContract,
            });
          }}
        >
          {t("contract")}
        </button>
      ) /* TODO: contract ID ?? */,
      renderCondition: () => !programOrderTaskState,
    },
    {
      title: `${t("fundingReceipt")}`,
      value: (
        <button
          className="btn btn-link fw-bold p-0"
          onClick={() => {
            setShowModalProps({
              isOpen: true,
              type: "Receipt",
              data: receipt?.result ?? [],
            });
          }}
        >
          {t("receipt")}
        </button>
      ) /* TODO: funding sarf ID ?? */,
      renderCondition: () => !programOrderTaskState,
    },
  ];
};

export default PageHeaders;
