import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SendContractModal from "./SendContractModal";
import { AddEditProgramMinutesRef } from "../../tabs/OrdersTap/ViceDeanOrdersTab/AddProgramMinutes";

const VicDeanActions = ({
  addEditProgramMinutesRef,
}: {
  addEditProgramMinutesRef: React.RefObject<AddEditProgramMinutesRef>;
}) => {
  const { t } = useTranslation("Programs");
  const navigate = useNavigate();
  const [isSendContractModalOpen, setIsSendContractModalOpen] = useState(false);

  return (
    <>
      <button
        className="btn btn-primary px-3"
        onClick={() => {
          setIsSendContractModalOpen(true);
        }}
      >
        {t("saveAndSendContracts")}
      </button>
      <button className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3" onClick={() => navigate(-1)}>
        {t("Common:cancel")}
      </button>
      <SendContractModal
        isOpen={isSendContractModalOpen}
        onClose={() => setIsSendContractModalOpen(false)}
        addEditProgramMinutesRef={addEditProgramMinutesRef}
      />
    </>
  );
};

export default VicDeanActions;
